import { create } from 'zustand';

interface IDataCreateConstructionSite {
  userIdView?: number | null;
}

interface ICreateConstructionSiteStore {
  /* ---------------------- Show create construction site --------------------- */
  showCreateConstructionSite: boolean;
  setShowCreateConstructionSite: (show: boolean) => void;

  /* ---------------------- Data create construction site --------------------- */
  dataCreateConstructionSite: IDataCreateConstructionSite;
  setDataCreateConstructionSite: (data: IDataCreateConstructionSite) => void;
}

export const useCreateConstructionSite = create<ICreateConstructionSiteStore>((set) => ({
  /* ---------------------- Show create construction site --------------------- */
  showCreateConstructionSite: false,
  setShowCreateConstructionSite: (show) => set({ showCreateConstructionSite: show }),

  /* ---------------------- Data create construction site --------------------- */
  dataCreateConstructionSite: {},
  setDataCreateConstructionSite: (data) => set({ dataCreateConstructionSite: data }),
}));
