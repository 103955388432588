import { ModalProps } from 'antd';
import { getConstructionSiteTaskDetails } from 'api/constructionSite';
import { ModeType } from 'common';
import { handleErrorMessage } from 'helper';
import { forwardRef } from 'react';
import { useQuery } from 'react-query';
import styles from './styles.module.scss';
import { ITaskDetail, ITask, ICheckedTask } from 'common/interface';
import Loader from 'components/Loader';
import { CommonModal } from 'components/CommonModal';
import classNames from 'classnames';
import useGetWidth from '../hooks/useGetWidth';
import useGetRoleInCompany from '../hooks/useGetRoleInCompany';
import { AxiosError } from 'axios';
import { ContentTaskDetails } from './ContentTaskDetails';

interface IPropsModal extends ModalProps {
  visible?: boolean;
  onCancel?: () => any;
  taskId: number;
  task?: ITask;
  setFilterCalendar?: any;
}

export const emptyData = '-';

export const TaskDetailModal = forwardRef<any, IPropsModal>((props, ref) => {
  const { visible = true, taskId, onCancel, className } = props;

  // get details task
  const { data: taskDetail, isLoading: isLoadingTaskDetail }: { data?: ITaskDetail; isLoading: boolean } = useQuery(
    ['construction-site-task-detail', taskId],
    () => getConstructionSiteTaskDetails(taskId).then((res: any) => res?.data),
    {
      enabled: !!taskId,
      onSuccess: (data) => {},
      onError(err: AxiosError) {
        handleErrorMessage(err);
      },
    }
  );

  const { companySelected, isOnlyCustomerOfTask } = useGetRoleInCompany({
    taskDetail,
    mode: ModeType.VIEW,
  });

  const checked: ICheckedTask = {
    morning: taskDetail?.morningShift,
    afternoon: taskDetail?.afternoonShift,
    evening: taskDetail?.eveningShift,
    other: taskDetail?.otherShift,
  };

  const { widthModal } = useGetWidth({
    checked,
    mode: ModeType.VIEW,
    task: taskDetail,
    companySelected,
    isOnlyCustomerOfTask,
  });

  const isLoading = isLoadingTaskDetail;

  return (
    <>
      <CommonModal
        width={widthModal}
        visible={visible}
        closable={true}
        footer={null}
        onCancel={onCancel}
        className={classNames(styles.modal, className)}
      >
        <Loader isLoading={isLoading}>
          <ContentTaskDetails {...props} ref={ref} />
        </Loader>
      </CommonModal>
    </>
  );
});
