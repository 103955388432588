import { Button } from 'antd';
import icons from 'assets/icons';

interface IProps {
  namePreview: string;
  onClick?: () => void;
}

const PreviewPdf = ({ namePreview, onClick }: IProps) => {
  return (
    <>
      <div className="truncate">{namePreview}</div>

      <Button
        type="text"
        icon={<img src={icons.eye.gray} alt="" style={{ width: '20px' }} />}
        shape="circle"
        onClick={onClick}
      />
    </>
  );
};

export default PreviewPdf;
