import { Input, InputProps } from 'antd';
import { TYPE_INPUT } from 'common';
import React, { forwardRef } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { MAX_LENGTH } from 'common/const';

const { TextArea } = Input;

export interface IPropsInput extends InputProps {
  mode?: TYPE_INPUT.MULTIPLE;
  onChange?: (vals: any) => any;
  value?: any;
  className?: string;
  autoSize?: any;
}

export const CommonInput = forwardRef((props: IPropsInput, ref: any) => {
  const { mode, onChange, value, className, autoSize = { minRows: 5, maxRows: 5 }, ...rest } = props;

  return (
    <>
      {!mode ? (
        <Input
          ref={ref}
          className={classNames(styles.input, className)}
          autoComplete="off"
          onChange={onChange}
          value={value}
          maxLength={MAX_LENGTH}
          {...rest}
        />
      ) : (
        // @ts-ignore
        <TextArea
          ref={ref}
          autoSize={autoSize}
          onChange={onChange}
          className={classNames(styles.input, className)}
          value={value}
          {...rest}
        />
      )}
    </>
  );
});
