import { dateUtils } from 'common/dateUtils';

interface IProps {
  taskCompanyName?: string;
  profileName?: string;
  nameFile?: string;
}

const HeaderPdf = ({ taskCompanyName, profileName, nameFile }: IProps) => {
  return (
    <div className="pl-10">
      <div>{[taskCompanyName, profileName].filter(Boolean).join(' - ')}</div>
      <div>件名 : {nameFile}</div>
      <div>{dateUtils.currentFullTimeJaPan3()}</div>
      <div></div>
    </div>
  );
};

export default HeaderPdf;
