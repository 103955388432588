import { MAX_PASSWORD, MIN_PASSWORD } from 'common/const';

export const passwordValidate = () => ({
  validator(rule: any, value: any) {
    if (value && value?.length < MIN_PASSWORD) {
      return Promise.reject('パスワードは6文字以上入力する必要があります。');
    } else if (value && value?.length > MAX_PASSWORD) {
      return Promise.reject('パスワードは32文字以下入力する必要があります。');
    } else {
      return Promise.resolve();
    }
  },
});

export const passwordValidator = [
  {
    required: true,
    message: '全ての項目を入力してください。',
  },
  passwordValidate,
];

export const numberInputGreaterThanZeroValidate = (message: string) => ({
  validator(rule: any, value: any) {
    if (value && Number(value) < 0) return Promise.reject(message);
    return Promise.resolve();
  },
});

export const validateMaxMinInput = (maxInput: number, minInput?: number) => ({
  validator(rule: any, value: any) {
    if (value && Number(value) > maxInput) return Promise.reject(`最大${maxInput}数学まで入力してください。`);
    if (value && minInput && Number(value) < minInput)
      return Promise.reject(`最小${minInput}数学まで入力してください。`);
    return Promise.resolve();
  },
});

export const validateOfficeWorker = ({
  isWorkOffice,
  isWorkConstructionSite,
}: {
  isWorkOffice: boolean;
  isWorkConstructionSite: boolean;
}) => {
  if (!isWorkOffice && !isWorkConstructionSite) {
    return false;
  }
  return true;
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
