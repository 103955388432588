import { CommonStatus } from 'common';
import { IWorker } from 'common/interface';
import Decimal from 'decimal.js';
import { capitalize } from 'lodash';
import { Shift } from 'pages/Tasks/AddTaskModal';

interface IProps {
  workers?: IWorker[];
  shift: Shift;
  label: string;
}

const CountWorkShiftDetail = ({ workers, shift, label }: IProps) => {
  const totalCountWork = workers?.reduce((sum: Decimal, worker: IWorker) => {
    const isWorkerInShift = worker?.[`${shift}Shift`] === CommonStatus.ACTIVE;
    const countWork = isWorkerInShift ? worker[`countWork${capitalize(shift)}`] ?? 1 : 0;

    return sum.plus(new Decimal(countWork));
  }, new Decimal(0));

  return (
    <div className="strong text-error">
      {label} : {totalCountWork?.toString()}
    </div>
  );
};

export default CountWorkShiftDetail;
