import { Button, Checkbox, Col, Collapse, Form, Menu, Radio, Row, Space, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { searchConstructionSiteWorkerV2 } from 'api/common';
import { createTaskMultipleDay, getConstructionSiteDetail } from 'api/constructionSite';
import icSearch from 'assets/icons/search2.svg';
import icCalendarWhite2 from 'assets/icons/calendar-white-2.svg';

import classNames from 'classnames';
import {
  CommonStatus,
  ConstructionSiteState,
  ConstructionSiteType,
  ModeType,
  PAGE_SIZE,
  SHIFT_TASK,
  SHIFT_TASK_SEARCH_CONSTRUCTION,
  STATUS,
  TaskTypes,
  TYPE_INPUT,
  TYPE_PICKER,
  TypeTaskArrangement,
  UserRole,
} from 'common';
import { ROLES_ADMIN, listProjectType, listTaskNames, messageError, textJP } from 'common/const';
import { dateUtils } from 'common/dateUtils';

import { CommonDatepicker } from 'components/CommonDatepicker';

import { CommonInputNumber } from 'components/CommonInputNumber';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { TextAreaWithAttachment } from 'components/TextAreaWithAttachment';
import {
  checkOverlapTimeShift,
  confirmPopupAsync,
  getFileNameFromUrl,
  handleErrorMessage,
  handleMessageSuccess,
} from 'helper';
import useProfile from 'hooks/useProfile';
import useProfileCompany from 'hooks/useProfileCompany';

import _, { capitalize, debounce, isNil, isUndefined, max, omit, omitBy, uniq, uniqBy } from 'lodash';
import moment, { Moment } from 'moment';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import VendorPicker from '../VendorPicker';
import styles from './styles.module.scss';

import { ICertificate, ICompanyActive, ICompanyProfile, IConstructionSite, IVendor, IWorker } from 'common/interface';
import useSelectVendorCalendar from 'hooks/useSelectVendorCalendar';

import ItemWorkerSelected from '../components/ItemWorkerSelected';
import useCreateTask from 'hooks/useCreateTask';
import { useRulesForm } from 'hooks/useRulesForm';
import Loader from 'components/Loader';
import SelectProject from '../components/SelectProject';
import DetailsProject from '../components/DetailsProject';
import SectionWrapper from 'components/SectionWrapper';
import TimeTaskPicker from '../components/TimeTaskPicker';
import { nameTempTypeDate } from '../components/TimeTaskPicker';
import TimeDesire from '../components/TimeDesire';
import {
  checkCheckedShiftAdminSameCountShift,
  checkIsFixPriceConstructionSite,
  getCountWork,
  getDayOffs,
  getInitialCountWorkWorker,
  // getOtherShiftType,
  getTimesDesire,
  getUnitPriceCertificate,
  getUnitPriceWorker,
} from '../utils';
import LabelRequired from 'components/Form/LabelRequired';
import useGetWidth from '../hooks/useGetWidth';
import NoteTaskInput from '../components/NoteTaskInput';
import NoteConstruction from '../components/NoteConstruction';
import SelectLeaderTask from '../components/SelectLeaderTask';
import ErrorCheckBoxShift from '../components/ErrorCheckBoxShift';
import queryKeys from 'common/queryKeys';
import LabelTaskName from '../components/LabelTaskName';
import CustomDropDown from 'components/DropDown/CustomDropDown';
import AccessControlMultiCompany from 'components/AccessControlMultiCompany';
import useGetRoleInCompany from '../hooks/useGetRoleInCompany';
import useProfileCompanySuper from 'hooks/apis/useProfileCompanySuper';
import MaxWorkerShift from '../components/MaxWorkerShift';
import useListCompanyActive from 'hooks/apis/useListCompanyActive';
import icClose from 'assets/icons/x.svg';
import { useNoteToTaskStore } from 'stores/NoteToTaskStore';
import useFillDefaultTime from '../hooks/useFillDefaultTime';
import useListProjectTask from 'hooks/apis/useListProjectTask';

import InformationCustomer from '../components/InformationCustomer';
import { CommonInput } from 'components/CommonInput';
import PaymentInformation from '../components/PaymentInformation';
import { createSmallTask } from 'api/task';
import { getListVendor } from 'api/vendor';
import { getStaff } from 'api/staff';
import IconDeleteShift from '../components/IconDeleteShift';
import CommonSpace from 'components/CommonSpace';

import CountWorkShift from '../components/CountWorkShift';
import { getCustomerDetail } from 'api/customer';
import CheckBoxShiftAdmin from '../components/CheckBoxShiftAdmin';
import ErrorCheckBoxShiftAdmin from '../components/ErrorCheckBoxShiftAdmin';
import { getValueTitleOtherShiftCustomer } from '../components/PriceShiftCustomer';
import { getLicense } from 'api/lisence';

const { Panel } = Collapse;
export type Shift = 'morning' | 'afternoon' | 'evening' | 'other';
export type HandleSelectedVendorFunc = ({
  vendor,
  shift,
  type,
}: {
  vendor: IVendor;
  type: 'add' | 'remove';
  shift?: Shift;
}) => void;

interface IPropsTaskForm {
  constructionSiteId?: number;
  startDate?: Moment;
  taskId?: number; // Edit mode
  handleClose: () => any;
  startTimeSelect?: Moment;
  endTimeSelect?: Moment;
  isSuperCalendar?: boolean;
}

export const AddTaskModal = (props: IPropsTaskForm) => {
  const { constructionSiteId, startDate, taskId, handleClose, startTimeSelect, endTimeSelect, isSuperCalendar } = props;

  // data note to task
  const dataNoteToTask = useNoteToTaskStore((state) => state.noteToTask);
  const setOpenModalAddNote = useNoteToTaskStore((state) => state.setOpenModalAddNote);
  const setNoteToTask = useNoteToTaskStore((state) => state.setNoteToTask);

  // type of task
  const [taskType, setTaskType] = useState<TaskTypes>(TaskTypes.NORMAL);
  const isSmallClientTask = taskType === TaskTypes.SMALL;

  const [form] = useForm();
  const queryClient = useQueryClient();

  const profile = useProfile();

  // Get role in company
  const {
    companySelected,
    setCompanySelected,
    isOnlyVendor,
    isCustomer,
    isVendorCustomer,
    currentRoleInCompany,
    isOnlyCustomerOfTask,
  } = useGetRoleInCompany({
    mode: ModeType.CREATE,
  });
  const { data: license } = useQuery(['/nest/license', companySelected?.id!], () => getLicense(companySelected?.id), {
    enabled: ROLES_ADMIN.includes(currentRoleInCompany),
  });

  const { listCompanyActive, companyWithUserIsAdmin, companyActiveIds, numberCompanyUserCanCreateTask } =
    useListCompanyActive();

  const { profileCompanySuper } = useProfileCompanySuper({
    companyId: companySelected?.id,
    enabled: Boolean(isSuperCalendar),
    onSuccess(data) {},
  });

  const { profileCompany: profileCompanyOneCompany } = useProfileCompany();
  const profileCompany = isSuperCalendar ? profileCompanySuper : profileCompanyOneCompany;

  useEffect(() => {
    resetAndCompareAllShifts(profileCompany);
  }, [profileCompany]);

  const { rulesForm } = useRulesForm();

  const {
    handlePickSelectedVendor,
    handleSelectedVendor,
    removeAllVendorInShift,
    selectedVendor,
    uniqueSelectedVendor,
    setSelectedVendor,
  } = useSelectVendorCalendar({ form });

  // checked phía bên trái của view (có thể nhìn thấy dc)
  const [checked, setChecked] = useSetState({
    morning: false,
    afternoon: false,
    evening: false,
    other: false,
  });

  // checked phía bên phải của view (admin - ngầm ẩn đi)
  const [checkedOfAdmin, setCheckedOfAdmin] = useState({
    morningShift: false,
    afternoonShift: false,
    eveningShift: false,
    otherShift: false,
  });

  //
  const handleDeletePanel = (shift: Shift) => {
    setCheckedOfAdmin((pre) => ({ ...pre, [`${shift}Shift`]: false }));

    // reset number worker
    const nameWorkerField = `worker${capitalize(shift)}`;
    form.resetFields([nameWorkerField]);

    removeAllVendorInShift(shift);
    removeAllWorkerInShift(shift);
  };

  //
  const handleCreateOtherShift = () => {
    // Update state delete panel
    setCheckedOfAdmin((pre) => ({ ...pre, otherShift: true }));
  };

  // handle checked shift : morning | afternoon | evening | other
  const handleSelectShift = (e: any, shift: Shift) => {
    switch (shift) {
      case 'morning':
        setChecked((pre) => ({ ...pre, morning: e.target.checked }));
        setCheckedOfAdmin((pre) => ({ ...pre, morningShift: e.target.checked }));

        // khi uncheck
        if (!e.target.checked) {
          form.resetFields(['workerIdsMorning', 'vendorsMorning', 'morningShift', 'workerMorning']);

          removeAllVendorInShift('morning');

          if (!checked.afternoon) {
            const values = form.getFieldsValue(true);
            form.setFieldsValue({ ...values, morning: undefined, workerIdsMorning: undefined });
          }
        }
        break;
      case 'afternoon':
        setChecked((pre) => ({ ...pre, afternoon: e.target.checked }));
        setCheckedOfAdmin((pre) => ({ ...pre, afternoonShift: e.target.checked }));

        // khi uncheck
        if (!e.target.checked) {
          form.resetFields(['workerIdsAfternoon', 'vendorsAfternoon', 'afternoonShift', 'workerAfternoon']);

          removeAllVendorInShift('afternoon');

          if (!checked.morning) {
            const values = form.getFieldsValue(true);
            form.setFieldsValue({ ...values, morning: undefined, workerIdsAfternoon: undefined });
          }
        }
        break;
      case 'evening':
        setChecked((pre) => ({ ...pre, evening: e.target.checked }));
        setCheckedOfAdmin((pre) => ({ ...pre, eveningShift: e.target.checked }));

        // khi uncheck
        if (!e.target.checked) {
          form.resetFields(['workerIdsEvening', 'vendorsEvening', 'eveningShift', 'workerEvening']);

          removeAllVendorInShift('evening');

          const values = form.getFieldsValue(true);
          form.setFieldsValue({ ...values, evening: undefined, workerIdsEvening: undefined });
        }
        break;
      case 'other':
        setChecked((pre) => ({ ...pre, other: e.target.checked }));
        setCheckedOfAdmin((pre) => ({ ...pre, otherShift: e.target.checked }));

        // khi uncheck
        if (!e.target.checked) {
          form.resetFields(['workerIdsOther', 'vendorsOther', 'otherShift', 'workerOther', 'workerOther']);

          removeAllVendorInShift('other');

          const values = form.getFieldsValue(true);
          form.setFieldsValue({ ...values, other: undefined, workerIdsOther: undefined });
        }
        break;
      default:
        break;
    }
  };

  const resetCheckedAllShift = () => {
    setChecked({
      morning: false,
      afternoon: false,
      evening: false,
      other: false,
    });

    setCheckedOfAdmin({
      morningShift: false,
      afternoonShift: false,
      eveningShift: false,
      otherShift: false,
    });

    // reset checked shift, maxWorker and worker in form
    form.resetFields([
      'morningShiftCustomer',
      'afternoonShiftCustomer',
      'eveningShiftCustomer',
      'otherShiftCustomer',
      'morningShift',
      'afternoonShift',
      'eveningShift',
      'otherShift',
      'maxWorkerMorning',
      'maxWorkerAfternoon',
      'maxWorkerEvening',
      'maxWorkerOther',
      'workerMorning',
      'workerAfternoon',
      'workerEvening',
      'workerOther',
    ]);
  };

  const resetAndCompareAllShifts = (profileCompany: ICompanyProfile) => {
    // compare với profileCompany để reset shifts
    const isHaveMorningShift = profileCompany?.morningShift ? checked.morning : false;
    const isHaveAfternoonShift = profileCompany?.afternoonShift ? checked.afternoon : false;
    const isHaveEveningShift = profileCompany?.eveningShift ? checked.evening : false;
    const isHaveOtherShift = checked.other;

    const isHaveMorningShiftAdmin = profileCompany?.morningShift ? checkedOfAdmin.morningShift : false;
    const isHaveAfternoonShiftAdmin = profileCompany?.afternoonShift ? checkedOfAdmin.afternoonShift : false;
    const isHaveEveningShiftAdmin = profileCompany?.eveningShift ? checkedOfAdmin.eveningShift : false;
    const isHaveOtherShiftAdmin = checkedOfAdmin.otherShift;

    // reset state checked
    setChecked({
      morning: isHaveMorningShift,
      afternoon: isHaveAfternoonShift,
      evening: isHaveEveningShift,
      other: isHaveOtherShift,
    });

    // reset state checked of admin
    setCheckedOfAdmin({
      morningShift: isHaveMorningShiftAdmin,
      afternoonShift: isHaveAfternoonShiftAdmin,
      eveningShift: isHaveEveningShiftAdmin,
      otherShift: isHaveOtherShiftAdmin,
    });

    const fieldsReset = [];

    if (!isHaveMorningShift) {
      fieldsReset.push(
        'morningShiftCustomer',
        'morningShift',
        'maxWorkerMorning',
        'morningFrom',
        'morningTo',
        'workerMorning',
        'vendorsMorning',
        'workerIdsMorning',
        'morning' // certificates of customer
      );
    }
    if (!isHaveAfternoonShift) {
      fieldsReset.push(
        'afternoonShiftCustomer',
        'afternoonShift',
        'maxWorkerAfternoon',
        'afternoonFrom',
        'afternoonTo',
        'workerAfternoon',
        'vendorsAfternoon',
        'workerIdsAfternoon',
        'afternoon' // certificates of customer
      );
    }
    if (!isHaveEveningShift) {
      fieldsReset.push(
        'eveningShiftCustomer',
        'eveningShift',
        'maxWorkerEvening',
        'eveningFrom',
        'eveningTo',
        'workerEvening',
        'vendorsEvening',
        'workerIdsEvening',
        'evening' // certificates of customer
      );
    }
    if (!isHaveOtherShift) {
      fieldsReset.push(
        'otherShiftCustomer',
        'otherShift',
        'maxWorkerOther',
        'otherFrom',
        'otherTo',
        'workerOther',
        'vendorsOther',
        'workerIdsOther',
        'other' // certificates of customer
      );
    }

    form.resetFields(fieldsReset);
  };

  const resetAndCompareVendors = ({ newListVendors }: { newListVendors: IVendor[] }) => {
    // get vendors
    const idsListVendors = newListVendors.map((vendor) => vendor?.id);

    const valuesForm = form.getFieldsValue(true) || {};

    // reset vendors in shift areas
    const resetVendorsMorning = valuesForm?.vendorsMorning?.filter((vendor: any) =>
      idsListVendors.includes(vendor?.id)
    );
    const resetVendorsAfternoon = valuesForm?.vendorsAfternoon?.filter((vendor: any) =>
      idsListVendors.includes(vendor?.id)
    );
    const resetVendorsEvening = valuesForm?.vendorsEvening?.filter((vendor: any) =>
      idsListVendors.includes(vendor?.id)
    );
    const resetVendorsOther = valuesForm?.vendorsOther?.filter((vendor: any) => idsListVendors.includes(vendor?.id));

    // reset certificate of vendor
    const resetCertificateVendor = valuesForm?.certificatesVendor?.filter((vendor: any) =>
      idsListVendors.includes(vendor?.id)
    );

    // reset certificate of vendor
    const resetItemCertificateVendors = Object.keys(valuesForm)
      ?.filter((key) => key.startsWith('certificate_vendor_id_'))
      ?.reduce((obj, key) => {
        const idVendor = key.replace('certificate_vendor_id_', '');
        const isVendorInList = idsListVendors.includes(+idVendor);

        if (!isVendorInList) {
          return { ...obj, [key]: undefined };
        }

        return obj;
      }, {} as any);

    // set reset value to form
    form.setFieldsValue({
      vendorsMorning: resetVendorsMorning,
      vendorsAfternoon: resetVendorsAfternoon,
      vendorsEvening: resetVendorsEvening,
      vendorsOther: resetVendorsOther,
      certificatesVendor: resetCertificateVendor,
      ...resetItemCertificateVendors,
    });

    const resetVendor = selectedVendor?.filter((vendor) => idsListVendors.includes(vendor?.id as any));
    setSelectedVendor(resetVendor);
  };

  const resetAndCompareWorkers = ({ newListWorkers }: { newListWorkers: IWorker[] }) => {
    // if (isSelectFixedPrice) {
    // reset number worker (in left)
    //   form.setFieldsValue({
    //     workerMorning: undefined,
    //     workerAfternoon: undefined,
    //     workerEvening: undefined,
    //     workerOther: undefined,
    //   });
    // }

    const formValues = form.getFieldsValue(true) ?? {};

    // get workers
    const idsListWorkers = newListWorkers.map((worker) => worker?.id);

    const resetWorkerIdsMorning = formValues?.workerIdsMorning?.filter((workerId: any) =>
      idsListWorkers.includes(workerId)
    );
    const resetWorkerIdsAfternoon = formValues?.workerIdsAfternoon?.filter((workerId: any) =>
      idsListWorkers.includes(workerId)
    );
    const resetWorkerIdsEvening = formValues?.workerIdsEvening?.filter((workerId: any) =>
      idsListWorkers.includes(workerId)
    );
    const resetWorkerIdsOther = formValues?.workerIdsOther?.filter((workerId: any) =>
      idsListWorkers.includes(workerId)
    );

    form.setFieldsValue({
      workerIdsMorning: resetWorkerIdsMorning,
      workerIdsAfternoon: resetWorkerIdsAfternoon,
      workerIdsEvening: resetWorkerIdsEvening,
      workerIdsOther: resetWorkerIdsOther,
    });
  };

  const resetAndCompareCertificatesCustomer = ({ newListCertificates }: { newListCertificates: ICertificate[] }) => {
    const formValues = form.getFieldsValue(true) ?? {};

    // get certificates
    const idsListCertificates = newListCertificates.map((certificate) => certificate?.id);

    const resetCertificateMorning = formValues?.morning?.filter((certificate: any) =>
      idsListCertificates.includes(certificate?.id)
    );
    const resetCertificateAfternoon = formValues?.afternoon?.filter((certificate: any) =>
      idsListCertificates.includes(certificate?.id)
    );
    const resetCertificateEvening = formValues?.evening?.filter((certificate: any) =>
      idsListCertificates.includes(certificate?.id)
    );
    const resetCertificateOther = formValues?.other?.filter((certificate: any) =>
      idsListCertificates.includes(certificate?.id)
    );

    form.setFieldsValue({
      morning: resetCertificateMorning,
      afternoon: resetCertificateAfternoon,
      evening: resetCertificateEvening,
      other: resetCertificateOther,
    });
  };

  const resetAndComparePriceOtherShiftCustomer = ({
    constructionSite,
    oldConstructionSiteRef,
  }: {
    constructionSite: IConstructionSite;
    oldConstructionSiteRef: MutableRefObject<IConstructionSite | undefined>;
  }) => {
    if (
      oldConstructionSiteRef.current?.customerId &&
      oldConstructionSiteRef.current?.customerId !== constructionSite?.customerId
    ) {
      form.resetFields(['titleOtherShiftCustomer', 'priceOtherShiftCustomer']);
    }
  };

  const resetAndCompareCertificateWorker = ({
    constructionSite,
    oldConstructionSiteRef,
  }: {
    constructionSite: IConstructionSite;
    oldConstructionSiteRef: MutableRefObject<IConstructionSite | undefined>;
  }) => {
    if (
      oldConstructionSiteRef.current?.customerId &&
      oldConstructionSiteRef.current?.customerId !== constructionSite?.customerId
    ) {
      // reset certificates of worker
      const fieldCertificateWorkerNames = form.getFieldsValue(true)
        ? Object.keys(form.getFieldsValue(true))?.filter((key) => key.startsWith('certificate_worker_'))
        : [];

      form.resetFields(fieldCertificateWorkerNames);
    }
  };

  const [constructionSiteFilter, setConstructionSiteFilter] = useSetState({
    pageIndex: 1,
    pageSize: PAGE_SIZE.PAGE_SIZE_20,
    keyword: '',
    // status: [ConstructionSiteStatus.ACTIVE],
    state: [ConstructionSiteState.COMING_TO_WORK, ConstructionSiteState.WORKING],
    // isCreateTask: true,
    companyIds: companyActiveIds,
    isAutoCreateTask: CommonStatus.INACTIVE,
  });

  const [selectedConstructionSiteId, setSelectedConstructionSiteId] = useState<number>(0);

  const oldConstructionSiteRef = useRef<IConstructionSite>();
  const { data: constructionSiteDetail, isLoading: isLoadingConstructionSiteDetail } = useQuery(
    ['construction-site-detail', selectedConstructionSiteId, companySelected?.id],
    () =>
      getConstructionSiteDetail(
        selectedConstructionSiteId,
        {
          leader: STATUS.ACTIVE,
        },
        companySelected?.id // companyId
      ),
    {
      enabled: !!selectedConstructionSiteId,
      onSuccess: (data) => {
        form.setFieldsValue({
          noteConstructionSite: data?.data?.description,
          constructionSiteId: data?.data?.id,
        });

        // reset worker when select fixed price construction site
        // const isSelectFixedPriceConstructionSite = data?.data?.type === ConstructionSiteType.FIXED_PRICE;

        // compare and reset certificates of customer
        resetAndCompareCertificatesCustomer({
          newListCertificates: data?.data?.unitPriceCertificate ?? [],
        });

        // compare and reset workers
        resetAndCompareWorkers({
          newListWorkers: data?.data?.workers ?? [],
        });

        // compare and reset vendors in shifts and certificate of vendors
        resetAndCompareVendors({ newListVendors: data?.data?.vendors ?? [] });

        // compare and reset price other shift customer
        resetAndComparePriceOtherShiftCustomer({ constructionSite: data?.data, oldConstructionSiteRef });

        // compare and reset certificate worker
        resetAndCompareCertificateWorker({ constructionSite: data?.data, oldConstructionSiteRef });

        oldConstructionSiteRef.current = data?.data;
      },
    }
  );

  const certificateOptions =
    constructionSiteDetail?.data.dataUnitPriceCertificateByUserId?.[constructionSiteDetail?.data?.customerId] ?? [];

  const customerIdOfConstructionSite = constructionSiteDetail?.data.customerId;

  const { data: dataCustomerOfConstruction } = useQuery({
    queryKey: [queryKeys.customer.details, customerIdOfConstructionSite, companySelected?.id],
    queryFn: () => getCustomerDetail(customerIdOfConstructionSite as number, companySelected?.id),
    enabled: !isNil(customerIdOfConstructionSite) && ROLES_ADMIN.includes(currentRoleInCompany),
  });
  const unitPriceCustomerOptions = dataCustomerOfConstruction?.data?.dataUnitPrice ?? [];
  // const unitPriceWorkerOptions = dataCustomerOfConstruction?.data?.dataUnitPriceCertificate ?? [];

  const handleSearchConstructionSite = useCallback(
    _.debounce(
      (keyword: string) => setConstructionSiteFilter({ keyword, isAutoCreateTask: CommonStatus.INACTIVE }),
      300
    ),
    []
  );

  useEffect(() => {
    if (constructionSiteId) {
      setSelectedConstructionSiteId(constructionSiteId);
    }
  }, []);

  const resetFormWhenChangeConstructionSite = () => {
    // reset certificate of vendor
    const valuesForm = form.getFieldsValue(true) || {};
    const resetCertificateVendors =
      Object.keys(valuesForm)
        ?.filter((key) => key.startsWith('certificate_vendor_id_'))
        ?.reduce((obj, key) => ({ ...obj, [key]: undefined }), {} as any) || {};

    // remove selected vendor
    setSelectedVendor([]);

    // reset filed after change Construction Site
    form.setFieldsValue({
      workerIdsMorning: undefined,
      workerIdsEvening: undefined,
      workerIdsAfternoon: undefined,
      workerIdsOther: undefined,
      vendorsMorning: undefined,
      vendorsEvening: undefined,
      vendorsAfternoon: undefined,
      vendorsOther: undefined,
      morning: undefined,
      evening: undefined,
      other: undefined,
      certificatesVendor: undefined,
      ...resetCertificateVendors,
    });
  };

  const resetConstructionSiteId = () => {
    // reset list project
    queryClient.invalidateQueries(queryKeys.task.list_constructionSite__create_task);

    setSelectedConstructionSiteId(0);

    // form
    form.resetFields(['constructionSiteId']);
    form.setFieldsValue({ noteConstructionSite: '' });
  };

  // Reset checked shift in all tab.
  const resetCheckedShiftInAllTab = () => {
    // reset checked shift in state
    setChecked({
      morning: false,
      afternoon: false,
      evening: false,
      other: false,
    });

    // reset checked in form
    form.resetFields(['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']);
  };

  const { getListProjectTask } = useListProjectTask();

  const handleSelectConstructionSite = (id: number) => {
    const listProject = getListProjectTask();

    const projectSelected = listProject?.find((project) => project.id === id);
    const newCompanySelected = listCompanyActive?.find((company) => company.id === projectSelected?.companyId);

    setCompanySelected(newCompanySelected);

    setSelectedConstructionSiteId(id);

    // Update companySelected in dataNoteToTask
    if (!isNil(dataNoteToTask)) {
      setNoteToTask({ ...dataNoteToTask, companySelected: newCompanySelected as any });
    }

    // reset form
    // resetFormWhenChangeConstructionSite();

    // if have dataNoteToTask not reset
    // if (!dataNoteToTask) {
    // resetCheckedAllShift();
    // }

    setConstructionSiteFilter((pre) => ({ ...pre, keyword: '' }));
  };

  const getTimeOfShift = (date?: string | null) => {
    const [hour, minute] = date?.split(':') || [];

    const time = moment().set({
      hour: hour as any,
      minute: minute as any,
      second: 0,
      millisecond: 0,
    });

    return time;
  };

  const handleSetDefaultValues = () => {
    form.setFieldsValue({
      startDate: startDate ? startDate.clone() : moment(),
      endDate: startDate ? startDate.clone() : moment(),
      morningFrom: profileCompany?.morningFrom,
      morningTo: profileCompany?.morningTo,
      afternoonFrom: profileCompany?.afternoonFrom,
      afternoonTo: profileCompany?.afternoonTo,
      eveningFrom: profileCompany?.eveningFrom,
      eveningTo: profileCompany?.eveningTo,
      startTime: startDate ? startDate.clone() : moment().set('minute', 0),
      endTime: startDate ? startDate.clone().add(1, 'hours') : moment().add(1, 'hours').set('minute', 0),
      constructionSiteId: constructionSiteId,
    });
  };

  useEffect(() => {
    // Handle when select time in calendar -> Auto fill checked shift
    if (startTimeSelect && endTimeSelect) {
      const timeMorningStart = getTimeOfShift(profileCompany?.morningFrom);
      const timeMorningEnd = getTimeOfShift(profileCompany?.morningTo);

      const isCheckedMorningShift = checkOverlapTimeShift(
        startTimeSelect,
        endTimeSelect,
        timeMorningStart,
        timeMorningEnd
      );

      const timeAfternoonStart = getTimeOfShift(profileCompany?.afternoonFrom);
      const timeAfternoonEnd = getTimeOfShift(profileCompany?.afternoonTo);
      const isCheckedAfternoonShift = checkOverlapTimeShift(
        startTimeSelect,
        endTimeSelect,
        timeAfternoonStart,
        timeAfternoonEnd
      );

      const timeEveningStart = getTimeOfShift(profileCompany?.eveningFrom);
      const timeEveningEnd = getTimeOfShift(profileCompany?.eveningTo);
      const isCheckedEveningShift = checkOverlapTimeShift(
        startTimeSelect,
        endTimeSelect,
        timeEveningStart,
        timeEveningEnd
      );

      const checkedOfMorning = !!profileCompany?.morningShift ? isCheckedMorningShift : false;
      const checkedOfAfternoon = !!profileCompany?.afternoonShift ? isCheckedAfternoonShift : false;
      const checkedOfEvening = !!profileCompany?.eveningShift ? isCheckedEveningShift : false;

      setChecked((pre) => ({
        morning: checkedOfMorning,
        afternoon: checkedOfAfternoon,
        evening: checkedOfEvening,
        other: false,
      }));

      setCheckedOfAdmin({
        morningShift: checkedOfMorning,
        afternoonShift: checkedOfAfternoon,
        eveningShift: checkedOfEvening,
        otherShift: false,
      });

      form.setFieldsValue({
        morningShiftCustomer: checkedOfMorning,
        afternoonShiftCustomer: checkedOfAfternoon,
        eveningShiftCustomer: checkedOfEvening,
        otherShiftCustomer: false,
      });
    }

    /* -------------------- set default value in create mode -------------------- */
    startDate?.set('minute', 0);

    // if don't have dataNoteToTask
    if (isNil(dataNoteToTask)) {
      handleSetDefaultValues();

      // add default time when select time in calendar
      if ([startTimeSelect, endTimeSelect].every((time) => !isNil(time))) {
        // handle fill timeForm and timeTo
        handleFillDefaultTime({
          formValue: form.getFieldsValue(true),
          profileCompany,
          startTimeDesire: startTimeSelect?.format('HH:mm') as string,
          endTimeDesire: endTimeSelect?.format('HH:mm') as string,
        });
      }
    }

    // if have dataNoteToTask
    if (!isNil(dataNoteToTask)) {
      handleSetDefaultValues();

      setCompanySelected(dataNoteToTask.companySelected);

      // set checked shift
      if (dataNoteToTask.shift?.includes(SHIFT_TASK.MORNING) && !!profileCompany?.morningShift) {
        setChecked({ morning: true });
        setCheckedOfAdmin((pre) => ({ ...pre, morningShift: true }));
        form.setFieldsValue({
          morningShiftCustomer: true,
          maxWorkerMorning: dataNoteToTask.morningExtraWorker,
          workerMorning: dataNoteToTask.morningExtraWorker,
        });
      }

      if (dataNoteToTask.shift?.includes(SHIFT_TASK.AFTERNOON) && !!profileCompany?.afternoonShift) {
        setChecked({ afternoon: true });
        setCheckedOfAdmin((pre) => ({ ...pre, afternoonShift: true }));
        form.setFieldsValue({
          afternoonShiftCustomer: true,
          maxWorkerAfternoon: dataNoteToTask.afternoonExtraWorker,
          workerAfternoon: dataNoteToTask.afternoonExtraWorker,
        });
      }

      if (dataNoteToTask.shift?.includes(SHIFT_TASK.EVENING) && !!profileCompany?.eveningShift) {
        setChecked({ evening: true });
        setCheckedOfAdmin((pre) => ({ ...pre, eveningShift: true }));
        form.setFieldsValue({
          eveningShiftCustomer: true,
          maxWorkerEvening: dataNoteToTask.eveningExtraWorker,
          workerEvening: dataNoteToTask.eveningExtraWorker,
        });
      }

      if (dataNoteToTask.shift?.includes(SHIFT_TASK.OTHER)) {
        setChecked({ other: true });
        setCheckedOfAdmin((pre) => ({ ...pre, otherShift: true }));
        form.setFieldsValue({
          otherShiftCustomer: true,
          maxWorkerOther: dataNoteToTask.otherExtraWorker,
          workerOther: dataNoteToTask.otherExtraWorker,
        });
      }

      // set note
      if (ROLES_ADMIN.includes(dataNoteToTask.companySelected.roleId)) {
        form.setFieldsValue({
          note: dataNoteToTask.note,
        });
      }

      // set startDate and end Date same date
      form.setFieldsValue({
        startDate: dataNoteToTask.workingDay ? moment(dataNoteToTask.workingDay) : null,
        endDate: dataNoteToTask.workingDay ? moment(dataNoteToTask.workingDay) : null,
        noteConstructionSite: dataNoteToTask?.noteConstructionSite ?? '',
      });

      // set time desire
      if (dataNoteToTask.companySelected.roleId === UserRole.CUSTOMER) {
        if (dataNoteToTask?.time) {
          const [hours = 0, minutes = 0] = dataNoteToTask?.time?.split(':');

          form.setFieldsValue({
            startTimeDesire: moment().set({ hours: +hours, minutes: +minutes }),
          });
        }

        if (dataNoteToTask?.endTime) {
          const [hours = 0, minutes = 0] = dataNoteToTask?.endTime?.split(':');

          form.setFieldsValue({
            endTimeDesire: moment().set({ hours: +hours, minutes: +minutes }),
          });
        }
      }

      // set files
      if (dataNoteToTask.files?.length) {
        form.setFieldsValue({
          description: {
            files: dataNoteToTask.files,
          },
        });
      }

      const formValue = form.getFieldsValue(true);
      // handle fill timeForm and timeTo
      handleFillDefaultTime({
        formValue,
        profileCompany,
        startTimeDesire: dataNoteToTask.time,
        endTimeDesire: dataNoteToTask.endTime,
      });

      form.setFieldsValue(formValue);

      return;
    }
  }, [profileCompany?.id, dataNoteToTask]);

  const handleRemoveWorker = (shift: Shift) => (id: number) => {
    const workerIdsMorning = form.getFieldValue('workerIdsMorning');
    const workerIdsAfternoon = form.getFieldValue('workerIdsAfternoon');
    const workerIdsEvening = form.getFieldValue('workerIdsEvening');
    const workerIdsOther = form.getFieldValue('workerIdsOther');

    if (shift === 'morning') {
      form.setFieldsValue({ workerIdsMorning: workerIdsMorning?.filter((x: number) => x != id) });
    }
    if (shift === 'afternoon') {
      form.setFieldsValue({ workerIdsAfternoon: workerIdsAfternoon?.filter((x: number) => x != id) });
    }
    if (shift === 'evening') {
      form.setFieldsValue({ workerIdsEvening: workerIdsEvening?.filter((x: number) => x != id) });
    }
    if (shift === 'other') {
      form.setFieldsValue({ workerIdsOther: workerIdsOther?.filter((x: number) => x != id) });
    }
  };

  const removeAllWorkerInShift = (shift: Shift) => {
    if (shift === 'morning') {
      form.setFieldsValue({ workerIdsMorning: undefined });
    }
    if (shift === 'afternoon') {
      form.setFieldsValue({ workerIdsAfternoon: undefined });
    }
    if (shift === 'evening') {
      form.setFieldsValue({ workerIdsEvening: undefined });
    }
    if (shift === 'other') {
      form.setFieldsValue({ workerIdsOther: undefined });
    }
  };

  const addTaskMutation = useMutation({
    mutationFn: ({ companyId, payload }: { payload: any; companyId?: number }) =>
      createTaskMultipleDay(payload, companyId),
  });

  const { checkDateSameToday, checkStartDateAndEndDateSameDateAndInPast, checkStartTimeDesireNotSelect } =
    useCreateTask();

  const onClickCreateTask = async (values: any) => {
    const isStartDateAndEndDateSameToday = checkDateSameToday(values);
    const isStartTimeDesireNotSelect = checkStartTimeDesireNotSelect(values);
    const isCustomerNotSelectTimeDesire = isCustomer && isStartTimeDesireNotSelect;

    let isCancel = false;

    if (isCustomerNotSelectTimeDesire) {
      const result = await confirmPopupAsync({ title: '開始時刻が設定されていません。このまま手配しますか。' });

      if (!result) {
        isCancel = true;
      }
    }

    if (isStartDateAndEndDateSameToday && !isCancel) {
      const result = await confirmPopupAsync({
        title: '本日の日付が設定されています。このまま手配しますか。',
      });

      if (!result) {
        isCancel = true;
      }
    }

    if (checkStartDateAndEndDateSameDateAndInPast(values) && !isCancel) {
      const result = await confirmPopupAsync({
        title: ' 過去の日付が設定されています。このまま手配しますか。',
      });

      if (!result) {
        isCancel = true;
      }
    }

    if (isCancel) return;

    handleSubmit(values);
  };

  const createTaskSmallMutation = useMutation({
    mutationFn: createSmallTask,
  });

  // handle submit add task
  const handleSubmit = (values: any) => {
    if (isAdminNotSelectShift) {
      handleErrorMessage(messageError.needAtLeastOneShift, true);
      return;
    }

    const isCheckedShiftAdminSameCountShift = checkCheckedShiftAdminSameCountShift({ checkedOfAdmin, values });
    if (isAdmin && isCheckedShiftAdminSameCountShift) {
      handleErrorMessage(messageError.checkedShiftAdminSameCountShift, true);
      return;
    }

    const dayOffs = getDayOffs(form);

    const selectedVendorIds = selectedVendor.map((vendor) => vendor?.id);

    // certificate vendor
    const unitPriceVendor = uniq(selectedVendorIds).map((id) => {
      const certificateNumberWorkers = values?.[`certificate_vendor_id_${id}` as any] as any;

      const vendorOtherShift: IVendor & { price?: number } = values?.vendorsOther?.find(
        (vendor: IVendor) => vendor?.id === id
      );

      return {
        userId: id,
        price: vendorOtherShift?.price,
        unitPriceCertificateIds: certificateNumberWorkers?.map((certificate: any) => certificate?.certificateId?.value),
        certificateNumberWorkers: certificateNumberWorkers?.map((certificate: any) => ({
          certificateId: certificate?.certificateId?.value,
          workers: certificate?.workers,
        })),
      };
    });

    // certificate customer
    const unitPriceCustomer = {
      morningShift:
        values?.morning?.map((certificate: any) => ({
          ...certificate,
          certificateId: certificate?.certificateId?.value,
        })) ?? [],
      afternoonShift:
        values?.afternoon?.map((certificate: any) => ({
          ...certificate,
          certificateId: certificate?.certificateId?.value,
        })) ?? [],
      eveningShift:
        values?.evening?.map((certificate: any) => ({
          ...certificate,
          certificateId: certificate?.certificateId?.value,
        })) ?? [],
      otherShift:
        values?.other?.map((certificate: any) => ({
          ...certificate,
          certificateId: certificate?.certificateId?.value,
        })) ?? [],
    };

    // const unitPriceWorker =
    //   values?.workerIdsOther?.map((id: number) => ({
    //     userId: id,
    //     price: values?.[`price_other_worker_${id}`] ? +values?.[`price_other_worker_${id}`] : 0,
    //   })) ?? [];

    const countWork = getCountWork({ values });
    const { unitPriceWorker } = getUnitPriceWorker({ values });
    // const otherShiftType = getOtherShiftType({ values });
    const unitPriceCertificate = getUnitPriceCertificate(values);

    const morningShift = () => {
      const checkedValue = checked.morning ? 1 : 0;

      if (isAdmin) {
        return checkedOfAdmin.morningShift ? 1 : 0;
      }

      return checkedValue;
    };

    const afternoonShift = () => {
      const checkedValue = checked.afternoon ? 1 : 0;

      if (isAdmin) {
        return checkedOfAdmin.afternoonShift ? 1 : 0;
      }

      return checkedValue;
    };

    const eveningShift = () => {
      const checkedValue = checked.evening ? 1 : 0;

      if (isAdmin) {
        return checkedOfAdmin.eveningShift ? 1 : 0;
      }

      return checkedValue;
    };

    const otherShift = () => {
      const checkedValue = checked.other ? 1 : 0;

      if (isAdmin) {
        return checkedOfAdmin.otherShift ? 1 : 0;
      }

      return checkedValue;
    };

    const timesDesire = getTimesDesire(values);

    const payload = {
      unitPriceCustomer,
      constructionSiteId: values?.constructionSiteId,
      name: name || null,
      // maxWorker: values?.maxWorker, // remove because maxWorker is split into maxWorkerMorning and maxWorkerAfternoon
      maxWorkerMorning: values?.maxWorkerMorning ?? 0,
      maxWorkerAfternoon: values?.maxWorkerAfternoon ?? 0,
      maxWorkerEvening: values?.maxWorkerEvening ?? 0,
      maxWorkerOther: values?.maxWorkerOther ?? 0,
      noteConstructionSite: values?.noteConstructionSite ?? '',
      date: {
        from: values?.startDate?.format('YYYY-MM-DD'),
        to:
          values?.[nameTempTypeDate] === TypeTaskArrangement.MULTI_DAYS
            ? values?.endDate?.format('YYYY-MM-DD')
            : values?.startDate?.format('YYYY-MM-DD'),
      },

      // Thời gian mong muốn
      ...timesDesire,

      vendors: {
        morningShift: values?.vendorsMorning?.map((x: any) => ({ userId: x.id, maxWorker: x.maxWorker || 0 })) || [],
        afternoonShift:
          values?.vendorsAfternoon?.map((x: any) => ({ userId: x.id, maxWorker: x.maxWorker || 0 })) || [],
        eveningShift: values?.vendorsEvening?.map((x: any) => ({ userId: x.id, maxWorker: x.maxWorker || 0 })) || [],
        otherShift: values?.vendorsOther?.map((x: any) => ({ userId: x.id, maxWorker: x.maxWorker || 0 })) || [],
        unitPrice: unitPriceVendor,
      },
      workers: {
        morningShift: values?.workerIdsMorning || [],
        afternoonShift: values?.workerIdsAfternoon || [],
        eveningShift: values?.workerIdsEvening || [],
        otherShift: values?.workerIdsOther || [],
        unitPrice: unitPriceWorker,
        countWork,
        unitPriceCertificate,
      },
      // morningShift: values?.morningShift ? 1 : 0,
      // afternoonShift: values?.afternoonShift ? 1 : 0,
      // eveningShift: values?.eveningShift ? 1 : 0,
      // otherShift: values?.otherShift ? 1 : 0,
      morningFrom:
        typeof values?.morningFrom === 'object' ? values?.morningFrom?.format('HH:mm') : values?.morningFrom || null,
      morningTo: typeof values?.morningTo === 'object' ? values?.morningTo?.format('HH:mm') : values?.morningTo || null,
      afternoonFrom:
        typeof values?.afternoonFrom === 'object'
          ? values?.afternoonFrom?.format('HH:mm')
          : values?.afternoonFrom || null,
      afternoonTo:
        typeof values?.afternoonTo === 'object' ? values?.afternoonTo?.format('HH:mm') : values?.afternoonTo || null,
      eveningFrom:
        typeof values?.eveningFrom === 'object' ? values?.eveningFrom?.format('HH:mm') : values?.eveningFrom || null,
      eveningTo: typeof values?.eveningTo === 'object' ? values?.eveningTo?.format('HH:mm') : values?.eveningTo || null,
      otherFrom: values?.otherFrom ? values?.otherFrom?.format('HH:mm') : null,
      otherTo: values?.otherTo ? values?.otherTo?.format('HH:mm') : null,
      workerMorning: values?.workerMorning || 0,
      workerAfternoon: values?.workerAfternoon || 0,
      workerEvening: values?.workerEvening || 0,
      workerOther: values?.workerOther || 0,
      description: values?.description?.content || '',
      files: values?.description?.files?.map((x: any) => ({ name: x.name, url: getFileNameFromUrl(x.url) })) || [],
      note: values?.note ?? '',
      leaderId: values?.leaderId,
      dayOffs: dayOffs,

      // shifts
      morningShiftCustomer: values?.morningShiftCustomer ? 1 : 0,
      afternoonShiftCustomer: values?.afternoonShiftCustomer ? 1 : 0,
      eveningShiftCustomer: values?.eveningShiftCustomer ? 1 : 0,
      otherShiftCustomer: values?.otherShiftCustomer ? 1 : 0,

      morningShift: morningShift(),
      afternoonShift: afternoonShift(),
      eveningShift: eveningShift(),
      otherShift: otherShift(),
      // otherShiftType,
      priceOtherShiftCustomer: values?.priceOtherShiftCustomer ?? null,
      titleOtherShiftCustomer: getValueTitleOtherShiftCustomer(values?.titleOtherShiftCustomer),
    };

    const isConvertNoteToTask = !isNil(dataNoteToTask?.noteId);

    if (isConvertNoteToTask) {
      (payload as any)['noteId'] = dataNoteToTask?.noteId;
    }

    let payloadCreateTask = omit(payload, [nameTempTypeDate]);

    if (isSmallClientTask) {
      // remove constructionSiteId
      (payloadCreateTask as any) = omit(payload, ['constructionSiteId']);

      // add thêm data vào payload
      (payloadCreateTask as any).customer = {
        ...values?.customer,
        nightUnitPrice: values?.customer?.nightUnitPrice ?? null,
        companyName: values?.customer?.companyName?.trim() || null,
      };
      (payloadCreateTask as any).constructionSite = values?.constructionSite;

      // call api create small task
      createTaskSmallMutation.mutate(payloadCreateTask as any, {
        onSuccess: () => {
          handleClose();
          handleMessageSuccess();
          queryClient.invalidateQueries(['construction-site-detail']);
          queryClient.invalidateQueries(['list-tasks--calendar']);
          queryClient.invalidateQueries(['list-tasks--home-calendar']);
          queryClient.invalidateQueries(['list-tasks-of-construction']);
          queryClient.invalidateQueries(['construction-site-task-detail']);
          queryClient.invalidateQueries([queryKeys.calendarWorkerReport]);

          //close modal add note
          setOpenModalAddNote(false);

          if (isConvertNoteToTask) {
            // refetch list note
            queryClient.invalidateQueries([queryKeys.notes.list]);
          }
        },
        onError(error, variables, context) {
          handleErrorMessage(error);
        },
      });

      return;
    }

    addTaskMutation.mutate(
      { payload: payloadCreateTask, companyId: companySelected?.id },
      {
        onSuccess(data, variables, context) {
          onCloseModal();
          handleMessageSuccess();
          queryClient.invalidateQueries(['construction-site-detail']);
          queryClient.invalidateQueries(['list-tasks--calendar']);
          queryClient.invalidateQueries(['list-tasks--home-calendar']);
          queryClient.invalidateQueries(['list-tasks-of-construction']);
          queryClient.invalidateQueries(['construction-site-task-detail']);
          queryClient.invalidateQueries([queryKeys.calendarWorkerReport]);

          //close modal add note
          setOpenModalAddNote(false);

          if (isConvertNoteToTask) {
            // refetch list note
            queryClient.invalidateQueries([queryKeys.notes.list]);
          }
        },
        onError(error, variables, context) {
          handleErrorMessage(error);
        },
      }
    );
  };

  const onCloseModal = () => {
    // Cookies.set('companyId', String(profile?.company?.companyId));
    handleClose();
    oldConstructionSiteRef.current = undefined;
  };

  // list staff of company
  const { data: dataWorkers } = useQuery({
    queryKey: [queryKeys.staff.listStaff],
    queryFn: () =>
      getStaff({
        filters: {
          pageIndex: 1,
          pageSize: 10000000,
          roleIds: [
            UserRole.ADMIN,
            UserRole.MANAGER,
            UserRole.OFFICE_MANAGER,
            UserRole.LEADER,
            // UserRole.VENDOR_WORKER,
            UserRole.PART_TIME_WORKER,
            UserRole.OTHER_WORKER,
            UserRole.WORKER,
          ],
          status: [CommonStatus.ACTIVE],
        },
      }),
    enabled: isSmallClientTask,
  });

  const [listWorker, setListWorker] = useState();
  const handleSearchWorker = _.debounce((keyword: string, shift: Shift) => {
    let vendorArr: any = [];
    let startTime: any;
    let endTime: any;
    let shiftConstructionSite: any;
    if (shift === 'morning') {
      vendorArr = form.getFieldValue('vendorsMorning');
      startTime = dateUtils.getHour(form.getFieldValue('morningFrom'));
      endTime = dateUtils.getHour(form.getFieldValue('morningTo'));
      shiftConstructionSite = SHIFT_TASK_SEARCH_CONSTRUCTION.MORNING;
    }
    if (shift === 'afternoon') {
      vendorArr = form.getFieldValue('vendorsAfternoon');
      startTime = dateUtils.getHour(form.getFieldValue('afternoonFrom'));
      endTime = dateUtils.getHour(form.getFieldValue('afternoonTo'));
      shiftConstructionSite = SHIFT_TASK_SEARCH_CONSTRUCTION.AFTERNOON;
    }
    if (shift === 'evening') {
      vendorArr = form.getFieldValue('vendorsEvening');
      startTime = dateUtils.getHour(form.getFieldValue('eveningFrom'));
      endTime = dateUtils.getHour(form.getFieldValue('eveningTo'));
      shiftConstructionSite = SHIFT_TASK_SEARCH_CONSTRUCTION.EVENING;
    }
    if (shift === 'other') {
      vendorArr = form.getFieldValue('vendorsOther');
      startTime = dateUtils.getHour(form.getFieldValue('otherFrom'));
      endTime = dateUtils.getHour(form.getFieldValue('otherTo'));
      shiftConstructionSite = SHIFT_TASK_SEARCH_CONSTRUCTION.OTHER;
    }
    const vendorIds = vendorArr ? vendorArr?.map((el: any) => el.id) : [];

    if (isSmallClientTask) {
      getStaff({
        filters: {
          pageIndex: 1,
          pageSize: 10000000,
          roleIds: [
            UserRole.ADMIN,
            UserRole.MANAGER,
            UserRole.OFFICE_MANAGER,
            UserRole.LEADER,
            // UserRole.VENDOR_WORKER,
            UserRole.PART_TIME_WORKER,
            UserRole.OTHER_WORKER,
            UserRole.WORKER,
          ],
          status: [CommonStatus.ACTIVE],
          keyword,
        },
      }).then((res) => {
        setListWorker(res?.data as any);
      });
    } else {
      searchConstructionSiteWorkerV2(
        {
          keyword,
          constructionSiteId: form.getFieldValue('constructionSiteId'),
          workingDay: form.getFieldValue('startDate')?.format('YYYY-MM-DD'),
          startTime,
          endTime,
          pageSize: 10000000,
          shift: shiftConstructionSite,
          vendorIds,
          leaderId: leader,
          roleIds:
            currentRoleInCompany === UserRole.VENDOR
              ? [UserRole.VENDOR_WORKER]
              : [
                  UserRole.WORKER,
                  UserRole.OTHER_WORKER,
                  UserRole.PART_TIME_WORKER,
                  UserRole.ADMIN,
                  UserRole.OFFICE_MANAGER,
                  UserRole.MANAGER,
                ],
        },
        companySelected?.id
      ).then((res) => {
        setListWorker(res?.data);
      });
    }
  }, 300);

  const [leader, setLeader] = useState<any>();
  const handleSelectLeader = (id: number) => {
    setLeader(constructionSiteDetail?.data?.workers?.filter((x: any) => x?.id === id));
  };

  const handleClearLeader = () => {
    setLeader(undefined);
  };

  const joinedVendors = useMemo(() => {
    return constructionSiteDetail?.data?.vendors?.filter((x: any) => x.csmStatus === STATUS.ACTIVE) || [];
  }, [constructionSiteDetail]);

  const isConstructionCompletedOrPause = [ConstructionSiteState.COMPLETED, ConstructionSiteState.PAUSE].includes(
    constructionSiteDetail?.data?.state
  );

  const { spanCol1, spanCol2, widthModal } = useGetWidth({
    // checked: taskType === TaskTypes.NORMAL ? checked : checkedSmallTask,
    checked: checked,
    mode: ModeType.CREATE,
    companySelected: companySelected,
    isOnlyCustomerOfTask,
  });

  const [name, setName] = useState<string>('');

  const menu = (
    <Menu
      multiple
      selectable
      onSelect={({ item, key }) => {
        setName(key as string);
      }}
      onDeselect={({ item, key }) => {
        setName('');
      }}
      selectedKeys={[name]}
    >
      {listTaskNames.map((item) => (
        <Menu.Item key={item.name} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {item.name}
          {name === item.name && <img src={icClose} alt="close" />}
        </Menu.Item>
      ))}
    </Menu>
  );

  const { handleFillDefaultTime } = useFillDefaultTime();

  // set default company id
  useEffect(() => {
    // when is not super calendar
    if (!isSuperCalendar) {
      setCompanySelected(profileCompany as any);

      return;
    }

    // check xem cty default co ton tai trong list company active khong?
    const isDefaultCompanyExistInList = listCompanyActive?.some(
      (company) => company?.id === profileCompanyOneCompany?.id && !company.disabled
    );
    if (isDefaultCompanyExistInList) {
      const defaultCompanySelected = listCompanyActive.find((company) => company.id === profileCompanyOneCompany?.id);

      setCompanySelected(defaultCompanySelected);
      return;
    }

    // if user is admin in one of the companies
    if (companyWithUserIsAdmin?.id) {
      // Cookies.set('companyId', String(companyWithUserIsAdmin?.id));
      setCompanySelected(companyWithUserIsAdmin);
      return;
    }

    // if user is not admin in any company -> set default first company in list
    if (isNil(companyWithUserIsAdmin?.id)) {
      setCompanySelected(listCompanyActive.find((company) => !company.disabled) as ICompanyActive);
    }
  }, [companyWithUserIsAdmin]);

  // default project
  const defaultProject = useMemo(() => {
    return constructionSiteDetail?.data;
  }, [constructionSiteDetail]);

  const [filtersVendor, setFilersVendor] = useState({
    pageSize: 20,
    keyword: '',
    pageIndex: 1,
  });
  // list vendor of company
  const {
    data: dataVendors,
    hasNextPage: hasNextPageVendor,
    fetchNextPage: fetchNextPageVendor,
  } = useInfiniteQuery({
    queryKey: [queryKeys.vendors.listVendorByCompany, JSON.stringify(filtersVendor)],
    queryFn: ({ pageParam = 1 }) => {
      return getListVendor({ ...filtersVendor, pageIndex: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      const { pageIndex, totalPages } = lastPage;

      if (pageIndex >= totalPages) {
        return false;
      }

      const nextPage = pageIndex + 1;

      return nextPage;
    },
    keepPreviousData: true,
    enabled: isSmallClientTask,
  });
  const listVendor = dataVendors?.pages?.map((page) => page?.data)?.reduce((arr, data) => [...arr, ...data], []) || [];
  const handleScrollVendor = (e: any) => {
    const target: any = e.target;

    if (target?.scrollTop + target?.clientHeight >= target?.scrollHeight && hasNextPageVendor) {
      fetchNextPageVendor();
    }
  };
  const handleSearchVendor = debounce((keyword: string) => {
    setFilersVendor({ ...filtersVendor, keyword, pageIndex: 1 });
  }, 500);
  const handleOnBlurVendor = () => {
    setFilersVendor({ ...filtersVendor, keyword: '', pageIndex: 1 });
  };
  const propsOfSelectVendor = isSmallClientTask
    ? {
        onPopupScroll: handleScrollVendor,
        onSearch: handleSearchVendor,
        filterOption: false,
        onBlur: handleOnBlurVendor,
      }
    : {};
  const isAdmin = ROLES_ADMIN.includes(currentRoleInCompany);

  // Check xem có hiển thị panel của shift nào không
  // Admin lấy theo shift
  // Customer lấy theo shiftCustomer
  const isShowPanelMorning = isAdmin ? !!checkedOfAdmin.morningShift : !!checked.morning;
  const isShowPanelAfternoon = isAdmin ? !!checkedOfAdmin.afternoonShift : !!checked.afternoon;
  const isShowPanelEvening = isAdmin ? !!checkedOfAdmin.eveningShift : !!checked.evening;
  const isShowPanelOther = isAdmin ? !!checkedOfAdmin.otherShift : !!checked.other;

  // Check xem liệu admin chưa chọn shift nào
  const isAdminNotSelectShift =
    isAdmin && [isShowPanelMorning, isShowPanelAfternoon, isShowPanelEvening, isShowPanelOther].every((x) => !x);

  // Check xem có hiển thị button tạo shift other không
  const isShowButtonCreateOtherShift = isAdmin && !checkedOfAdmin.otherShift;
  const propsOfMaxWorkerShift = isSmallClientTask
    ? {
        spanMaxWorker: 24,
        isHideAddCertificate: true,
      }
    : {};

  const handleFillDefaultCountWorkWorker = ({ shift, workerId }: { shift: Shift; workerId: number }) => {
    const isFixPriceConstructionSite = checkIsFixPriceConstructionSite({
      constructionSiteDetail: constructionSiteDetail?.data,
      isSmallClientTask,
      values: form.getFieldsValue(true),
    });

    const initialCountWork = getInitialCountWorkWorker({
      values: form.getFieldsValue(true),
      checked,
      checkedOfAdmin,
      shift,
      isFixPriceConstructionSite,
    });

    const nameFromCountWork = `count_work_${shift}_${workerId}`;

    form.setFieldsValue({
      [nameFromCountWork]: initialCountWork,
    });
  };

  return (
    <CommonModal
      // width={taskType === TaskTypes.NORMAL ? widthModal : 1200}
      width={widthModal}
      handleClose={onCloseModal}
      isModalVisible
      title={
        <div>
          <Space className={styles.wrapperTitle}>
            <div>手配の詳細</div>
            <div className={classNames('d-flex justify-content-center mr-20')}>
              <Button
                style={{ marginRight: 5, width: 120 }}
                htmlType="button"
                className="btn-outline"
                onClick={handleClose}
              >
                キャンセル
              </Button>

              <Button
                style={{ marginLeft: 5, width: 120 }}
                htmlType="submit"
                type="primary"
                disabled={addTaskMutation.isLoading}
                onClick={() => {
                  form.validateFields().then((values) => {
                    onClickCreateTask(values);
                  });
                }}
              >
                {'手配する'}
              </Button>
            </div>
          </Space>
        </div>
      }
      footer={null}
      maskClosable={false}
      className={styles.modal}
    >
      <Loader isLoading={isLoadingConstructionSiteDetail}>
        {ROLES_ADMIN.includes(profile.company.roleId) && (
          <Radio.Group
            className="mb-10"
            value={taskType}
            onChange={(e) => {
              setTaskType(e.target.value);

              const oldValues = form.getFieldsValue(true);

              // reset value in form
              form.resetFields();

              form.setFieldsValue({
                morningFrom: oldValues?.morningFrom,
                morningTo: oldValues?.morningTo,
                afternoonFrom: oldValues?.afternoonFrom,
                afternoonTo: oldValues?.afternoonTo,
                eveningFrom: oldValues?.eveningFrom,
                eveningTo: oldValues?.eveningTo,
                otherFrom: oldValues?.otherFrom,
                otherTo: oldValues?.otherTo,
                [nameTempTypeDate]: oldValues[nameTempTypeDate],
                startDate: oldValues?.startDate,
                endDate: oldValues?.endDate,
                morningShiftCustomer: oldValues?.morningShiftCustomer,
                afternoonShiftCustomer: oldValues?.afternoonShiftCustomer,
                eveningShiftCustomer: oldValues?.eveningShiftCustomer,
                otherShiftCustomer: oldValues?.otherShiftCustomer,
                maxWorkerMorning: oldValues?.maxWorkerMorning,
                maxWorkerAfternoon: oldValues?.maxWorkerAfternoon,
                maxWorkerEvening: oldValues?.maxWorkerEvening,
                maxWorkerOther: oldValues?.maxWorkerOther,
                workerMorning: oldValues?.workerMorning,
                workerAfternoon: oldValues?.workerAfternoon,
                workerEvening: oldValues?.workerEvening,
                workerOther: oldValues?.workerOther,
              });

              // reset checked
              // setChecked({
              //   morning: false,
              //   afternoon: false,
              //   evening: false,
              //   other: false,
              // });

              // setCheckedOfAdmin({
              //   morningShift: false,
              //   afternoonShift: false,
              //   eveningShift: false,
              //   otherShift: false,
              // });

              setSelectedVendor([]);
            }}
          >
            <Radio value={TaskTypes.NORMAL}>{textJP.normalProject}</Radio>
            <Radio value={TaskTypes.SMALL}>{textJP.smallProject}</Radio>
          </Radio.Group>
        )}

        <Form
          layout="vertical"
          form={form}
          scrollToFirstError
          onFinish={onClickCreateTask}
          className="fixed-header-form"
        >
          <Row gutter={[20, 20]}>
            <Col span={spanCol1}>
              <div className={styles.colWrap}>
                {isSmallClientTask ? (
                  <InformationCustomer className="mb-10" />
                ) : (
                  <SectionWrapper className="mb-10">
                    <LabelRequired label="プロジェクト" />
                    {isConstructionCompletedOrPause ? (
                      <p className="heading-14">プロジェクト名: {constructionSiteDetail?.data?.name}</p>
                    ) : (
                      <Form.Item name="constructionSiteId" rules={[rulesForm().required]} className="mb-10">
                        <SelectProject
                          onSearch={handleSearchConstructionSite}
                          onChange={handleSelectConstructionSite}
                          filters={constructionSiteFilter}
                          companySelected={companySelected}
                          defaultValue={defaultProject}
                          numberCompanyUserCanCreateTask={numberCompanyUserCanCreateTask}
                        />
                      </Form.Item>
                    )}

                    <DetailsProject
                      details={constructionSiteDetail}
                      roleInCompany={currentRoleInCompany}
                      className="mb-10"
                    />

                    <NoteConstruction disabled={isOnlyVendor} />

                    <div className={styles.listFiles}>
                      {constructionSiteDetail?.data?.files.map((file: any, index: number) => (
                        <Typography.Link target="_blank" href={file.url} key={file?.id || index}>
                          {file?.name}
                        </Typography.Link>
                      ))}
                    </div>
                  </SectionWrapper>
                )}

                <SectionWrapper className="mb-10">
                  <Space size="small" align="center">
                    <img src={icCalendarWhite2} alt="" />

                    <div className="strong">手配情報</div>
                  </Space>

                  {isSmallClientTask && (
                    <>
                      <Form.Item
                        label={textJP.projectName}
                        rules={[rulesForm().required]}
                        className="mb-10"
                        required
                        name={['constructionSite', 'name']}
                      >
                        <CommonInput />
                      </Form.Item>

                      <Form.Item
                        label={textJP.label.projectAddress}
                        rules={[rulesForm().required]}
                        className="mb-10"
                        required
                        name={['constructionSite', 'address']}
                      >
                        <CommonInput />
                      </Form.Item>

                      <Form.Item
                        label={textJP.typeOfArrangement}
                        rules={[rulesForm().required]}
                        className="mb-10"
                        required
                        name={['constructionSite', 'type']}
                      >
                        <CommonSelect options={listProjectType} showSearch={false} />
                      </Form.Item>
                    </>
                  )}

                  <TimeTaskPicker form={form} />

                  <Row>
                    <Col span={24}>
                      <AccessControlMultiCompany
                        accessibleRoles={[
                          UserRole.ADMIN,
                          UserRole.MANAGER,
                          UserRole.OFFICE_MANAGER,
                          UserRole.LEADER,
                          UserRole.CUSTOMER,
                          UserRole.VENDOR,
                        ]}
                        hidden={isOnlyVendor}
                        roleInCompany={currentRoleInCompany}
                      >
                        <Space size="small" align="center" style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <LabelRequired label="プロジェクト時間" className="mr-10" />

                          <Space align="center">
                            {(isSmallClientTask ? true : profileCompany?.morningShift === STATUS.ACTIVE) && (
                              <Form.Item
                                className="mb-0"
                                rules={[rulesForm().validateCheckBoxShiftTaskV2()]}
                                name="morningShiftCustomer"
                                valuePropName="checked"
                                noStyle
                                dependencies={['afternoonShift', 'eveningShift', 'otherShift']}
                              >
                                <Checkbox onChange={(e: any) => handleSelectShift(e, 'morning')} className="strong">
                                  午前
                                </Checkbox>
                              </Form.Item>
                            )}

                            {(isSmallClientTask ? true : profileCompany?.afternoonShift === STATUS.ACTIVE) && (
                              <Form.Item
                                className="mb-0"
                                rules={[rulesForm().validateCheckBoxShiftTaskV2()]}
                                name="afternoonShiftCustomer"
                                valuePropName="checked"
                                noStyle
                                dependencies={['morningShift', 'eveningShift', 'otherShift']}
                              >
                                <Checkbox onChange={(e: any) => handleSelectShift(e, 'afternoon')} className="strong">
                                  午後
                                </Checkbox>
                              </Form.Item>
                            )}

                            {(isSmallClientTask ? true : profileCompany?.eveningShift === STATUS.ACTIVE) && (
                              <Form.Item
                                className="mb-0"
                                rules={[rulesForm().validateCheckBoxShiftTaskV2()]}
                                name="eveningShiftCustomer"
                                valuePropName="checked"
                                noStyle
                                dependencies={['morningShift', 'afternoonShift', 'otherShift']}
                              >
                                <Checkbox onChange={(e: any) => handleSelectShift(e, 'evening')} className="strong">
                                  夜間
                                </Checkbox>
                              </Form.Item>
                            )}

                            <Form.Item
                              className="mb-0"
                              rules={[rulesForm().validateCheckBoxShiftTaskV2()]}
                              name="otherShiftCustomer"
                              valuePropName="checked"
                              noStyle
                              dependencies={['morningShift', 'afternoonShift', 'eveningShift']}
                            >
                              <Checkbox onChange={(e: any) => handleSelectShift(e, 'other')} className="strong">
                                オプション
                              </Checkbox>
                            </Form.Item>
                          </Space>
                        </Space>
                      </AccessControlMultiCompany>

                      <ErrorCheckBoxShift
                        nameCheckBoxMorningShift="morningShiftCustomer"
                        nameCheckBoxAfternoonShift="afternoonShiftCustomer"
                        nameCheckBoxEveningShift="eveningShiftCustomer"
                        nameCheckBoxOtherShift="otherShiftCustomer"
                      />

                      <AccessControlMultiCompany
                        accessibleRoles={[UserRole.CUSTOMER, UserRole.VENDOR]}
                        hidden={!isCustomer}
                        roleInCompany={currentRoleInCompany}
                      >
                        {Object.values(checked).filter((x) => x).length > 0 && (
                          <Row className="mb-10" gutter={[8, 8]}>
                            <TimeDesire startTimeDesireName="startTimeDesire" endTimeDesireName="endTimeDesire" />
                          </Row>
                        )}
                      </AccessControlMultiCompany>

                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldsValue }) => {
                          const isFixPriceConstructionSite = checkIsFixPriceConstructionSite({
                            values: getFieldsValue(true),
                            isSmallClientTask,
                            constructionSiteDetail: constructionSiteDetail?.data,
                          });

                          if (isFixPriceConstructionSite) return null;

                          return (
                            <AccessControlMultiCompany
                              accessibleRoles={[
                                UserRole.ADMIN,
                                UserRole.MANAGER,
                                UserRole.OFFICE_MANAGER,
                                UserRole.LEADER,
                                UserRole.CUSTOMER,
                                UserRole.VENDOR,
                              ]}
                              hidden={isOnlyVendor}
                              roleInCompany={currentRoleInCompany}
                            >
                              {(checked.afternoon || checked.morning || checked.evening || checked.other) && (
                                <div className="color-red">人工</div>
                              )}

                              <Row gutter={[5, 5]}>
                                {/* Number max workers morningShift */}
                                {checked.morning && (
                                  <Col span={24}>
                                    <Row>
                                      <MaxWorkerShift
                                        typeShift="morning"
                                        form={form}
                                        currentRoleInCompany={currentRoleInCompany}
                                        selectedConstructionSiteId={selectedConstructionSiteId}
                                        certificateOptions={certificateOptions}
                                        labelMaxWorker="午前"
                                        nameCheckBox="morningShiftCustomer"
                                        {...propsOfMaxWorkerShift}
                                      />
                                    </Row>
                                  </Col>
                                )}

                                {/* Number max workers afternoonShift */}
                                {checked.afternoon && (
                                  <Col span={24}>
                                    <Row>
                                      <MaxWorkerShift
                                        typeShift="afternoon"
                                        form={form}
                                        currentRoleInCompany={currentRoleInCompany}
                                        selectedConstructionSiteId={selectedConstructionSiteId}
                                        certificateOptions={certificateOptions}
                                        labelMaxWorker="午後"
                                        nameCheckBox="afternoonShiftCustomer"
                                        {...propsOfMaxWorkerShift}
                                      />
                                    </Row>
                                  </Col>
                                )}

                                {/* Number max workers eveningShift */}
                                {checked.evening && (
                                  <Col span={24}>
                                    <Row>
                                      <MaxWorkerShift
                                        typeShift="evening"
                                        form={form}
                                        currentRoleInCompany={currentRoleInCompany}
                                        selectedConstructionSiteId={selectedConstructionSiteId}
                                        certificateOptions={certificateOptions}
                                        labelMaxWorker="夜間"
                                        nameCheckBox="eveningShiftCustomer"
                                        {...propsOfMaxWorkerShift}
                                      />
                                    </Row>
                                  </Col>
                                )}

                                {/* Number max workers otherShift */}
                                {checked.other && (
                                  <Col span={24}>
                                    <Row>
                                      <MaxWorkerShift
                                        typeShift="other"
                                        form={form}
                                        currentRoleInCompany={currentRoleInCompany}
                                        selectedConstructionSiteId={selectedConstructionSiteId}
                                        certificateOptions={certificateOptions}
                                        labelMaxWorker="オプション"
                                        nameCheckBox="otherShiftCustomer"
                                        unitPriceCustomerOptions={isSmallClientTask ? [] : unitPriceCustomerOptions}
                                        isCanViewPriceOfCustomer={ROLES_ADMIN.includes(currentRoleInCompany)}
                                        {...propsOfMaxWorkerShift}
                                      />
                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            </AccessControlMultiCompany>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item label="仕事の内容" name="description" labelAlign="left" className="mt-20 mb-10">
                    <TextAreaWithAttachment disabled={isOnlyVendor} autoSize={{ minRows: 3 }} />
                  </Form.Item>

                  {!isSmallClientTask && (
                    <Tooltip title="手配名" placement="topLeft">
                      <CustomDropDown overlay={menu} trigger={['click']} className="mb-10">
                        <div className="cursorPointer">
                          <LabelTaskName label={name} />
                        </div>
                      </CustomDropDown>
                    </Tooltip>
                  )}

                  {/* Select Leader normal task */}
                  <AccessControlMultiCompany
                    accessibleRoles={ROLES_ADMIN}
                    roleInCompany={currentRoleInCompany}
                    hidden={isSmallClientTask}
                  >
                    <SelectLeaderTask
                      constructionSiteDetail={constructionSiteDetail}
                      listStaffOfCompany={dataWorkers?.data}
                      form={form}
                      setLeader={setLeader}
                      onSelect={handleSelectLeader}
                      onClear={handleClearLeader}
                      isSmallClientTask={isSmallClientTask}
                    />

                    {leader && <div className="mb-10">電話番号：{leader[0]?.phone}</div>}
                  </AccessControlMultiCompany>

                  <AccessControlMultiCompany
                    accessibleRoles={ROLES_ADMIN}
                    roleInCompany={currentRoleInCompany}
                    hidden={!isSmallClientTask}
                  >
                    <NoteTaskInput className="mb-10" />
                  </AccessControlMultiCompany>

                  {/* Select leader small task */}
                  <AccessControlMultiCompany
                    accessibleRoles={ROLES_ADMIN}
                    roleInCompany={currentRoleInCompany}
                    hidden={!isSmallClientTask}
                  >
                    <SelectLeaderTask
                      constructionSiteDetail={constructionSiteDetail}
                      listStaffOfCompany={dataWorkers?.data}
                      form={form}
                      setLeader={setLeader}
                      onSelect={handleSelectLeader}
                      onClear={handleClearLeader}
                      isSmallClientTask={isSmallClientTask}
                      classFormItem="mb-0"
                    />

                    {leader && <div className="mt-10">電話番号：{leader[0]?.phone}</div>}
                  </AccessControlMultiCompany>
                </SectionWrapper>

                <AccessControlMultiCompany
                  accessibleRoles={ROLES_ADMIN}
                  roleInCompany={currentRoleInCompany}
                  hidden={isSmallClientTask}
                >
                  <SectionWrapper className="mb-10">
                    <NoteTaskInput />
                  </SectionWrapper>
                </AccessControlMultiCompany>

                {isSmallClientTask && <PaymentInformation className="mt-10" />}
              </div>
            </Col>

            <Col span={spanCol2}>
              <div className={styles.colWrap}>
                <AccessControlMultiCompany
                  accessibleRoles={[
                    UserRole.ADMIN,
                    UserRole.MANAGER,
                    UserRole.OFFICE_MANAGER,
                    UserRole.LEADER,
                    UserRole.VENDOR,
                  ]}
                  hidden={isVendorCustomer}
                  roleInCompany={currentRoleInCompany}
                >
                  <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                    <CommonSpace className="mb-10" align="start" justify="space-between" widthFull>
                      <LabelRequired label="プロジェクト時間" />

                      <div className="text-right">
                        <Space align="center">
                          {(isSmallClientTask ? true : profileCompany?.morningShift === STATUS.ACTIVE) && (
                            <CheckBoxShiftAdmin
                              checkBoxText={textJP.shift.morningShift}
                              nameCountShift="otherShiftTypeMorning"
                              checked={checkedOfAdmin.morningShift}
                              onChange={(e) => {
                                const isChecked = e.target.checked;

                                if (isChecked) {
                                  setCheckedOfAdmin((pre) => ({ ...pre, morningShift: isChecked }));
                                }

                                if (!isChecked) {
                                  handleDeletePanel('morning');
                                }
                              }}
                            />
                          )}

                          {(isSmallClientTask ? true : profileCompany?.afternoonShift === STATUS.ACTIVE) && (
                            <CheckBoxShiftAdmin
                              checkBoxText={textJP.shift.afternoonShift}
                              nameCountShift="otherShiftTypeAfternoon"
                              checked={checkedOfAdmin.afternoonShift}
                              onChange={(e) => {
                                const isChecked = e.target.checked;

                                if (isChecked) {
                                  setCheckedOfAdmin((pre) => ({ ...pre, afternoonShift: isChecked }));
                                }

                                if (!isChecked) {
                                  handleDeletePanel('afternoon');
                                }
                              }}
                            />
                          )}

                          {(isSmallClientTask ? true : profileCompany?.eveningShift === STATUS.ACTIVE) && (
                            <CheckBoxShiftAdmin
                              checkBoxText={textJP.shift.eveningShift}
                              nameCountShift="otherShiftTypeEvening"
                              checked={checkedOfAdmin.eveningShift}
                              onChange={(e) => {
                                const isChecked = e.target.checked;

                                if (isChecked) {
                                  setCheckedOfAdmin((pre) => ({ ...pre, eveningShift: isChecked }));
                                }

                                if (!isChecked) {
                                  handleDeletePanel('evening');
                                }
                              }}
                            />
                          )}

                          <CheckBoxShiftAdmin
                            checkBoxText={textJP.shift.otherShift}
                            checked={checkedOfAdmin.otherShift}
                            onChange={(e) => {
                              const isChecked = e.target.checked;

                              if (isChecked) {
                                setCheckedOfAdmin((pre) => ({ ...pre, otherShift: isChecked }));
                              }

                              if (!isChecked) {
                                handleDeletePanel('other');
                              }
                            }}
                          />
                        </Space>

                        {isAdminNotSelectShift && (
                          <div className="text-error strong">{messageError.needAtLeastOneShift}</div>
                        )}

                        <ErrorCheckBoxShiftAdmin checkedOfAdmin={checkedOfAdmin} />
                      </div>
                    </CommonSpace>
                  </AccessControlMultiCompany>

                  {/* Total work output of workers in shifts */}
                  <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                    <Row gutter={[20, 20]} className="mb-10" align="middle">
                      <Col span={12} className="strong text-error">
                        {textJP.label.numberWorker}
                      </Col>

                      <Col span={12}>
                        <Row>
                          {checkedOfAdmin.morningShift && (
                            <Col span={12}>
                              <CountWorkShift label={textJP.shift.morningShift} shift="morning" />
                            </Col>
                          )}

                          {checkedOfAdmin.afternoonShift && (
                            <Col span={12} className="strong text-error">
                              <CountWorkShift label={textJP.shift.afternoonShift} shift="afternoon" />
                            </Col>
                          )}

                          {checkedOfAdmin.eveningShift && (
                            <Col span={12} className="strong text-error">
                              <CountWorkShift label={textJP.shift.eveningShift} shift="evening" />
                            </Col>
                          )}

                          {checkedOfAdmin.otherShift && (
                            <Col span={12} className="strong text-error">
                              <CountWorkShift label={textJP.shift.otherShift} shift="other" />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </AccessControlMultiCompany>

                  {isShowPanelMorning && (
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']}
                    >
                      <Panel
                        key="morningShift"
                        header="午前"
                        extra={
                          <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <IconDeleteShift
                              onClick={(e) => {
                                e.stopPropagation();

                                handleDeletePanel('morning');
                              }}
                            />
                          </AccessControlMultiCompany>
                        }
                      >
                        <Row gutter={8}>
                          <Col xs={12}>
                            <Form.Item
                              label="開始時刻"
                              name="morningFrom"
                              rules={[rulesForm().required]}
                              labelAlign="left"
                            >
                              <CommonDatepicker
                                picker={TYPE_PICKER.TIME}
                                allowClear={false}
                                disabled={isOnlyVendor}
                                minuteStep={5}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              label="終了時刻"
                              name="morningTo"
                              rules={[rulesForm().required]}
                              labelAlign="left"
                            >
                              <CommonDatepicker
                                picker={TYPE_PICKER.TIME}
                                allowClear={false}
                                disabled={isOnlyVendor}
                                minuteStep={5}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label={textJP.label.numberPeople}
                              name={'workerMorning'}
                              rules={[
                                {
                                  required: true,
                                  message: '全ての項目を入力してください。',
                                },
                                () => ({
                                  validator(rule, value) {
                                    if (value && +value < 0) {
                                      return Promise.reject('必要人は0人以上です');
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                              labelAlign="left"
                            >
                              <CommonInputNumber min={0} isOnlyInteger />
                            </Form.Item>
                          </Col>
                          <AccessControlMultiCompany
                            accessibleRoles={[
                              UserRole.ADMIN,
                              UserRole.MANAGER,
                              UserRole.OFFICE_MANAGER,
                              UserRole.LEADER,
                            ]}
                            roleInCompany={currentRoleInCompany}
                          >
                            <Col span={24}>
                              <Form.Item label="サプライヤー" name="vendorsMorning" labelAlign="left" shouldUpdate>
                                <VendorPicker
                                  options={isSmallClientTask ? listVendor : joinedVendors}
                                  disabled={isOnlyVendor}
                                  handleSelectedVendor={(props) => {
                                    handleSelectedVendor({ ...props });

                                    if (isSmallClientTask) {
                                      handleOnBlurVendor();
                                    }
                                  }}
                                  shift="morning"
                                  form={form}
                                  {...propsOfSelectVendor}
                                />
                              </Form.Item>
                            </Col>
                          </AccessControlMultiCompany>

                          <AccessControlMultiCompany
                            accessibleRoles={[
                              UserRole.ADMIN,
                              UserRole.MANAGER,
                              UserRole.OFFICE_MANAGER,
                              UserRole.LEADER,
                              UserRole.VENDOR,
                            ]}
                            roleInCompany={currentRoleInCompany}
                          >
                            <Col span={24}>
                              <Form.Item
                                label="スタッフ"
                                name="workerIdsMorning"
                                labelAlign="left"
                                className={styles.workerSelectList}
                              >
                                <CommonSelect
                                  optionLabel={'name'}
                                  filterOption={false}
                                  onSearch={(e: any) => handleSearchWorker(e, 'morning')}
                                  onFocus={() => handleSearchWorker('', 'morning')}
                                  options={listWorker || []}
                                  mode={TYPE_INPUT.MULTIPLE}
                                  suffixIcon={<img src={icSearch} style={{ margin: -12 }} alt="" />}
                                  onSelect={(workerId: number) =>
                                    handleFillDefaultCountWorkWorker({ shift: 'morning', workerId })
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                shouldUpdate={(prev, next) => prev.workerIdsMorning !== next.workerIdsMorning}
                                noStyle
                              >
                                {() => {
                                  let allWorkers: any = isSmallClientTask
                                    ? dataWorkers?.data || []
                                    : constructionSiteDetail?.data?.workers || [];

                                  if (currentRoleInCompany === UserRole.VENDOR) {
                                    allWorkers = allWorkers?.filter((x: any) => x.managerId === profile.id);
                                  }
                                  const selectedWorkerIds = form.getFieldValue('workerIdsMorning');
                                  const selectedWorker = allWorkers?.filter((x: any) =>
                                    selectedWorkerIds?.includes(x.id)
                                  );

                                  return (
                                    <Row className={styles.workerList} gutter={8}>
                                      {selectedWorker?.map((worker: any) => (
                                        <Col
                                          span={24}
                                          key={worker.id}
                                          style={{ marginTop: 8 }}
                                          className={classNames({
                                            [styles.disabled]:
                                              currentRoleInCompany === UserRole.VENDOR &&
                                              worker.managerId !== profile.id,
                                          })}
                                        >
                                          <ItemWorkerSelected
                                            worker={worker}
                                            handleRemoveWorker={handleRemoveWorker('morning')}
                                            shift="morning"
                                            roleId={currentRoleInCompany}
                                            // companyId={companySelected?.id}
                                            isSmallClientTask={isSmallClientTask}
                                            options={license?.data}
                                            form={form}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </AccessControlMultiCompany>
                        </Row>
                      </Panel>
                    </Collapse>
                  )}

                  {isShowPanelAfternoon && (
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']}
                    >
                      <Panel
                        key="afternoonShift"
                        header="午後"
                        extra={
                          <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <IconDeleteShift
                              onClick={(e) => {
                                e.stopPropagation();

                                handleDeletePanel('afternoon');
                              }}
                            />
                          </AccessControlMultiCompany>
                        }
                      >
                        <Row gutter={8}>
                          <Col xs={12}>
                            <Form.Item
                              label="開始時刻"
                              name="afternoonFrom"
                              rules={[rulesForm().required]}
                              labelAlign="left"
                            >
                              <CommonDatepicker
                                picker={TYPE_PICKER.TIME}
                                allowClear={false}
                                disabled={isOnlyVendor}
                                minuteStep={5}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              label="終了時刻"
                              name="afternoonTo"
                              rules={[rulesForm().required]}
                              labelAlign="left"
                            >
                              <CommonDatepicker
                                picker={TYPE_PICKER.TIME}
                                allowClear={false}
                                disabled={isOnlyVendor}
                                minuteStep={5}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label={textJP.label.numberPeople}
                              name={'workerAfternoon'}
                              rules={[
                                {
                                  required: true,
                                  message: '全ての項目を入力してください。',
                                },
                                () => ({
                                  validator(rule, value) {
                                    if (value && +value < 0) {
                                      return Promise.reject('必要人は0人以上です');
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                              labelAlign="left"
                            >
                              <CommonInputNumber min={0} isOnlyInteger />
                            </Form.Item>
                          </Col>
                          <AccessControlMultiCompany
                            accessibleRoles={[
                              UserRole.ADMIN,
                              UserRole.MANAGER,
                              UserRole.OFFICE_MANAGER,
                              UserRole.LEADER,
                            ]}
                            roleInCompany={currentRoleInCompany}
                          >
                            <Col span={24}>
                              <Form.Item label="サプライヤー" name="vendorsAfternoon" labelAlign="left">
                                <VendorPicker
                                  options={isSmallClientTask ? listVendor : joinedVendors}
                                  disabled={isOnlyVendor}
                                  handleSelectedVendor={(props) => {
                                    handleSelectedVendor({ ...props });

                                    if (isSmallClientTask) {
                                      handleOnBlurVendor();
                                    }
                                  }}
                                  shift="afternoon"
                                  form={form}
                                  {...propsOfSelectVendor}
                                />
                              </Form.Item>
                            </Col>
                          </AccessControlMultiCompany>
                          <AccessControlMultiCompany
                            accessibleRoles={[
                              UserRole.ADMIN,
                              UserRole.MANAGER,
                              UserRole.OFFICE_MANAGER,
                              UserRole.LEADER,
                              UserRole.VENDOR,
                            ]}
                            roleInCompany={currentRoleInCompany}
                          >
                            <Col span={24}>
                              <Form.Item
                                label="スタッフ"
                                name="workerIdsAfternoon"
                                labelAlign="left"
                                className={styles.workerSelectList}
                              >
                                <CommonSelect
                                  optionLabel={'name'}
                                  filterOption={false}
                                  onSearch={(e: any) => handleSearchWorker(e, 'afternoon')}
                                  onFocus={() => handleSearchWorker('', 'afternoon')}
                                  options={listWorker || []}
                                  mode={TYPE_INPUT.MULTIPLE}
                                  suffixIcon={<img src={icSearch} style={{ margin: -12 }} alt="" />}
                                  onSelect={(workerId: number) =>
                                    handleFillDefaultCountWorkWorker({ shift: 'afternoon', workerId })
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                shouldUpdate={(prev, next) => prev.workerIdsAfternoon !== next.workerIdsAfternoon}
                                noStyle
                              >
                                {() => {
                                  let allWorkers: any = isSmallClientTask
                                    ? dataWorkers?.data || []
                                    : constructionSiteDetail?.data?.workers || [];

                                  if (currentRoleInCompany === UserRole.VENDOR) {
                                    allWorkers = allWorkers?.filter((x: any) => x.managerId === profile.id);
                                  }
                                  const selectedWorkerIds = form.getFieldValue('workerIdsAfternoon');
                                  const selectedWorker = allWorkers?.filter((x: any) =>
                                    selectedWorkerIds?.includes(x.id)
                                  );
                                  return (
                                    <Row className={styles.workerList} gutter={8}>
                                      {selectedWorker?.map((worker: any) => (
                                        <Col
                                          span={24}
                                          key={worker.id}
                                          style={{ marginTop: 8 }}
                                          className={classNames({
                                            [styles.disabled]:
                                              currentRoleInCompany === UserRole.VENDOR &&
                                              worker.managerId !== profile.id,
                                          })}
                                        >
                                          <ItemWorkerSelected
                                            worker={worker}
                                            handleRemoveWorker={handleRemoveWorker('afternoon')}
                                            shift="afternoon"
                                            roleId={currentRoleInCompany}
                                            // companyId={companySelected?.id}
                                            isSmallClientTask={isSmallClientTask}
                                            options={license?.data}
                                            form={form}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </AccessControlMultiCompany>
                        </Row>
                      </Panel>
                    </Collapse>
                  )}

                  {isShowPanelEvening && (
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']}
                    >
                      <Panel
                        key="eveningShift"
                        header="夜間"
                        extra={
                          <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <IconDeleteShift
                              onClick={(e) => {
                                e.stopPropagation();

                                handleDeletePanel('evening');
                              }}
                            />
                          </AccessControlMultiCompany>
                        }
                      >
                        <Row gutter={8}>
                          <Col xs={12}>
                            <Form.Item
                              label="開始時刻"
                              name="eveningFrom"
                              rules={[rulesForm().required]}
                              labelAlign="left"
                            >
                              <CommonDatepicker
                                picker={TYPE_PICKER.TIME}
                                allowClear={false}
                                disabled={isOnlyVendor}
                                minuteStep={5}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              label="終了時刻"
                              name="eveningTo"
                              rules={[rulesForm().required]}
                              labelAlign="left"
                            >
                              <CommonDatepicker
                                picker={TYPE_PICKER.TIME}
                                allowClear={false}
                                disabled={isOnlyVendor}
                                minuteStep={5}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label={textJP.label.numberPeople}
                              name={'workerEvening'}
                              rules={[
                                rulesForm().required,
                                () => ({
                                  validator(rule, value) {
                                    if (value && +value < 0) {
                                      return Promise.reject('必要人は0人以上です');
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                              labelAlign="left"
                            >
                              <CommonInputNumber min={0} isOnlyInteger />
                            </Form.Item>
                          </Col>
                          <AccessControlMultiCompany
                            accessibleRoles={[
                              UserRole.ADMIN,
                              UserRole.MANAGER,
                              UserRole.OFFICE_MANAGER,
                              UserRole.LEADER,
                            ]}
                            roleInCompany={currentRoleInCompany}
                          >
                            <Col span={24}>
                              <Form.Item label="サプライヤー" name="vendorsEvening" labelAlign="left">
                                <VendorPicker
                                  options={isSmallClientTask ? listVendor : joinedVendors}
                                  disabled={isOnlyVendor}
                                  handleSelectedVendor={(props) => {
                                    handleSelectedVendor({ ...props });

                                    if (isSmallClientTask) {
                                      handleOnBlurVendor();
                                    }
                                  }}
                                  shift="evening"
                                  form={form}
                                  {...propsOfSelectVendor}
                                />
                              </Form.Item>
                            </Col>
                          </AccessControlMultiCompany>
                          <AccessControlMultiCompany
                            accessibleRoles={[
                              UserRole.ADMIN,
                              UserRole.MANAGER,
                              UserRole.OFFICE_MANAGER,
                              UserRole.LEADER,
                              UserRole.VENDOR,
                            ]}
                            roleInCompany={currentRoleInCompany}
                          >
                            <Col span={24}>
                              <Form.Item
                                label="スタッフ"
                                name="workerIdsEvening"
                                labelAlign="left"
                                className={styles.workerSelectList}
                              >
                                <CommonSelect
                                  optionLabel={'name'}
                                  filterOption={false}
                                  onSearch={(e: any) => handleSearchWorker(e, 'evening')}
                                  onFocus={() => handleSearchWorker('', 'evening')}
                                  options={listWorker || []}
                                  mode={TYPE_INPUT.MULTIPLE}
                                  suffixIcon={<img src={icSearch} style={{ margin: -12 }} alt="" />}
                                  onSelect={(workerId: number) =>
                                    handleFillDefaultCountWorkWorker({ shift: 'evening', workerId })
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                shouldUpdate={(prev, next) => prev.workerIdsEvening !== next.workerIdsEvening}
                                noStyle
                              >
                                {() => {
                                  let allWorkers: any = isSmallClientTask
                                    ? dataWorkers?.data || []
                                    : constructionSiteDetail?.data?.workers || [];
                                  if (currentRoleInCompany === UserRole.VENDOR) {
                                    allWorkers = allWorkers?.filter((x: any) => x.managerId === profile.id);
                                  }
                                  const selectedWorkerIds = form.getFieldValue('workerIdsEvening');
                                  const selectedWorker = allWorkers?.filter((x: any) =>
                                    selectedWorkerIds?.includes(x.id)
                                  );
                                  return (
                                    <Row className={styles.workerList} gutter={8}>
                                      {selectedWorker?.map((worker: any) => (
                                        <Col
                                          span={24}
                                          key={worker.id}
                                          style={{ marginTop: 8 }}
                                          className={classNames({
                                            [styles.disabled]:
                                              currentRoleInCompany === UserRole.VENDOR &&
                                              worker.managerId !== profile.id,
                                          })}
                                        >
                                          <ItemWorkerSelected
                                            worker={worker}
                                            handleRemoveWorker={handleRemoveWorker('evening')}
                                            shift="evening"
                                            roleId={currentRoleInCompany}
                                            // companyId={companySelected?.id}
                                            isSmallClientTask={isSmallClientTask}
                                            options={license?.data}
                                            form={form}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </AccessControlMultiCompany>
                        </Row>
                      </Panel>
                    </Collapse>
                  )}

                  {isShowPanelOther && (
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']}
                    >
                      <Panel
                        key="otherShift"
                        header="オプション"
                        extra={
                          <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <IconDeleteShift
                              onClick={(e) => {
                                e.stopPropagation();

                                handleDeletePanel('other');
                              }}
                              disabled={checked.other}
                            />
                          </AccessControlMultiCompany>
                        }
                      >
                        <Row gutter={8}>
                          {/* <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <Space
                              size="small"
                              align="center"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginBottom: '10px',
                              }}
                            >
                              <LabelRequired label={textJP.label.projectTimeFrame} className="mr-10" />

                              <Space align="center">
                                {(isSmallClientTask ? true : profileCompany?.morningShift === STATUS.ACTIVE) && (
                                  <Form.Item
                                    className="mb-0"
                                    name="otherShiftTypeMorning"
                                    valuePropName="checked"
                                    noStyle
                                  >
                                    <Checkbox className="strong" disabled={checkedOfAdmin.morningShift}>
                                      {textJP.shift.morningShift}
                                    </Checkbox>
                                  </Form.Item>
                                )}

                                {(isSmallClientTask ? true : profileCompany?.afternoonShift === STATUS.ACTIVE) && (
                                  <Form.Item
                                    className="mb-0"
                                    name="otherShiftTypeAfternoon"
                                    valuePropName="checked"
                                    noStyle
                                  >
                                    <Checkbox className="strong" disabled={checkedOfAdmin.afternoonShift}>
                                      {textJP.shift.afternoonShift}
                                    </Checkbox>
                                  </Form.Item>
                                )}

                                {(isSmallClientTask ? true : profileCompany?.eveningShift === STATUS.ACTIVE) && (
                                  <Form.Item
                                    className="mb-0"
                                    name="otherShiftTypeEvening"
                                    valuePropName="checked"
                                    noStyle
                                  >
                                    <Checkbox className="strong" disabled={checkedOfAdmin.eveningShift}>
                                      {textJP.shift.eveningShift}
                                    </Checkbox>
                                  </Form.Item>
                                )}
                              </Space>
                            </Space>
                          </AccessControlMultiCompany> */}

                          <Col xs={12}>
                            <Form.Item
                              label="開始時刻"
                              name="otherFrom"
                              rules={[rulesForm().required]}
                              labelAlign="left"
                            >
                              <CommonDatepicker
                                picker={TYPE_PICKER.TIME}
                                allowClear={false}
                                disabled={isOnlyVendor}
                                minuteStep={5}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              label="終了時刻"
                              name="otherTo"
                              rules={[
                                {
                                  required: true,
                                  message: '全ての項目を入力してください。',
                                },
                              ]}
                              labelAlign="left"
                            >
                              <CommonDatepicker
                                picker={TYPE_PICKER.TIME}
                                allowClear={false}
                                disabled={isOnlyVendor}
                                minuteStep={5}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label={textJP.label.numberPeople}
                              name={'workerOther'}
                              rules={[
                                {
                                  required: true,
                                  message: '全ての項目を入力してください。',
                                },
                                () => ({
                                  validator(rule, value) {
                                    if (value && +value < 0) {
                                      return Promise.reject('必要人は0人以上です');
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                              labelAlign="left"
                            >
                              <CommonInputNumber min={0} isOnlyInteger />
                            </Form.Item>
                          </Col>
                          <AccessControlMultiCompany
                            accessibleRoles={[
                              UserRole.ADMIN,
                              UserRole.MANAGER,
                              UserRole.OFFICE_MANAGER,
                              UserRole.LEADER,
                            ]}
                            roleInCompany={currentRoleInCompany}
                          >
                            <Col span={24}>
                              <Form.Item label="サプライヤー" name="vendorsOther" labelAlign="left">
                                <VendorPicker
                                  options={isSmallClientTask ? listVendor : joinedVendors}
                                  disabled={isOnlyVendor}
                                  handleSelectedVendor={(props) => {
                                    handleSelectedVendor({ ...props });

                                    if (isSmallClientTask) {
                                      handleOnBlurVendor();
                                    }
                                  }}
                                  shift="other"
                                  form={form}
                                  {...propsOfSelectVendor}
                                />
                              </Form.Item>
                            </Col>
                          </AccessControlMultiCompany>
                          <AccessControlMultiCompany
                            accessibleRoles={[
                              UserRole.ADMIN,
                              UserRole.MANAGER,
                              UserRole.OFFICE_MANAGER,
                              UserRole.LEADER,
                              UserRole.VENDOR,
                            ]}
                            roleInCompany={currentRoleInCompany}
                          >
                            <Col span={24}>
                              <Form.Item
                                label="スタッフ"
                                name="workerIdsOther"
                                labelAlign="left"
                                className={styles.workerSelectList}
                              >
                                <CommonSelect
                                  optionLabel={'name'}
                                  filterOption={false}
                                  onSearch={(e: any) => handleSearchWorker(e, 'other')}
                                  onFocus={() => handleSearchWorker('', 'other')}
                                  options={listWorker || []}
                                  mode={TYPE_INPUT.MULTIPLE}
                                  suffixIcon={<img src={icSearch} style={{ margin: -12 }} alt="" />}
                                  onSelect={(workerId: number) =>
                                    handleFillDefaultCountWorkWorker({ shift: 'other', workerId })
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                shouldUpdate={(prev, next) => prev.workerIdsOther !== next.workerIdsOther}
                                noStyle
                              >
                                {() => {
                                  let allWorkers: any = isSmallClientTask
                                    ? dataWorkers?.data || []
                                    : constructionSiteDetail?.data?.workers || [];

                                  if (currentRoleInCompany === UserRole.VENDOR) {
                                    allWorkers = allWorkers?.filter((x: any) => x.managerId === profile.id);
                                  }
                                  const selectedWorkerIds = form.getFieldValue('workerIdsOther');
                                  const selectedWorker = allWorkers?.filter((x: any) =>
                                    selectedWorkerIds?.includes(x.id)
                                  );

                                  return (
                                    <Row className={styles.workerList} gutter={8}>
                                      {selectedWorker?.map((worker: IWorker) => (
                                        <Col
                                          span={24}
                                          key={worker.id}
                                          style={{ marginTop: 8 }}
                                          className={classNames({
                                            [styles.disabled]:
                                              currentRoleInCompany === UserRole.VENDOR &&
                                              worker.managerId !== profile.id,
                                          })}
                                        >
                                          <ItemWorkerSelected
                                            worker={worker}
                                            handleRemoveWorker={handleRemoveWorker('other')}
                                            form={form}
                                            shift="other"
                                            roleId={currentRoleInCompany}
                                            // companyId={companySelected?.id}
                                            isSmallClientTask={isSmallClientTask}
                                            options={license?.data}
                                          />
                                        </Col>
                                      ))}
                                    </Row>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </AccessControlMultiCompany>
                        </Row>
                      </Panel>
                    </Collapse>
                  )}
                </AccessControlMultiCompany>

                {/* {isShowButtonCreateOtherShift && (
                  <div onClick={handleCreateOtherShift} className={styles.btnAddOtherShift}>
                    <CommonSpace justify="center" widthFull align="center">
                      <Button icon={<img src={icons.plus.outlineGray} alt="" />} type="text" shape="circle" />
                      <div className="mt-6">{textJP.shift.otherShift}</div>
                    </CommonSpace>
                  </div>
                )} */}

                <AccessControlMultiCompany
                  accessibleRoles={[UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER]}
                  roleInCompany={currentRoleInCompany}
                >
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const vendorsMorning = getFieldValue('vendorsMorning') || [];
                      const vendorsAfternoon = getFieldValue('vendorsAfternoon') || [];
                      const vendorsEvening = getFieldValue('vendorsEvening') || [];
                      const vendorsOther = getFieldValue('vendorsOther') || [];

                      if (
                        vendorsMorning.length === 0 &&
                        vendorsAfternoon.length === 0 &&
                        vendorsEvening.length === 0 &&
                        vendorsOther.length === 0
                      ) {
                        return null;
                      }

                      return (
                        <Col span={24} className="mt-10">
                          <Form.Item
                            label="サプライヤーのライセンス"
                            name="certificatesVendor"
                            labelAlign="left"
                            shouldUpdate
                          >
                            <VendorPicker
                              options={uniqueSelectedVendor}
                              type="certificateVendor"
                              handlePickSelectedVendor={handlePickSelectedVendor}
                              form={form}
                              onRemove={(vendor) => {
                                const values = form.getFieldsValue(true) || {};
                                form.setFieldsValue({ ...values, [vendor?.id]: undefined });
                              }}
                            />
                          </Form.Item>
                        </Col>
                      );
                    }}
                  </Form.Item>
                </AccessControlMultiCompany>
              </div>
            </Col>
          </Row>
        </Form>

        {/* {taskType === TaskTypes.SMALL && <TabSmallTask />} */}
      </Loader>
    </CommonModal>
  );
};
