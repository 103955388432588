import {
  ICheckConstructionSite,
  IResConstructionSiteDetail,
  IUpdateNoteBody,
  IUpdateSideBar,
  TResCheckConstructionSiteExist,
  TResListProject,
  TRestListCustomerOfCompany,
} from 'common/interface';
import { axiosInstance, sendGet, sendPost, sendPut } from './axios';
import { CommonStatus } from 'common';

export const getListConstructionSite = async (params: any, companyId?: number) => {
  const response = await axiosInstance.post('/construction-site/list-construction-site', params, {
    headers: {
      companyId,
    },
  });

  return response.data;
};

export const getListConstructionSiteMultiCompany = async (params: any) => {
  const response = await axiosInstance.post<TResListProject>(
    '/construction-site/list-construction-site-new-logic',
    params
  );

  return response.data;
};

export const getConstructionSiteDetail = async (id: number, payload = {}, companyId?: number) => {
  const response = await axiosInstance.post(`/construction-site/details/${id}`, payload, {
    headers: {
      companyId,
    },
  });

  return response.data as IResConstructionSiteDetail;
};

export const getListTaskOfConstructionSite = (constructionSiteId: number, params: any) =>
  sendPost(`/construction-site/${constructionSiteId}/list-task`, params);

interface UpdateConstructionSiteStatusParams {
  state?: number;
  status?: number;
}
export const updateConstructionSiteStatus = (constructionSiteId: number, params: UpdateConstructionSiteStatusParams) =>
  sendPut(`/construction-site/${constructionSiteId}/update-status`, params);
export const getConstructionSiteTaskDetail = (taskId: number) => sendPost(`/construction-site/detail-task/${taskId}`);
export const createConstructionSite = (payload: any) => sendPost('construction-site/create', payload);
export const updateConstructionSite = (id: number, payload: any) => sendPut(`construction-site/${id}/update`, payload);

export const vendorApproveConstructionSite = (id: number, payload: any) =>
  sendPut(`/construction-site/${id}/vendor-approve`, payload);

// Paper, Bill and Request Construction site
// export const getListPaperConstructionSite = (params: any) => sendPost('/construction-site/list-paper', params);
export const getListPaymentRequestConstructionSite = (constructionSiteId: number, params: any) =>
  sendPost(`construction-site/${constructionSiteId}/list-payment-request`, params);
export const addPaymentRequestConstructionSite = (params: any) =>
  sendPost('/construction-site/request-payment', params);
export const approvePaymentRequestConstructionSite = (requestId: number, params: any) =>
  sendPost(`/construction-site/approve-payment-request/${requestId}`, params);
// export const getListSendCompany = () => sendPost('/paper/send-company');
// export const getListReceiveCompany = (params: any) => sendPost('/paper/receiver-company', params);
// export const createPaper = (params: any) => sendPut('/paper/create', params);

// Task
export const createTask = (payload: any) => sendPost('/construction-site/create-task', payload);
export const updateTask = (id: number, payload: any) => sendPost(`/construction-site/update-task/${id}`, payload);

//payment, Cost
export const getPaymentCost = (id: number, payload: any) => sendPost(`construction-site/${id}/revenue-cost`, payload);
export const updatePaymentCost = (id: number, payload: any) =>
  sendPost(`/construction-site/update-payment-request/${id}`, payload);

export const getCostRevenueConstructionSite = (id: number) => sendPost(`/v2/construction-site/cost-revenue/${id}`);
export const createRevenueConstructionSite = (payload: any) =>
  sendPost('/v2/construction-site/create-revenue-fixed-price', payload);
export const getListRevenue = (id: number, params: any) => sendGet(`/v2/construction-site/list-revenue/${id}`, params);
export const getListCost = (id: number, params: any) => sendGet(`/v2/construction-site/list-cost/${id}`, params);
export const removeRevenue = (id: number) => sendPost(`/v2/construction-site/deleted-revenue/${id}`);

//Task-v2
// export const createTaskMultipleDay = (payload: any) => sendPost('/v2/task', payload);
export const createTaskMultipleDay = (payload: any, companyId?: number) => {
  return axiosInstance.post('/v2/task', payload, {
    headers: {
      companyId,
    },
  });
};

export const getConstructionSiteTaskDetails = async (id: number, companyId?: number) => {
  const response = await axiosInstance.get(`/v2/task/${id}`, {
    headers: { companyId },
  });

  return response.data;
};

export const updateTaskV2 = async ({ id, payload, companyId }: { id: number; payload: any; companyId?: number }) => {
  const response = await axiosInstance.put(`/v2/task/${id}`, payload, { headers: { companyId } });

  return response?.data;
};

// V2
export const searchCustomer = (payload: ISearchCustomer) =>
  sendPost('/v2/construction-site/search-name-customer-company', payload);
export interface ISearchCustomer {
  pageIndex: number;
  pageSize: number;
  companyName?: string;
  customerName?: string;
  actionStatus?: CommonStatus[];
  keyword?: string;
}

// V3
export const confirmUpdateTask = async ({ id, payload, companyId }: { id: any; payload: any; companyId?: number }) => {
  const response = await axiosInstance.post(`/v3/task/confirm-customer-update-task/${id}`, payload, {
    headers: { companyId },
  });

  return response?.data;
};

export const rejectCustomerUpdateTask = async ({ id, companyId }: { id: any; companyId?: number }) => {
  const response = await axiosInstance.post(
    `/v3/task/reject-customer-update-task/${id}`,
    {}, // payload
    { headers: { companyId } }
  );

  return response?.data;
};
export const updateReserveTask = (id: any, payload: any) => sendPut(`/v3/task/update-reserve-status/${id}`, payload);

export const getMenuSideBar = () => sendGet<{ data: IUpdateSideBar[] }>('v5/side-bar/get');
export const updateMenuSidebar = (payload: IUpdateSideBar[]) => sendPut('v5/side-bar/set', payload);

export const adminUpdateNote = (body: IUpdateNoteBody) => {
  return sendPut('/nest/task/update-note', body);
};

export const getListCustomerOfCompany = async ({ companyId, payload }: { companyId?: number; payload?: any }) => {
  const res = await axiosInstance.post<TRestListCustomerOfCompany>(
    '/v2/construction-site/search-name-customer-company-new',
    payload,
    { headers: { companyId } }
  );

  return res?.data;
};

export const checkConstructionSiteExist = async (payload: ICheckConstructionSite) => {
  const res = await axiosInstance.post<TResCheckConstructionSiteExist>('/nest/construction-site/is-exists', payload);

  return res?.data;
};
