import { Col, Divider, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { textJP } from 'common/const';
import CommonInputNumberV2 from 'components/CommonInputNumberV2';
import { CommonSelect } from 'components/CommonSelect';
import { useRef, useState } from 'react';

interface IProps {
  options?: any[];
  form?: FormInstance;
  isCanEditPriceOfCustomer?: boolean;
}

export const setValueTitleOtherShiftCustomer = (title: string, price: number) => {
  const label = `${title} ${price}`;

  return {
    value: label,
    label: [price, null],
    key: label,
    titleOriginal: title,
  };
};

export const getValueTitleOtherShiftCustomer = (value: any) => {
  return value?.titleOriginal ?? value?.titleOtherShiftCustomer?.label?.[0] ?? '';
};

const PriceShiftCustomer = ({ options = [], form, isCanEditPriceOfCustomer }: IProps) => {
  const [customPrice, setCustomPrice] = useState<any>();
  const inputRef = useRef<any>(null);
  const isSelectingRef = useRef(false);

  const optionsConvert = options.map((op) => ({
    ...op,
    titleConvert: `${op.title} ${op.price}`,
  }));

  const handleSetCustomPrice = () => {
    if (customPrice === undefined || isSelectingRef.current) return;

    // set custom price to form
    form?.setFieldsValue({
      titleOtherShiftCustomer: setValueTitleOtherShiftCustomer(`${textJP.label.manualInput}`, customPrice || 0),
      priceOtherShiftCustomer: customPrice || 0,
    });

    // blur input to close dropdown
    inputRef?.current?.blur();

    // clear input value
    setCustomPrice(undefined);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={16}>
        <Form.Item name="titleOtherShiftCustomer" className="mb-0" label={textJP.label.priceCustomerOtherShift}>
          <CommonSelect
            allowClear
            style={{ width: '100%' }}
            options={optionsConvert}
            optionLabel="titleConvert"
            optionValue="id"
            showSearch={false}
            labelInValue
            dropdownRender={(menu) => (
              <>
                {options.length > 0 && menu}

                <Divider style={{ margin: '8px 0' }} />
                <Row style={{ padding: '0 8px 4px' }} gutter={10} align="middle" wrap={false}>
                  <Col span={5}>
                    <div>{textJP.label.manualInput}</div>
                  </Col>

                  <Col flex={1}>
                    <CommonInputNumberV2
                      ref={inputRef}
                      value={customPrice}
                      onChange={(value) => setCustomPrice(value as number)}
                      onBlur={() => {
                        if (customPrice !== undefined && customPrice !== '' && !isSelectingRef.current) {
                          handleSetCustomPrice();
                        }
                      }}
                      onKeyDown={(e) => {
                        e.stopPropagation(); // prevent select option when press Enter

                        const key = e.key;

                        if (key !== 'Enter') return;

                        if (customPrice !== undefined && customPrice !== '') {
                          handleSetCustomPrice();
                        }
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
            onSelect={(value: any) => {
              isSelectingRef.current = true;
              const optionSelect = options.find((op) => op.id === value?.value);

              const titleSelect = optionSelect?.title ?? '';
              const priceSelect = optionSelect?.price ?? 0;

              // Clear custom price first
              setCustomPrice(undefined);

              // Then set form values
              form?.setFieldsValue({
                priceOtherShiftCustomer: priceSelect,
                titleOtherShiftCustomer: { ...value, label: [priceSelect, null], titleOriginal: titleSelect },
              });

              // Reset selecting flag after a delay
              setTimeout(() => {
                isSelectingRef.current = false;
              }, 100);

              // Force blur the input to ensure dropdown closes
              inputRef?.current?.blur();
            }}
            placeholder={textJP.label.pleaseSelect}
            disabled={!isCanEditPriceOfCustomer}
            onClear={() => {
              form?.setFieldsValue({
                priceOtherShiftCustomer: null,
                titleOtherShiftCustomer: '',
              });
            }}
          />
        </Form.Item>
      </Col>

      <Col span={8} style={{ opacity: 0, width: 0, pointerEvents: 'none' }}>
        <Form.Item name="priceOtherShiftCustomer" className="mb-0" label=" ">
          <CommonInputNumberV2 placeholder={textJP.label.price} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PriceShiftCustomer;
