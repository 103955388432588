import { UserRole } from 'common';
import { Typography } from 'antd';
import { textJP } from 'common/const';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  details: any;
  roleInCompany: UserRole;
}

const { Text } = Typography;

const DetailsProject = ({ details, roleInCompany, ...rest }: IProps) => {
  return (
    <div {...rest}>
      {details?.data?.customerCompanyName && (
        <div className="mb-5">
          <Text>企業名: {details?.data?.customerCompanyName}</Text>
        </div>
      )}
      {details?.data?.customerName && ![UserRole.VENDOR, UserRole.CUSTOMER].includes(roleInCompany) && (
        <div className="mb-5">
          <Text>クライアント名: {details?.data?.customerName}</Text>
        </div>
      )}
      {details?.data?.address && (
        <div className="mb-5">
          <Text>住所: {details?.data?.address}</Text>
        </div>
      )}
      {details?.data?.leaderName && (
        <div className="mb-5">
          <Text>
            {textJP.label.projectLeader}: {details?.data?.leaderName}
          </Text>
        </div>
      )}
    </div>
  );
};

export default DetailsProject;
