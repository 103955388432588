import { IBodyUpdateBlockPayroll, IRestGetDetailTimeSheet, IRestGetTimeSheets } from 'common/interface';
import { sendGet, sendPost, sendPut } from './axios';

export const getSalary = (payload: any) => sendGet('/v2/timekeeping/payroll2', payload);
export const getTimeSheets = (payLoad: Partial<ITimeSheets>) =>
  sendGet<IRestGetTimeSheets>('/v2/timekeeping/timesheets', payLoad);
export const getDetailTimeSheet = (payload: Partial<ITimeSheets>) =>
  sendGet<IRestGetDetailTimeSheet>('/v2/timekeeping/list', payload);
export const updateSalary = (payload: any) => sendPut('/timesheets/update-salary', payload);

export const getMoneyPaymentRequest = (payload: Partial<ITimeSheets>, id: number) =>
  sendPost(`payment-request/money-payment-request/${id}`, payload);

export const updateMonthSalaryCustom = (payload: any) => sendPut('/timesheets/update-month-custom-salary', payload);
export const getSalaryDetail = (id: number, payload: any) => sendPost(`/timesheets/salary-detail/${id}`, payload);
export const getFastApprovedTimeKeepingList = (payload: any) => sendGet('/v2/timekeeping/quick-accept', payload);
export const getCurrentPayroll = () => sendGet('/v2/timekeeping/current-payroll');
export const getDefaultPaymentDate = () => sendGet('nest/company/default-payment-date-company');
export const getPayrollByMonth = (params: { month?: string }) => sendGet('/v2/timekeeping/payroll-by-month', params);
export const getSnapShot = (month: any) => sendGet(`/v2/timekeeping/snapshot/${month}`);
export const snapShot = (params: any) => sendPost(`/v2/timekeeping/snapshot`, params);
export const clearSnapShot = (params: any) => sendPost('/v2/timekeeping/clear-snapshot', params);
interface ITimeSheets {
  startDate: any;
  endDate: any;
  keyword: string;
  userId: number;
  workingDay: string;
  pageIndex?: number;
}

// V4
interface ISalaryV4Params {
  month: string | null;
  userId: number | undefined;
}

interface IGetHistoryUpdateV4 {
  userId: number;
  month: string;
}

interface IExportCSVV4 {
  month: string[];
}

export const getSalaryDetailV4 = (params: ISalaryV4Params) => sendGet('/v4/timesheet/detail', params);

export const getSalaryV4 = (params: any) => sendGet('/v4/timesheet/all', params);
export const getSalaryV4MultipleMonth = (params: any) => sendGet('/v4/timesheet/all-multiple-month', params);
export const updateSalaryV4 = (params: any) => sendPost('/v4/timesheet/update-payroll', params);
export const renewPayroll = (params: any) => sendPost('/v4/timesheet/clear-payroll', params);
export const clearSnapShotV4 = (params: any) => sendPost('/v4/timesheet/clear-snapshot', params);
export const createSnapShotV4 = (params: any) => sendPost('/v4/timesheet/snapshot', params);
export const getSnapShotV4 = (params: any) => sendGet('/v4/timesheet/snapshot', params);
export const getHistoryUpdateV4 = (params: IGetHistoryUpdateV4) => sendGet('/v4/timesheet/history', params);

export const getExportCSVV4 = (params: IExportCSVV4) => sendGet('/v4/timesheet/csv', params);

export const getDetailSalaryV4 = (params: IGetHistoryUpdateV4) => sendGet('/v4/timesheet/manage', params);

export const updateBlockPayroll = (body: IBodyUpdateBlockPayroll) =>
  sendPost('v5/timesheet/update-block-payroll', body);
