import { Col, Form, Input, Row } from 'antd';
import style from '../../styles.module.scss';
import classNames from 'classnames';
import { CommonInputNumber } from 'components/CommonInputNumber';
import addField from 'assets/icons/add_circle_outline.svg';
import iconDelete from 'assets/icons/trash-2.svg';
import CommonSpace from 'components/CommonSpace';
import { useRulesForm } from 'hooks/useRulesForm';
import { textJP } from 'common/const';

interface IProps {
  disabledAll?: boolean;
  showIcons?: boolean;
  showCertificateWorker?: boolean;
}
const FormCertificate = ({ disabledAll, showIcons = true, showCertificateWorker = false }: IProps) => {
  const { rulesForm } = useRulesForm();

  // const spanTitle = showCertificateWorker ? 8 : 12;
  // const spanPriceCustomer = showCertificateWorker ? 8 : showIcons ? 11 : 12;
  // const spanPriceStaff = showIcons ? 7 : 8;

  return (
    <Col span={12} className={style.wrapContent}>
      <Row gutter={24} className={style.wrapper}>
        <Form.List name="dataUnitPriceCertificate">
          {(fields, { add, remove }) => (
            <>
              <CommonSpace align="center">
                <div className={style.titleSection}>{textJP.label.license}</div>

                {showIcons && (
                  <Form.Item
                    className={classNames(style.addCertificate, {
                      [style.disabled]: disabledAll,
                    })}
                  >
                    <img src={addField} alt="" onClick={() => !disabledAll && add()} />
                  </Form.Item>
                )}
              </CommonSpace>

              {fields.map(({ key, name, ...restField }) => {
                return (
                  <div className={classNames(style.wrapperUnitPrice, 'mb-12')} key={key}>
                    <Row gutter={8} className={style.unitPrice}>
                      <Col span={11}>
                        <Form.Item noStyle shouldUpdate>
                          <Form.Item
                            {...restField}
                            name={[name, 'title']}
                            label={<span className={classNames('color-text', 'text-13')}>タイトル</span>}
                            rules={[rulesForm().required]}
                          >
                            <Input placeholder="タイトルを入力" className="inputGreyNoBorder" disabled={disabledAll} />
                          </Form.Item>
                        </Form.Item>
                      </Col>

                      <Col span={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'price']}
                          label={<span className={classNames('color-text', 'text-13')}>単価</span>}
                          rules={[rulesForm().required]}
                          validateFirst
                        >
                          <CommonInputNumber placeholder="単価" className={style.inputNumber} disabled={disabledAll} />
                        </Form.Item>
                      </Col>

                      {/* 
                      {showCertificateWorker && (
                        <Col span={spanPriceStaff}>
                          <Form.Item
                            {...restField}
                            name={[name, 'priceStaff']}
                            label={
                              <span className={classNames('color-text', 'text-13')}>{textJP.label.priceStaff}</span>
                            }
                            rules={[rulesForm().required]}
                            validateFirst
                          >
                            <CommonInputNumber
                              placeholder={textJP.label.priceStaff}
                              className={style.inputNumber}
                              disabled={disabledAll}
                            />
                          </Form.Item>
                        </Col>
                      )} */}

                      {showIcons && (
                        <Col span={1} className={style.iconWrap}>
                          <img
                            src={iconDelete}
                            alt=""
                            onClick={() => {
                              if (disabledAll) return;

                              remove(name);
                            }}
                            className={classNames(style.icon, {
                              [style.disabled]: disabledAll,
                            })}
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              })}
            </>
          )}
        </Form.List>
      </Row>
    </Col>
  );
};

export default FormCertificate;
