import { IMetaDataOrigin, IMetaDataUpdate } from './index';
import { isEmpty, isNil } from 'lodash';

import styles from './styles.module.scss';
import { Space, Typography } from 'antd';
import { checkTaskSeparatedShift, convertStringWorkerToNumber, convertUniqueUrlFiles } from 'helper';

import RowDate from './RowDate';
import RowMaxWorkerAndCertificate from './RowMaxWorkerAndCertificate';
import ShiftTask from './ShiftTask';
import { IFileDownLoad } from 'common/interface';

interface IProps {
  dataUpdate?: IMetaDataUpdate;
  dataOrigin?: IMetaDataOrigin;
}

const valueNotChange = '変更なし';
const valueNotExists = 'なし';
export const valueDeleteAllFiles = '添付ファイル削除';
export const arrowDown = '↓';
export const arrowRight = '→';

const BodyHistoryUpdate = ({ dataUpdate = {}, dataOrigin = {} }: IProps) => {
  const isFirstTimeCreateTask = isEmpty(dataOrigin);

  const dataOriginConvert = convertStringWorkerToNumber({ ...dataOrigin }) as IMetaDataOrigin;
  const dataUpdateConvert = convertStringWorkerToNumber({ ...dataUpdate }) as IMetaDataUpdate;

  // Remove duplicate files in origin to fix bug from backend
  dataOriginConvert.files = convertUniqueUrlFiles(dataOriginConvert.files as IFileDownLoad[]);

  const valueDefault = isFirstTimeCreateTask ? valueNotExists : valueNotChange;

  const metadataCustomerUnitPriceCertificate = dataUpdateConvert?.metadataCustomerUnitPriceCertificate
    ? (JSON.parse(
        dataUpdateConvert?.metadataCustomerUnitPriceCertificate
      ) as IMetaDataOrigin['metadataCustomerUnitPriceCertificate'])
    : null;

  // check is update max-worker and certificate morning + afternoon
  const isUpdateCertificate = !isNil(metadataCustomerUnitPriceCertificate?.morningShift);
  const isUpdateMaxWorker =
    !isNil(dataUpdateConvert?.maxWorker) && dataUpdateConvert?.maxWorker !== dataOriginConvert?.maxWorker;

  // check is update max-worker and certificate morning
  const isUpdateCertificateMorning = !isNil(metadataCustomerUnitPriceCertificate?.morningShift);
  const isUpdateMaxWorkerMorning =
    !isNil(dataUpdateConvert?.maxWorkerMorning) &&
    dataUpdateConvert?.maxWorkerMorning !== dataOriginConvert?.maxWorkerMorning;

  // check is update max-worker and certificate afternoon
  const isUpdateCertificateAfternoon = !isNil(metadataCustomerUnitPriceCertificate?.afternoonShift);
  const isUpdateMaxWorkerAfternoon =
    !isNil(dataUpdateConvert?.maxWorkerAfternoon) &&
    dataUpdateConvert?.maxWorkerAfternoon !== dataOriginConvert?.maxWorkerAfternoon;

  // check is update max-worker and certificate evening
  const isUpdateCertificateEvening = !isNil(metadataCustomerUnitPriceCertificate?.eveningShift);
  const isUpdateMaxWorkerEvening =
    !isNil(dataUpdateConvert?.maxWorkerEvening) &&
    dataUpdateConvert?.maxWorkerEvening !== dataOriginConvert?.maxWorkerEvening;

  // check is update max-worker and certificate other
  const isUpdateCertificateOther = !isNil(metadataCustomerUnitPriceCertificate?.otherShift);
  const isUpdateMaxWorkerOther =
    !isNil(dataUpdateConvert?.maxWorkerOther) &&
    dataUpdateConvert?.maxWorkerOther !== dataOriginConvert?.maxWorkerOther;

  const isShowMaxWorkerMorningAndAfternoon = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorker)) {
      return false;
    }

    return isUpdateCertificate || isUpdateMaxWorker;
  };

  const isShowMaxWorkerMorning = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorkerMorning)) {
      return false;
    }

    return isUpdateCertificateMorning || isUpdateMaxWorkerMorning;
  };

  const isShowMaxWorkerAfternoon = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorkerAfternoon)) {
      return false;
    }

    return isUpdateCertificateAfternoon || isUpdateMaxWorkerAfternoon;
  };

  const isShowMaxWorkerEvening = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorkerEvening)) {
      return false;
    }

    return isUpdateCertificateEvening || isUpdateMaxWorkerEvening;
  };

  const isShowMaxWorkerOther = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorkerOther)) {
      return false;
    }

    return isUpdateCertificateOther || isUpdateMaxWorkerOther;
  };

  const isShowDataMaxWorker = [
    isShowMaxWorkerMorningAndAfternoon(),
    isShowMaxWorkerMorning(),
    isShowMaxWorkerAfternoon(),
    isShowMaxWorkerEvening(),
    isShowMaxWorkerOther(),
  ].some(Boolean);

  const isNewData = checkTaskSeparatedShift(dataUpdateConvert as any);

  // Nếu có data update thì ưu tiên lấy data update
  // Nếu không có data update thì lấy data origin
  const shiftsAfterUpdate = {
    morningShift: !isNil(dataUpdateConvert.morningShiftCustomer)
      ? !!dataUpdateConvert.morningShiftCustomer
      : !!dataOriginConvert.morningShiftCustomer,
    afternoonShift: !isNil(dataUpdateConvert.afternoonShiftCustomer)
      ? !!dataUpdateConvert.afternoonShiftCustomer
      : !!dataOriginConvert.afternoonShiftCustomer,
    eveningShift: !isNil(dataUpdateConvert.eveningShiftCustomer)
      ? !!dataUpdateConvert.eveningShiftCustomer
      : !!dataOriginConvert.eveningShiftCustomer,
    otherShift: !isNil(dataUpdateConvert.otherShiftCustomer)
      ? !!dataUpdateConvert.otherShiftCustomer
      : !!dataOriginConvert.otherShiftCustomer,
  };

  const isDifferentFile = () => {
    // check length of files
    const isDifferentLength = dataUpdateConvert?.files?.length !== dataOriginConvert?.files?.length;
    if (isDifferentLength) return true;

    // check different url files
    const differentFiles = dataUpdateConvert?.files?.filter(
      (fileUpdate) => !dataOriginConvert?.files?.some((fileOrigin) => fileUpdate.url === fileOrigin.url)
    );
    if (differentFiles?.length) return true;

    return false;
  };

  const isShowNoteConstructionSite = isFirstTimeCreateTask
    ? !isNil(dataUpdateConvert?.noteConstructionSite) && dataUpdateConvert?.noteConstructionSite !== ''
    : !isNil(dataUpdateConvert?.noteConstructionSite);

  const isShowShiftTask = [
    dataUpdateConvert?.afternoonShiftCustomer,
    dataUpdateConvert?.morningShiftCustomer,
    dataUpdateConvert?.eveningShiftCustomer,
    dataUpdateConvert?.otherShiftCustomer,
  ].some((item) => !isNil(item));

  return (
    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* Note công việc */}
      {!!dataUpdateConvert?.description?.trim() && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.colTitle}>仕事の内容</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                {dataOriginConvert?.description || valueNotExists}

                <div>{arrowDown}</div>
              </>
            )}
            {!isNil(dataUpdateConvert?.description) ? dataUpdateConvert?.description : valueDefault}
          </div>
        </div>
      )}

      {/* Files thay đổi */}
      {!isNil(dataUpdateConvert?.files) && isDifferentFile() && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.colTitle}>アップロードされたファイル</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                <Space direction="vertical" size={1}>
                  {!!dataOriginConvert?.files?.length
                    ? dataOriginConvert?.files?.map((file, index) => (
                        <Typography.Link target="_blank" href={file.url} key={index}>
                          {file?.name}
                        </Typography.Link>
                      )) || valueNotExists
                    : valueNotExists}
                </Space>

                <div>{arrowDown}</div>
              </>
            )}

            {!isNil(dataUpdateConvert?.files) ? (
              <Space direction="vertical" size={1}>
                {dataUpdateConvert?.files?.length > 0
                  ? dataUpdateConvert?.files?.map((file, index) => (
                      <Typography.Link target="_blank" href={file.url} key={index}>
                        {file?.name}
                      </Typography.Link>
                    ))
                  : valueDeleteAllFiles}
              </Space>
            ) : (
              valueNotChange
            )}
          </div>
        </div>
      )}

      {/* Ca làm việc của task */}
      {isShowShiftTask && (
        <ShiftTask
          isFirstTimeCreateTask={isFirstTimeCreateTask}
          dataOriginConvert={dataOriginConvert}
          shiftsAfterUpdate={shiftsAfterUpdate}
        />
      )}

      {/* Tên công việc */}
      {!isNil(dataUpdateConvert?.name) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.colTitle}>手配名</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                {dataOriginConvert?.name || valueNotExists} {arrowRight}{' '}
              </>
            )}
            {dataUpdateConvert?.name || valueDefault}
          </div>
        </div>
      )}

      {/* Số người cần thiết cho công việc  + Chứng chỉ khách hàng*/}
      {isShowDataMaxWorker && (
        <RowMaxWorkerAndCertificate
          dataOriginConvert={dataOriginConvert}
          dataUpdateConvert={dataUpdateConvert}
          isFirstTimeCreateTask={isFirstTimeCreateTask}
          isNewData={isNewData}
          shiftsAfterUpdate={shiftsAfterUpdate}
          metadataCustomerUnitPriceCertificate={
            metadataCustomerUnitPriceCertificate as IMetaDataOrigin['metadataCustomerUnitPriceCertificate']
          }
        />
      )}

      {/* note công trường */}
      {isShowNoteConstructionSite && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.colTitle}>プロジェクト概要・メモ</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {dataOriginConvert?.noteConstructionSite || valueNotExists}
                </div>

                <div>{arrowDown}</div>
              </>
            )}

            {!isNil(dataUpdateConvert?.noteConstructionSite) ? (
              <div style={{ whiteSpace: 'pre-line' }}>{dataUpdateConvert?.noteConstructionSite}</div>
            ) : (
              valueDefault
            )}
          </div>
        </div>
      )}

      {/* Ngày bắt đầu/ ngày kết thúc seri task */}
      {!isNil(dataUpdateConvert?.date) && (
        <RowDate
          dataOriginConvert={dataOriginConvert}
          dataUpdateConvert={dataUpdateConvert}
          isFirstTimeCreateTask={isFirstTimeCreateTask}
        />
      )}

      {/* Thời gian mong muốn của customer */}
      {(dataUpdateConvert?.startTimeDesire || dataUpdateConvert?.endTimeDesire) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.colTitle}>希望時間</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                <span>
                  {dataOriginConvert?.startTimeDesire || valueNotExists} :{' '}
                  {dataOriginConvert?.endTimeDesire || valueNotExists}
                </span>

                <> {arrowRight} </>
              </>
            )}

            <span>
              {dataUpdateConvert?.startTimeDesire || (isFirstTimeCreateTask ? valueNotExists : valueDefault)} :{' '}
              {dataUpdateConvert?.endTimeDesire || (isFirstTimeCreateTask ? valueNotExists : valueDefault)}
            </span>
          </div>
        </div>
      )}

      {!isNil(dataUpdateConvert?.constructionSiteNameOldUpdateProject) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.colTitle}>プロジェクト</div>
          <div className={styles.colFull}>{dataUpdateConvert?.constructionSiteNameOldUpdateProject}</div>
        </div>
      )}
    </div>
  );
};

export default BodyHistoryUpdate;
