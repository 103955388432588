import { InputNumber, type InputNumberProps } from 'antd';
import { formatValue, isValidNumber } from './utils';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { forwardRef } from 'react';

interface IPropsInputNumber extends InputNumberProps {
  decimalPlaces?: number; // Số chữ số sau dấu phẩys
}

const CommonInputNumberV2 = forwardRef(
  ({ onChange, decimalPlaces, className, ...rest }: IPropsInputNumber, ref: any) => {
    const handleChange = (value: string | number | null | undefined) => {
      if (value !== null && value !== undefined && !isValidNumber(value, decimalPlaces)) {
        return;
      }
      onChange?.(value);
    };

    return (
      <InputNumber
        ref={ref}
        className={classNames(styles.input, className)}
        onChange={handleChange}
        formatter={(value) => formatValue(value?.toString(), decimalPlaces)}
        parser={(value) => formatValue(value, decimalPlaces)}
        {...rest}
      />
    );
  }
);

export default CommonInputNumberV2;
