import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { UserRole } from 'common';
import { IResConstructionSiteDetail, IStaff } from 'common/interface';
import { CommonSelect } from 'components/CommonSelect';
import { ISelectProps } from 'components/CommonSelect';

interface IProps extends ISelectProps<any> {
  constructionSiteDetail?: IResConstructionSiteDetail;
  form: FormInstance;
  setLeader: (value: any) => void;
  isSmallClientTask?: boolean;
  listStaffOfCompany?: IStaff[];
  classFormItem?: string;
}

const SelectLeaderTask = (props: IProps) => {
  const { constructionSiteDetail, form, setLeader, listStaffOfCompany, isSmallClientTask, classFormItem, ...rest } =
    props;

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const workerIdsMorning = getFieldValue('workerIdsMorning');
        const workerIdsAfternoon = getFieldValue('workerIdsAfternoon');
        const workerIdsEvening = getFieldValue('workerIdsEvening');
        const workerIdsOther = getFieldValue('workerIdsOther');
        const leaderId = getFieldValue('leaderId');

        const leaderConstructionSiteIds = isSmallClientTask
          ? constructionSiteDetail?.data?.workers
              ?.filter((el: any) => el.roleId === UserRole.LEADER)
              ?.map((item: any) => item?.id)
          : listStaffOfCompany
              ?.filter((staff) => staff.userCompany.roleId === UserRole.LEADER)
              .map((staff) => staff.id);

        const workerIds = [
          ...(workerIdsMorning || []),
          ...(workerIdsAfternoon || []),
          ...(workerIdsEvening || []),
          ...(workerIdsOther || []),
          ...(leaderConstructionSiteIds || []),
        ];

        if (
          (isSmallClientTask ? !!listStaffOfCompany?.length : constructionSiteDetail) &&
          leaderId &&
          (workerIds?.length === 0 || !workerIds.includes(leaderId))
        ) {
          form.setFieldsValue({
            leaderId: undefined,
          });
          setLeader(undefined);
        }

        let allWorkers: any = isSmallClientTask ? listStaffOfCompany : constructionSiteDetail?.data?.workers;
        allWorkers = allWorkers?.filter((worker: any, index: number) => workerIds.includes(worker?.id));

        return (
          <Form.Item label="プロジェクトリーダー" name="leaderId" className={classFormItem}>
            <CommonSelect filterOption={true} allowClear={true} options={allWorkers || []} {...rest} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default SelectLeaderTask;
