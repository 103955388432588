import icSendEmail from 'assets/icons/send-email.svg';
import classNames from 'classnames';
import { CommonModal } from 'components/CommonModal';
import { Col, Form, Row } from 'antd';
import { CommonInput } from 'components/CommonInput';
import styles from './styles.module.scss';
import useToggleModal from 'hooks/useToggleModal';
import { QueryClientProvider, useInfiniteQuery, useMutation } from 'react-query';
import { sendEmailMulti } from 'api/task';
import {
  checkIsUserInTask,
  checkStaffInactive,
  handleErrorMessage,
  handleMessageSuccess,
  handleOnPopupScroll,
} from 'helper';
import { useEffect, useRef, useState } from 'react';
import {
  CustomReceiverOption,
  ICompanyActive,
  IPayloadSendEmails,
  IPdfFile,
  IReceiver,
  ITaskDetail,
} from 'common/interface';
import { useRulesForm } from 'hooks/useRulesForm';
import useProfile from 'hooks/useProfile';
import { getNamePdfFile } from './utils';
import Loader from 'components/Loader';
import sanitize from 'sanitize-filename';
import { CommonStatus, TYPE_INPUT, UserRole } from 'common';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import { getImageFormUrl } from 'api/common';

import { renderToStaticMarkup } from 'react-dom/server';
import { history, queryClient } from 'App';
import { ContentTaskDetails } from 'pages/Tasks/TaskDetailModal/ContentTaskDetails';
import { Router } from 'react-router-dom';
import queryKeys from 'common/queryKeys';
import { ALL_ROLES, ROLES_CAN_VIEW_CERTIFICATE_VENDORS, textJP } from 'common/const';
import { getStaff } from 'api/staff';
import { CommonSelect } from 'components/CommonSelect';
// import { debounce } from 'lodash';
import { uploadMultiple } from 'api/upload';
import HeaderPdf from '../HeaderPdf';
import DropRenderEmail from '../DropRenderEmail';
import { isValidEmail } from 'helper/validator';
import PreviewPdf from '../PreviewPdf';
import { useDebounce } from 'use-debounce';

interface IProps {
  detailsTaskRef: React.RefObject<HTMLDivElement>;
  taskDetail?: ITaskDetail;
  companySelected?: ICompanyActive;
}

type TValue = {
  replyToEmail: string;
  to: string;
  subject: string;
  html: string;
  receiverIds: number[];
};

const ButtonSendEmail = ({ detailsTaskRef, taskDetail, companySelected }: IProps) => {
  const profile = useProfile();

  const [form] = Form.useForm();
  const { rulesForm } = useRulesForm();

  const { isOpen, toggleModal } = useToggleModal();
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const isCreateDefaultPdf = useRef(false); // check if create default pdf for customer in task detail
  const isFirstLoadingStaff = useRef(true);

  // Preview PDF
  const [previewPdf, setPreviewPdf] = useState({
    open: false,
    nameFilePreview: '',
    bodyHtml: '',
  });

  const [customReceiverOptions, setCustomReceiverOptions] = useState<CustomReceiverOption[]>([]);
  const [pdfFiles, setPdfFiles] = useState<IPdfFile[]>([]);
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword] = useDebounce(keyword, 300); // Debounce 300ms

  const {
    data: dataListStaff,
    isLoading: isLoadingStaff,
    isFetching: isFetchingStaff,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.staff.listStaff, taskDetail?.companyId, debouncedKeyword],
    queryFn: ({ pageParam = 1 }) =>
      getStaff({
        companyId: taskDetail?.companyId,
        filters: {
          roleIds: ALL_ROLES,
          status: [CommonStatus.ACTIVE, CommonStatus.INACTIVE],
          pageIndex: pageParam,
          pageSize: 10000, // fake large number to get all data
          isAutoCreateTask: CommonStatus.INACTIVE,
          keyword: debouncedKeyword,
        },
      }),
    getNextPageParam: (lastPage, pages) => {
      const { pageIndex, totalPages } = lastPage;

      if (pageIndex >= totalPages) {
        return false;
      }

      return pageIndex + 1;
    },
    onSuccess() {
      if (isFirstLoadingStaff.current) {
        isFirstLoadingStaff.current = false;
      }
    },
    enabled: isOpen,
  });

  const listStaff = dataListStaff?.pages?.map((page) => page?.data)?.reduce((arr, data) => [...arr, ...data], []) || [];
  const uniqueListStaff = Array.from(new Map(listStaff.map((s) => [s.id, s])).values()) ?? [];
  const receiverOptions = [
    ...uniqueListStaff,
    ...customReceiverOptions.filter((staff) => {
      const prefixEmail = (staff?.email as string)?.split('@')?.[0];

      return keyword ? prefixEmail.includes(keyword) : true;
    }),
  ]?.map((staff) => ({ ...staff, disabled: checkStaffInactive(staff?.userCompany?.status) }));

  const isShowCreateCustomEmail =
    isValidEmail(keyword) &&
    !isLoadingStaff &&
    uniqueListStaff.length === 0 &&
    !customReceiverOptions.find((receiver) => receiver.email?.includes(keyword));

  const snapShortCertificateVendors = ({ roleStaff, staffId }: { roleStaff: UserRole; staffId: number }) => {
    const isVendor = roleStaff === UserRole.VENDOR;

    const certificatesVendors = detailsTaskRef.current?.querySelector('#certificates-vendors');

    if (isVendor) {
      const certificateVendor = certificatesVendors?.querySelectorAll('[id^="items_vendor_"]') || [];

      // vendor can only see its own certificate
      certificateVendor.forEach((el) => {
        if (el.id !== `items_vendor_${staffId}`) {
          el.remove();
        }
      });
    }

    return certificatesVendors?.cloneNode(true);
  };

  const generateEmailBodyFromHtml = async ({
    htmlString,
    roleStaff,
    staffId,
  }: {
    htmlString: string;
    roleStaff: UserRole;
    staffId: number;
  }) => {
    if (!htmlString || !detailsTaskRef.current) return;

    try {
      // Create a div containing HTML content from the input string
      const wrapperDiv = document.createElement('div');
      wrapperDiv.innerHTML = htmlString;

      // Create placeholder for header element
      const headerPlaceholder = document.createElement('div');
      headerPlaceholder.id = 'header-placeholder';

      // Hide unnecessary elements with class "hide-with-pdf"
      wrapperDiv.querySelectorAll('.hide-with-pdf').forEach((el) => el.remove());

      // Remove unnecessary classes
      wrapperDiv.querySelectorAll('.fixed-header-form').forEach((el) => el.classList.remove('fixed-header-form'));

      // fix bug css when printing pdf
      wrapperDiv.querySelectorAll('.ant-avatar-string').forEach((el: any) => {
        el.style.opacity = 1;
        el.style.position = 'static';
      });

      // Add section containing certificates-vendors
      const isCanViewCertificateVendors = ROLES_CAN_VIEW_CERTIFICATE_VENDORS.includes(roleStaff);
      if (isCanViewCertificateVendors) {
        const certificatesVendorsHtml = snapShortCertificateVendors({
          roleStaff: roleStaff,
          staffId,
        });
        const placeHolderCertificatesVendors = wrapperDiv.querySelector('#place-holder-certificates-vendors');
        if (placeHolderCertificatesVendors && certificatesVendorsHtml) {
          placeHolderCertificatesVendors.replaceWith(certificatesVendorsHtml);
        }
      }

      // Process images to avoid CORS errors
      wrapperDiv.querySelectorAll('img')?.forEach((img: any) => {
        if (img?.src?.startsWith('https://')) {
          img.src = img.src.replace(img.src, getImageFormUrl(img.src));
        }
      });

      // Wrap everything into a new div
      const finalWrapper = document.createElement('div');
      finalWrapper.appendChild(headerPlaceholder);
      finalWrapper.appendChild(wrapperDiv);

      return finalWrapper.innerHTML;
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const generatePdfFromHtml = async ({ bodyHtml, nameFile }: { bodyHtml: string; nameFile: string }) => {
    if (!bodyHtml) return;

    setIsCreatingPdf(true);

    try {
      const bodyElement = document.createElement('div');
      bodyElement.innerHTML = bodyHtml;

      // Add Header
      const elementHeader = document.createElement('div');
      elementHeader.innerHTML = renderToStaticMarkup(
        <HeaderPdf nameFile={nameFile} taskCompanyName={taskDetail?.taskCompanyName} profileName={profile?.name} />
      );

      // Find header placeholder and replace with header
      const headerPlaceholderElement = bodyElement.querySelector('#header-placeholder');

      if (headerPlaceholderElement) {
        headerPlaceholderElement.replaceWith(elementHeader);
      }

      // PDF options
      const opt = {
        margin: 0.5,
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' },
      };

      // Generate PDF blob
      const blob = await html2pdf().set(opt).from(bodyElement).outputPdf('blob', nameFile);

      // Convert blob to file
      const file = new File([blob], nameFile, { type: 'application/pdf' });

      return file;
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setIsCreatingPdf(false);
    }
  };

  const sendEmailMutation = useMutation({
    mutationFn: sendEmailMulti,
  });

  const handleSendEmail = async (values: TValue) => {
    setIsSendingEmail(true);

    try {
      // loop through pdfFiles to create pdf file
      const pdfFilesPromises = pdfFiles.map((pdfFiles) =>
        generatePdfFromHtml({
          bodyHtml: pdfFiles.bodyHtml,
          nameFile: values.subject,
        })
      );
      const listPdfCreate = await Promise.all(pdfFilesPromises);

      // upload all pdf files to s3
      const response = await uploadMultiple(listPdfCreate);

      // url of pdf files
      const urls = response.data.map((suffixUrl) => `${response.domain}/${suffixUrl}`);

      const receivers: IReceiver[] = pdfFiles.map((pdfFile, index) => ({
        email: pdfFile.email as string,
        fileUrl: urls[index],
      }));

      const payloadSendEmails: IPayloadSendEmails = {
        taskId: taskDetail?.id as number,
        replyToEmail: values.replyToEmail,
        subject: values.subject ?? '',
        html: values.html ?? '',
        receivers: receivers,
      };

      await sendEmailMutation.mutateAsync({ data: payloadSendEmails, companyId: companySelected?.id });

      // After sending the email, close the modal so need to reset the form, state
      handleMessageSuccess();
      handleCancelModal();
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setIsSendingEmail(false);
    }
  };

  const onClickBtnSendEmail = async () => {
    toggleModal(true);
    const namePdfFile = sanitize(getNamePdfFile(taskDetail!));

    // Set default form values
    form.setFieldsValue({
      replyToEmail: profile?.email,
      to: taskDetail?.customerEmail,
      subject: namePdfFile,
      receiverIds: [taskDetail?.customerId],
    });
  };

  const handleCancelModal = () => {
    toggleModal(false);
    form.resetFields();
    setPdfFiles([]);
    setCustomReceiverOptions([]);
    isCreateDefaultPdf.current = false;
    isFirstLoadingStaff.current = true;
  };

  const generateHtmlString = (profileUserViewPdf: any, taskId: number) => {
    return renderToStaticMarkup(
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <ContentTaskDetails taskId={taskDetail?.id as number} profileUserViewPdf={profileUserViewPdf} />
        </Router>
      </QueryClientProvider>
    );
  };

  const handleSaveCustomEmail = async () => {
    const customEmail = keyword;

    if (!isValidEmail(customEmail)) return;

    // reset filterStaff keyword
    setKeyword('');

    // With custom email, default role is customer
    const newReceiver: CustomReceiverOption = {
      id: Date.now(),
      email: customEmail,
      name: customEmail,
      avatar: undefined,
      userCompany: { roleId: UserRole.CUSTOMER },
    };
    const currentReceiverIds = form.getFieldValue('receiverIds') || [];
    form.setFieldsValue({ receiverIds: [...currentReceiverIds, newReceiver.id] });

    // generate pdf for custom email
    const htmlString = generateHtmlString(newReceiver, taskDetail?.id as number);
    const bodyHtml = (await generateEmailBodyFromHtml({
      htmlString,
      roleStaff: newReceiver?.userCompany?.roleId as UserRole,
      staffId: newReceiver?.id as number,
    })) as string;

    // save this htmlString to preview
    setPdfFiles((pre) => [
      ...pre,
      {
        userId: newReceiver.id as number,
        email: newReceiver.email as string,
        bodyHtml,
        nameStaff: newReceiver.name as string,
      },
    ]);

    // update customReceiverOptions to show in select
    setCustomReceiverOptions((pre) => [...pre, newReceiver]);
  };

  useEffect(() => {
    const isCanNotFillDefaultValues = !isOpen || isLoadingStaff || pdfFiles?.length > 0 || isCreateDefaultPdf.current;

    if (isCanNotFillDefaultValues) return;

    // Generate PDF default for customer in task detail
    const setDefaultFile = async () => {
      try {
        const defaultReceiveStaff = uniqueListStaff?.find((staff) => staff.id === taskDetail?.customerId);
        const htmlString = generateHtmlString(defaultReceiveStaff, taskDetail?.id as number);
        const bodyHtml = (await generateEmailBodyFromHtml({
          htmlString,
          roleStaff: defaultReceiveStaff?.userCompany?.roleId as UserRole,
          staffId: defaultReceiveStaff?.id as number,
        })) as string;

        // save this htmlString to preview
        setPdfFiles([
          {
            userId: taskDetail?.customerId,
            email: taskDetail?.customerEmail as string,
            bodyHtml,
            nameStaff: defaultReceiveStaff?.name as string,
          },
        ]);

        // update isCreateDefaultPdf
        isCreateDefaultPdf.current = true;
      } catch (error) {
        handleErrorMessage(error);
        setIsCreatingPdf(false);
      }
    };

    setDefaultFile();
  }, [isOpen, isLoadingStaff, pdfFiles?.length]);

  return (
    <>
      <div className={styles.customButton} onClick={onClickBtnSendEmail}>
        <img src={icSendEmail} alt="" />
        <div className={classNames(styles.titleUnderline, 'strong')}>メール送信</div>
      </div>

      <CommonModal
        visible={isOpen}
        resetPadding
        title={textJP.titles.sendEmails}
        okText={textJP.btn.sendEmails}
        cancelText={textJP.btn.cancel}
        className={styles.modalSendEmail}
        onOk={() => form.submit()}
        onCancel={handleCancelModal}
        confirmLoading={isSendingEmail}
      >
        <Loader isLoading={isFirstLoadingStaff.current && isLoadingStaff} />

        <Form form={form} layout="vertical" onFinish={handleSendEmail}>
          <Form.Item label="From" name="replyToEmail" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>

          <Form.Item label="To" name="receiverIds" className="mb-10" rules={[rulesForm().required]}>
            <CommonSelect
              isAllowSelectDisabled
              autoClearSearchValue
              mode={TYPE_INPUT.MULTIPLE}
              options={receiverOptions as any}
              onPopupScroll={(e) => {
                handleOnPopupScroll(() => {
                  if (isLoadingStaff || isFetchingStaff) return;
                  hasNextPage && fetchNextPage();
                });
              }}
              loading={isLoadingStaff || isFetchingStaff}
              variants="multiple"
              allowClear
              filterOption={false}
              listHeight={200}
              searchValue={keyword}
              onSearch={setKeyword}
              onSelect={async (staffId) => {
                const staff = uniqueListStaff?.find((s) => s.id === staffId);
                const email = staff?.email;

                const isStaffInTask = checkIsUserInTask({ taskDetail, user: staff });

                // If staff is not in task, consider staff as customer
                const convertStaff = isStaffInTask
                  ? staff
                  : { ...staff, userCompany: { ...staff?.userCompany, roleId: UserRole.CUSTOMER } };

                const htmlString = generateHtmlString(convertStaff, taskDetail?.id as number);
                const bodyHtml = (await generateEmailBodyFromHtml({
                  htmlString,
                  roleStaff: convertStaff?.userCompany?.roleId as UserRole,
                  staffId: convertStaff?.id as number,
                })) as string;

                setPdfFiles((pre) => [
                  ...pre,
                  {
                    userId: staffId as number,
                    email: email as string,
                    bodyHtml,
                    nameStaff: convertStaff?.name as string,
                  },
                ]);
              }}
              onDeselect={(staffId) => {
                setPdfFiles((pre) => pre.filter((pdfFile) => pdfFile.userId !== staffId));
                setCustomReceiverOptions((pre) => pre.filter((receiver) => receiver.id !== staffId));
              }}
              onClear={() => {
                setPdfFiles([]);
                setCustomReceiverOptions([]);
              }}
              dropdownRender={(menu) => (
                <>
                  {isShowCreateCustomEmail && (
                    <DropRenderEmail email={keyword} handleAddEmail={() => handleSaveCustomEmail()} />
                  )}

                  {menu}
                </>
              )}
              onKeyDown={(e) => {
                e.stopPropagation();

                const isPressEnter = e.key === 'Enter';
                if (!isPressEnter) return;
                handleSaveCustomEmail();
              }}
              onBlur={() => setKeyword('')}
            />
          </Form.Item>

          <Form.Item
            label="件名"
            name="subject"
            className="mb-10"
            rules={[rulesForm().required, rulesForm().validatePdfFileName()]}
            validateFirst
          >
            <CommonInput placeholder="タイトルを入力してください。" maxLength={255} />
          </Form.Item>

          <Form.Item label="メッセージ" name="html" className="mb-10">
            <CommonInput placeholder="メッセージを入力してください" mode={TYPE_INPUT.MULTIPLE} />
          </Form.Item>

          <Loader isLoading={isCreatingPdf} variants="small">
            <Row align="middle" gutter={10}>
              {pdfFiles.map((pdfFile, index) => (
                <Col span={12} className={styles.linkPdf} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <PreviewPdf
                    namePreview={`${pdfFile.nameStaff}_${form?.getFieldValue('subject')}`}
                    onClick={() => {
                      setPreviewPdf({
                        open: true,
                        bodyHtml: pdfFile.bodyHtml,
                        nameFilePreview: form?.getFieldValue('subject'),
                      });
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Loader>
        </Form>
      </CommonModal>

      <CommonModal
        title=""
        titleCenter
        visible={previewPdf.open}
        width={1200}
        footer={null}
        onCancel={() => setPreviewPdf({ bodyHtml: '', open: false, nameFilePreview: '' })}
        resetPadding
      >
        <HeaderPdf
          nameFile={previewPdf.nameFilePreview}
          taskCompanyName={taskDetail?.taskCompanyName}
          profileName={profile?.name}
        />
        <div dangerouslySetInnerHTML={{ __html: previewPdf.bodyHtml }}></div>
      </CommonModal>
    </>
  );
};

export default ButtonSendEmail;
