import { Form, FormInstance, message } from 'antd';
import { updateNoteCalendar } from 'api/calendar';
import { updateExtraWorker } from 'api/worker';
import { queryClient } from 'App';
import { CommonStatus, STATUS } from 'common';
import { dateUtils } from 'common/dateUtils';
import { IBodyAddExtraWorker, IBodyUpdateExtraWorker, IComment } from 'common/interface';
import queryKeys from 'common/queryKeys';
import { SHIFT } from 'common/schedule';
import { confirmPopup, handleErrorMessage } from 'helper';
import useCheckPermissionCalendar from 'hooks/useCheckPermissionCalendar';
import useCheckRoleEditNote from 'hooks/useCheckRoleEditNote';
import { isNil } from 'lodash';
import moment from 'moment';
import { IFile } from 'pages/Tasks/components/ModalInforDetailsEdit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { createContext, useContext } from 'use-context-selector';

interface INoteCalendarContextProps {
  openModalWorker: boolean;
  setOpenModalWorker: React.Dispatch<React.SetStateAction<boolean>>;
  comment: IComment | null;
  setComment: React.Dispatch<React.SetStateAction<IComment | null>>;
  handleSelectNote: ({ note }: { note: IComment }) => void;
  handleUpdateWorkerNote: (values: any) => void;
  handleDeleteNote: () => void;
  handleInputTimeNote: (time: any) => any;
  isCanDeleteNote: () => boolean;
  formNote: FormInstance;
  updateExtraWorkerMutation: UseMutationResult<
    any,
    unknown,
    {
      body:
        | IBodyUpdateExtraWorker
        | {
            status: CommonStatus;
          };
      noteId: number;
      companyId?: number;
    },
    unknown
  >;
}

const NoteCalendarContext = createContext<INoteCalendarContextProps | null>(null);

const NoteCalendarProvider = ({ children }: { children: React.ReactNode }) => {
  const [openModalWorker, setOpenModalWorker] = useState(false);
  const [comment, setComment] = useState<IComment | null>(null);
  const { isCanDeleteNote } = useCheckRoleEditNote({ comment: comment! });
  const { checkCanOpenNote } = useCheckPermissionCalendar();
  const [formNote] = Form.useForm();

  const updateExtraWorkerMutation = useMutation({
    mutationFn: updateExtraWorker,
  });

  const handleSelectNote = useCallback(
    ({ note }: { note: IComment }) => {
      const isCanOpenNote = checkCanOpenNote({ note });

      if (!isCanOpenNote) return;

      // open modal note
      setComment(note);
      setOpenModalWorker(true);
    },
    [checkCanOpenNote]
  );

  const handleInputTimeNote = useCallback((time: any) => {
    if (!time) return null;
    if (!isNaN(time.valueOf())) return moment(time).format('HH:mm');
    return time;
  }, []);

  const handleUpdateWorkerNote = useCallback(
    (values: any) => {
      const time = handleInputTimeNote(values?.time);
      const endTime = handleInputTimeNote(values?.endTime);

      values?.shift?.forEach((item: SHIFT) => {
        if (item === SHIFT.MORNING) Object.assign(values, { isMorningShift: CommonStatus.ACTIVE });
        if (item === SHIFT.AFTERNOON) Object.assign(values, { isAfternoonShift: CommonStatus.ACTIVE });
        if (item === SHIFT.EVENING) Object.assign(values, { isEveningShift: CommonStatus.ACTIVE });
        if (item === SHIFT.OTHER) Object.assign(values, { isOtherShift: CommonStatus.ACTIVE });
      });
      delete values?.shift;

      const valuesUpdate: IBodyAddExtraWorker = {
        ...values,
        workingDay: dateUtils.currentDatabaseDate(values?.workingDay),
        time,
        endTime,
        morningExtraWorker: values?.isMorningShift ? values?.morningExtraWorker : undefined,
        afternoonExtraWorker: values?.isAfternoonShift ? values?.afternoonExtraWorker : undefined,
        eveningExtraWorker: values?.isEveningShift ? values?.eveningExtraWorker : undefined,
        otherExtraWorker: values?.isOtherShift ? values?.otherExtraWorker : undefined,
        files: values?.files?.map((file: IFile) => ({ name: file?.name, url: file?.url })) || [],
      };

      updateExtraWorkerMutation.mutate(
        { body: valuesUpdate, noteId: comment?.id!, companyId: comment?.companyId },
        {
          onError(error) {
            handleErrorMessage(error);
          },
          onSuccess: () => {
            message.success('成功');
            queryClient.invalidateQueries(queryKeys.calendarWorkerReport);
            queryClient.invalidateQueries(queryKeys.ListCommentWorkerReport);
            queryClient.invalidateQueries(queryKeys.notes.list);

            formNote.resetFields();
            setOpenModalWorker(false);
            setComment(null);
          },
        }
      );
    },
    [comment, formNote, handleInputTimeNote, updateExtraWorkerMutation]
  );

  // handle delete note
  const handleDeleteNote = useCallback(() => {
    confirmPopup({
      title: 'このメッセージを削除しますか。',
      onOk: () => {
        // When the comment was born from adding company Extra Worker
        if (comment?.companyExtraWorkerId) {
          updateExtraWorkerMutation.mutate(
            {
              body: { status: CommonStatus.INACTIVE },
              noteId: comment?.id,
              companyId: comment?.companyId,
            },
            {
              onError(error, variables, context) {
                handleErrorMessage(error);
              },
              onSuccess() {
                setOpenModalWorker(false);
                setComment(null);

                message.success('成功');
                queryClient.invalidateQueries(queryKeys.calendarWorkerReport);
                queryClient.invalidateQueries(queryKeys.ListCommentWorkerReport);
                queryClient.invalidateQueries(queryKeys.notes.list);
              },
            }
          );

          return;
        } else {
          updateNoteCalendar(
            comment?.id!,
            {
              status: STATUS.INACTIVE,
            },
            comment?.companyId
          )
            .then(() => queryClient.invalidateQueries(queryKeys.ListCommentWorkerReport))
            .catch(handleErrorMessage);
        }
      },
    });
  }, [comment, updateExtraWorkerMutation]);

  // set value to note
  useEffect(() => {
    if (!openModalWorker) return;

    const shifts: SHIFT[] = [];
    if (comment?.isMorningShift) shifts.push(SHIFT.MORNING);
    if (comment?.isAfternoonShift) shifts.push(SHIFT.AFTERNOON);
    if (comment?.isEveningShift) shifts.push(SHIFT.EVENING);
    if (comment?.isOtherShift) shifts.push(SHIFT.OTHER);

    !isNil(comment?.workingDayActual) && formNote.setFieldsValue({ workingDay: moment(comment?.workingDayActual) });

    formNote.setFieldsValue({
      userIdsView: comment?.userViewNote?.map((staff) => staff.userId) ?? [],
    });

    if (comment?.companyExtraWorkerId) {
      formNote.setFieldsValue({
        shift: shifts,
        extraWorker: comment?.extraWorker,
        time: comment?.time,
        note: comment?.note,
        endTime: comment?.endTime,
        morningExtraWorker: comment?.isMorningShift ? comment?.morningExtraWorker : undefined,
        afternoonExtraWorker: comment?.isAfternoonShift ? comment?.afternoonExtraWorker : undefined,
        eveningExtraWorker: comment?.isEveningShift ? comment?.eveningExtraWorker : undefined,
        otherExtraWorker: comment?.isOtherShift ? comment?.otherExtraWorker : undefined,
        files: comment.files ?? [],
      });
    }
  }, [formNote, comment, openModalWorker]);

  const value = useMemo(() => {
    return {
      openModalWorker,
      setOpenModalWorker,
      comment,
      setComment,
      handleSelectNote,
      handleUpdateWorkerNote,
      handleDeleteNote,
      handleInputTimeNote,
      isCanDeleteNote,
      updateExtraWorkerMutation,
      formNote,
    };
  }, [
    comment,
    openModalWorker,
    handleDeleteNote,
    handleInputTimeNote,
    handleSelectNote,
    handleUpdateWorkerNote,
    isCanDeleteNote,
    updateExtraWorkerMutation,
    formNote,
  ]);

  return <NoteCalendarContext.Provider value={value}>{children}</NoteCalendarContext.Provider>;
};

const useNoteCalendarContext = () => {
  const context = useContext(NoteCalendarContext);
  if (!context) {
    throw new Error('useNoteCalendarContext must be used within a NoteCalendarProvider');
  }
  return context;
};

export { NoteCalendarProvider, useNoteCalendarContext };
