import React from 'react';
import { NavLink } from 'react-router-dom';

type AllowedTags = keyof JSX.IntrinsicElements | React.ElementType;

type TProps<T extends AllowedTags = 'a'> = {
  as?: T;
  to?: string;
} & React.ComponentPropsWithoutRef<T>;

const LinkCondition = <T extends AllowedTags = 'a'>({ as: asComponent = 'a', to, ...rest }: TProps<T>) => {
  const Component = asComponent as React.ElementType;

  if (Component === 'a') {
    return <NavLink to={to!} {...rest} />;
  }

  return <Component {...rest} />;
};

export default LinkCondition;
