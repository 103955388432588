import { Tooltip } from 'antd';
import classNames from 'classnames';
import { UserRole, WORKING_TIME } from 'common';
import AvatarProfile from 'components/Avatar';
import styles from './styles.module.scss';
import { IVendor } from 'common/interface';
import { formatCurrencyJP } from 'helper';
import LinkCondition from 'components/LinkCondition';
interface VenderItemProps {
  vendor: IVendor;
  time: number;
  showPrice?: boolean;
  disableLink?: boolean;
}
function VenderItem({ vendor, time, showPrice = false, disableLink }: VenderItemProps) {
  const timeWork: any = {
    [WORKING_TIME.MORNING]: vendor?.workerMorning,
    [WORKING_TIME.AFTERNOON]: vendor?.workerAfternoon,
    [WORKING_TIME.EVENING]: vendor?.workerEvening,
    [WORKING_TIME.OTHER]: vendor?.workerOther,
  };

  const isUnActive = () => {
    if ([UserRole.ADMIN, UserRole.MANAGER, UserRole.LEADER, UserRole.OFFICE_MANAGER].includes(vendor?.roleId))
      return false;

    return !Boolean(vendor?.status);
  };

  return (
    <LinkCondition
      to={`/vendor/${vendor?.id}`}
      key={vendor?.id}
      className={classNames({ [styles.unActive]: isUnActive(), [styles.disableLink]: disableLink }, styles.vendorItem)}
      target="_blank"
      as={!disableLink ? 'a' : 'div'}
    >
      <AvatarProfile src={vendor.avatar} name={vendor.name} size={33} />
      <div className={styles.info}>
        <div className={styles.nameName}>
          <Tooltip className="line-1 strong" title={vendor?.name}>
            {vendor?.vendorCompanyName}
          </Tooltip>
        </div>
        <div className={styles.bottom}>
          <div className="fontsize-13 color-text2">必要なスタッフ数: {timeWork[time]}</div>
          {showPrice && (
            <Tooltip title={formatCurrencyJP(vendor?.price)} placement="topRight">
              <div className={classNames(styles.inputPrice, 'max-1-line')}>{formatCurrencyJP(vendor?.price)}</div>
            </Tooltip>
          )}
        </div>
      </div>
    </LinkCondition>
  );
}

export default VenderItem;
