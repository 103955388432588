import styles from './styles.module.scss';
import { CommonModal } from 'components/CommonModal';
import { useQuery } from 'react-query';
import queryKeys from 'common/queryKeys';
import { isEmpty, isNil } from 'lodash';
import { getDetailsEdit } from 'api/task';

import { Empty } from 'antd';
import Loader from 'components/Loader';
import BodyHistoryUpdate from './BodyHistoryUpdate';
import { ICompanyActive, IUnitPriceCertificate } from 'common/interface';
import { dateUtils } from 'common/dateUtils';
import { CommonStatus } from 'common';

interface IProps {
  taskId?: number;
  open: boolean;
  toggleOpen: (open: boolean) => void;
  companySelected?: ICompanyActive;
}

export interface IFile {
  name: string;
  url: string;
  id?: number;
}

export interface IMetaData {
  description?: string;
  morningShift?: number;
  afternoonShift?: number;
  eveningShift?: number;
  otherShift?: number;
  name?: string;
  maxWorkerEvening?: number;
  maxWorkerOther?: number;
  maxWorkerMorning?: number;
  maxWorkerAfternoon?: number;
  noteConstructionSite?: string;
  maxWorker?: string;
  date?: {
    from: string;
    to: string;
  };
  files?: IFile[];
  metadataCustomerUpdate?: string;
  startTimeDesire?: string;
  endTimeDesire?: string;
  constructionSiteNameOldUpdateProject?: string;
  morningShiftCustomer?: CommonStatus.ACTIVE | CommonStatus.INACTIVE;
  afternoonShiftCustomer?: CommonStatus.ACTIVE | CommonStatus.INACTIVE;
  eveningShiftCustomer?: CommonStatus.ACTIVE | CommonStatus.INACTIVE;
  otherShiftCustomer?: CommonStatus.ACTIVE | CommonStatus.INACTIVE;
}
export interface IMetaDataOrigin extends IMetaData {
  metadataCustomerUnitPriceCertificate?: {
    morningShift: IUnitPriceCertificate[];
    afternoonShift: IUnitPriceCertificate[];
    eveningShift: IUnitPriceCertificate[];
    otherShift: IUnitPriceCertificate[];
  };
}
export interface IMetaDataUpdate extends IMetaData {
  metadataCustomerUnitPriceCertificate?: string;
}

const ModalInforDetailsEdit = ({ taskId, open, toggleOpen, companySelected }: IProps) => {
  const { data: dataDetailsEdit, isLoading } = useQuery({
    queryKey: [queryKeys.detailsEdit, taskId],
    queryFn: () => getDetailsEdit(taskId as number, companySelected?.id),
    enabled: !isNil(taskId) && open,
  });

  return (
    <CommonModal
      title=""
      centered
      isModalVisible={open}
      handleClose={() => toggleOpen(false)}
      footer=""
      width={980}
      className={styles.modalDetailsEdit}
    >
      <Loader isLoading={isLoading}>
        <>
          <div className={styles.titleModalDetailsEdit}>変更履歴</div>

          <div className={styles.headTable}>
            <div className={styles.col150px}>承認日時</div>
            <div className={styles.col150px}>変更日時</div>
            <div style={{ display: 'flex', gap: '20px', flex: '1' }}>
              <div className={styles.colTitle}>項目</div>
              <div className={styles.colFull}>内容</div>
            </div>
          </div>

          {dataDetailsEdit?.data?.map((item) => {
            const dataUpdate = item?.dataUpdate ? (JSON.parse(item.dataUpdate) as IMetaData) : undefined;
            const dataOrigin = item?.dataOrigin ? (JSON.parse(item.dataOrigin) as IMetaData) : undefined;

            const isFirstTimeCreateTask = isEmpty(dataOrigin);

            return (
              <div key={item.id} className={styles.bodyTable}>
                <div className={styles.col150px}>
                  {isFirstTimeCreateTask ? '' : dateUtils.getDateAndTime24h(item?.createdAt)}
                </div>
                <div className={styles.col150px}>
                  {dateUtils.getDateAndTime24h(isFirstTimeCreateTask ? item?.createdAt : item?.customerUpdatedAt)}
                </div>

                <BodyHistoryUpdate dataUpdate={dataUpdate} dataOrigin={dataOrigin} />
              </div>
            );
          })}

          {dataDetailsEdit?.data?.length === 0 && <Empty />}
        </>
      </Loader>
    </CommonModal>
  );
};

export default ModalInforDetailsEdit;
