import { IProfile } from 'common/interface';
import queryKeys from 'common/queryKeys';
import { useQueryClient } from 'react-query';

interface IProps {
  profileUserViewPdf?: any;
  isCreateFilePdf?: boolean;
}

export default function useProfile({ profileUserViewPdf, isCreateFilePdf }: IProps = {}) {
  const queryClient = useQueryClient();
  const profile: any = queryClient.getQueryData(queryKeys.profile);
  return isCreateFilePdf ? profileUserViewPdf : (profile?.data as IProfile);
}
