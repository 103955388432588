import { Form } from 'antd';
import { capitalize } from 'lodash';
import { Shift } from 'pages/Tasks/AddTaskModal';
import Decimal from 'decimal.js';

interface IProps {
  shift: Shift;
  label: string;
}

const CountWorkShift = ({ shift, label }: IProps) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue }) => {
        const formValues = getFieldsValue(true) ?? {};

        const workerIdsName = `workerIds${capitalize(shift)}`;

        const totalCountWork =
          formValues?.[workerIdsName]?.reduce((sum: Decimal, workerId: number) => {
            const countWork = formValues[`count_work_${shift}_${workerId}`] || 0;
            return sum.plus(new Decimal(countWork));
          }, new Decimal(0)) ?? 0;

        return (
          <div className="strong text-error">
            {label} : {totalCountWork?.toString()}
          </div>
        );
      }}
    </Form.Item>
  );
};

export default CountWorkShift;
