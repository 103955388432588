import React from 'react';
import styles from './styles.module.scss';
import { textJP } from 'common/const';
import { IMetaDataOrigin } from '.';
import { arrowRight } from './BodyHistoryUpdate';

interface IProps {
  isFirstTimeCreateTask: boolean;
  dataOriginConvert: IMetaDataOrigin;
  shiftsAfterUpdate: {
    morningShift: boolean;
    afternoonShift: boolean;
    eveningShift: boolean;
    otherShift: boolean;
  };
}

const ShiftTask = ({ isFirstTimeCreateTask, dataOriginConvert, shiftsAfterUpdate }: IProps) => {
  const originShiftsString = [
    dataOriginConvert.morningShiftCustomer ? textJP.shift.morningShift : null,
    dataOriginConvert.afternoonShiftCustomer ? textJP.shift.afternoonShift : null,
    dataOriginConvert.eveningShiftCustomer ? textJP.shift.eveningShift : null,
    dataOriginConvert.otherShiftCustomer ? textJP.shift.otherShift : null,
  ]
    .filter(Boolean)
    .join(', ');

  const updateShiftsString = [
    shiftsAfterUpdate.morningShift ? textJP.shift.morningShift : null,
    shiftsAfterUpdate.afternoonShift ? textJP.shift.afternoonShift : null,
    shiftsAfterUpdate.eveningShift ? textJP.shift.eveningShift : null,
    shiftsAfterUpdate.otherShift ? textJP.shift.otherShift : null,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
      <div className={styles.colTitle}>プロジェクト時間</div>
      <div className={styles.colFull}>
        {isFirstTimeCreateTask ? (
          updateShiftsString
        ) : (
          <>
            {originShiftsString} {arrowRight} {updateShiftsString}
          </>
        )}
      </div>
    </div>
  );
};

export default ShiftTask;
