import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export interface IUpdateBody {
  name: string;
  price: number;
}

export const getLicense = async (companyId?: number) =>
  sendGet('/nest/license', undefined, companyId ? { companyId } : undefined);
export const updateLicense = async (id: number, body: IUpdateBody) => sendPut(`/nest/license/${id}`, body);
export const createLicense = async (body: IUpdateBody) => sendPost(`/nest/license`, body);
export const deleteLicense = async (id: number) => sendDelete(`/nest/license/${id}`);
