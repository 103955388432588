import { DAY_OF_WEEK, STATUS, ScheduleStatus, TYPE_PICKER, UserScheduleType } from 'common';
import { dateUtils } from 'common/dateUtils';
import { cloneDeep, omit } from 'lodash';
import moment, { Moment } from 'moment';

import {
  ICompanyProfile,
  IDataCreateUserScheduleDefault,
  IDataCreateUserScheduleOther,
  IHoliday,
  IUserSchedule,
} from 'common/interface';
import { listDay, textJP } from 'common/const';
import { Typography } from 'antd';

const { Text } = Typography;

export const getAttendanceDate = (schedule: IUserSchedule, profileCompany: ICompanyProfile) => {
  let days = 0;
  schedule?.detail.forEach((el) => {
    if (
      (el?.morning === STATUS.ACTIVE && profileCompany?.morningShift) ||
      (el?.afternoon === STATUS.ACTIVE && profileCompany?.afternoonShift) ||
      (el?.evening === STATUS.ACTIVE && profileCompany?.eveningShift) ||
      el?.other === STATUS.ACTIVE
    ) {
      days++;
    }
  });
  return days;
};

export const getNumberTimeOff = (schedule: IUserSchedule) => {
  return schedule.detail.reduce((acc: number, cur: any) => {
    if (cur?.timeOff) {
      return (acc += 1);
    }

    return acc;
  }, 0);
};

export const getNumberUnRegister = (schedule: IUserSchedule) => {
  return schedule?.detail?.reduce((acc: number, cur: any) => {
    const isUnRegister = [cur?.morning, cur?.afternoon, cur?.evening, cur?.other, cur?.timeOff]?.every(
      (item) => item === 0
    );

    if (isUnRegister) {
      return (acc += 1);
    }

    return acc;
  }, 0);
};

export const getParamsSendToApi = (filter: any) => {
  let startDate: any;
  let endDate: any;
  let type: any;

  // get start date, end date to send api
  if (filter.typeDate === TYPE_PICKER.WEEK) {
    startDate = dateUtils.startOfWeekDatabase(filter.date);
    endDate = dateUtils.endOfWeekDatabase(filter.date);
    type = UserScheduleType.WEEK;
  }

  if (filter.typeDate === TYPE_PICKER.MONTH) {
    startDate = dateUtils.startOfMonthDatabase(filter.date);
    endDate = dateUtils.endOfMonthDatabase(filter.date);
    type = UserScheduleType.MONTH;
  }

  // add start date, end date, type to filter
  const filterClone = { ...filter, startDate, endDate, type };

  // remove typeDate, date
  const filterSend = omit(filterClone, ['typeDate', 'date']);

  return { filterSend };
};

export const getStartDateAndEndDate = (date: Moment | null, typeDate?: TYPE_PICKER) => {
  if (typeDate === TYPE_PICKER.WEEK) {
    return {
      startDate: dateUtils.startOfWeekDatabase(date),
      endDate: dateUtils.endOfWeekDatabase(date),
    };
  }

  return {
    startDate: dateUtils.startOfMonthDatabase(date),
    endDate: dateUtils.endOfMonthDatabase(date),
  };
};

export const getFormatDate = (date: Moment, typeDate?: TYPE_PICKER) => {
  if (typeDate === TYPE_PICKER.WEEK) {
    return `${dateUtils.startOfWeekDisplay(date)} - ${dateUtils.endOfWeekDisplay(date)}`;
  }

  return date.format('YYYY-MM');
};

export const getFormatDateDataOther = (date: Moment) => {
  const weekday = dateUtils.getTextDayOfWeek(date);

  return date.format(`YYYY/MM/DD (${weekday})`);
};

export type TCheckBox = (typeof checkBoxDefault)[0] & { formattedDate?: string };

export const checkBoxDefault = [
  {
    day: DAY_OF_WEEK.SUNDAY,
    date: '日曜日',
    morning: 0,
    afternoon: 0,
    evening: 0,
    other: 0,
    timeOff: 1,
  },
  {
    day: DAY_OF_WEEK.MONDAY,
    date: '月曜日',
    morning: 1,
    afternoon: 1,
    evening: 0,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.TUESDAY,
    date: '火曜日',
    morning: 1,
    afternoon: 1,
    evening: 0,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.WEDNESDAY,
    date: '水曜日',
    morning: 1,
    afternoon: 1,
    evening: 0,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.THURSDAY,
    date: '木曜日',
    morning: 1,
    afternoon: 1,
    evening: 0,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.FRIDAY,
    date: '金曜日',
    morning: 1,
    afternoon: 1,
    evening: 0,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.SATURDAY,
    date: '土曜日',
    morning: 1,
    afternoon: 1,
    evening: 0,
    other: 0,
    timeOff: 0,
  },
];

export const checkBoxAllDefault = [
  {
    day: DAY_OF_WEEK.SUNDAY,
    date: '日曜日',
    morning: 1,
    afternoon: 1,
    evening: 1,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.MONDAY,
    date: '月曜日',
    morning: 1,
    afternoon: 1,
    evening: 1,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.TUESDAY,
    date: '火曜日',
    morning: 1,
    afternoon: 1,
    evening: 1,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.WEDNESDAY,
    date: '水曜日',
    morning: 1,
    afternoon: 1,
    evening: 1,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.THURSDAY,
    date: '木曜日',
    morning: 1,
    afternoon: 1,
    evening: 1,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.FRIDAY,
    date: '金曜日',
    morning: 1,
    afternoon: 1,
    evening: 1,
    other: 0,
    timeOff: 0,
  },
  {
    day: DAY_OF_WEEK.SATURDAY,
    date: '土曜日',
    morning: 1,
    afternoon: 1,
    evening: 1,
    other: 0,
    timeOff: 0,
  },
];

export const convertDataMonth = ({
  values,
  type,
  checkedListDefault,
}: {
  values: any;
  type: TYPE_PICKER;
  checkedListDefault: typeof checkBoxDefault;
}) => {
  const dataOther =
    values?.exceptData?.map((item: any) => ({
      ...item.typeShifts,
      date: dateUtils.getDateToString(item.date) as string,
    })) ?? [];

  const dataDefault = checkedListDefault.map((item) => {
    const itemRemoveFields = omit(item, ['date']);

    return { ...itemRemoveFields };
  });

  return {
    dataOther,
    dataDefault,
    targetId: values.targetId,
    startDate:
      type === TYPE_PICKER.MONTH
        ? dateUtils.startOfMonthDatabase(values?.date)
        : dateUtils.startOfWeekDatabase(values?.date),
    endDate:
      type === TYPE_PICKER.MONTH
        ? dateUtils.endOfMonthDatabase(values?.date)
        : dateUtils.endOfWeekDatabase(values?.date),
    type: type === TYPE_PICKER.MONTH ? UserScheduleType.MONTH : UserScheduleType.WEEK,
  };
};

export const convertDataOtherToValueForm = (dataOther: IDataCreateUserScheduleOther[]) => {
  const exceptData = dataOther?.map((item) => ({
    date: moment(item?.date),
    typeShifts: {
      ...(omit(item, 'date') as Record<string, number>),
    },
  }));

  return exceptData ?? [];
};

export const convertDataDefaultToValueForm = (dataDefault: IDataCreateUserScheduleDefault[]) => {
  return (
    dataDefault
      ?.map((item) => ({
        ...item,
        date: listDay[item.day as keyof typeof listDay],
      }))
      ?.sort((a, b) => a?.day - b?.day) ?? []
  );
};

export const renderStatusSchedule = (status: number) => {
  switch (status) {
    case ScheduleStatus.ACCEPT:
      return <Text className="color-green">{textJP.scheduleStatus.accept}</Text>;
    case ScheduleStatus.REJECT:
      return <Text className="color-red">{textJP.scheduleStatus.reject}</Text>;
    case ScheduleStatus.PENDING:
      return <Text className="color-orange">{textJP.scheduleStatus.pending}</Text>;
    case ScheduleStatus.DELETED:
      return <Text className="color-red">{textJP.scheduleStatus.deleted}</Text>;
    default:
      break;
  }
};

export const renderAppearEditUser = (startDate: any) => {
  const nextWeek = dateUtils.getNextWeek();

  if (dateUtils.getDay(startDate).isoWeek() < nextWeek.isoWeek() - 1) {
    if (dateUtils.getDay(startDate).isAfter(dateUtils.getToday())) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const getConvertCheckedListFromDate = ({
  startDate,
  endDate,
  checkedList,
}: {
  startDate: string;
  endDate: string;
  checkedList: TCheckBox[];
}) => {
  let index = 0;

  const checkedListClone = cloneDeep(checkedList);

  for (let currentDate = moment(startDate); currentDate.isSameOrBefore(moment(endDate)); currentDate.add(1, 'days')) {
    const formattedDate = currentDate.format('YYYY-MM-DD');

    checkedListClone[index] = {
      ...checkedListClone[index],
      formattedDate: formattedDate,
    };

    index++;
  }

  return checkedListClone?.sort((a, b) => a?.day - b?.day);
};
