import styles from './styles.module.scss';

interface IProps {
  email: string;
  handleAddEmail: () => void;
}

const DropRenderEmail = ({ email, handleAddEmail }: IProps) => {
  return (
    <div
      className={styles.container}
      onClick={handleAddEmail}
      onMouseDown={(e) => {
        e.preventDefault(); // Ngăn Select bị mất focus khi click vào
      }}
    >
      {email}
    </div>
  );
};

export default DropRenderEmail;
