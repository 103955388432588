import { dateUtils } from 'common/dateUtils';
import {
  ICheckedTask,
  IConstructionSite,
  ITaskDetail,
  IUnitPriceCertificateWorker,
  IUnitPriceCertificateWorkerItem,
  IUnitPriceCertificateWorkerItemInForm,
  TShift,
} from 'common/interface';
import { capitalize, isNil, uniq } from 'lodash';
import moment, { Moment } from 'moment';
import { FormInstance } from 'rc-field-form';
import { priceByCountWorkShift } from '../components/ItemWorkerSelected';
import { CommonStatus, ConstructionSiteType, OtherShiftType, ShipOfDay } from 'common';
import { getSalaryStaff, getShiftNumber, roundCountWork } from 'helper';
import { Shift } from '../AddTaskModal';

export const getTimeDesire = (time: any) => {
  return typeof time === 'object' ? time?.format('HH:mm') : time || null;
};

export const getTimesDesire = (values: any) => {
  return {
    startTimeDesire: getTimeDesire(values?.startTimeDesire),
    endTimeDesire: getTimeDesire(values?.endTimeDesire),
  };
};

export const getTimesDesireUpdate = (values: any, taskDetail?: ITaskDetail) => {
  return {
    startTimeDesire:
      typeof values?.startTimeDesire === 'object'
        ? values?.startTimeDesire?.format('HH:mm')
        : values?.startTimeDesire || taskDetail?.startTimeDesire || null,

    endTimeDesire:
      typeof values?.endTimeDesire === 'object'
        ? values?.endTimeDesire?.format('HH:mm')
        : values?.endTimeDesire || taskDetail?.endTimeDesire || null,
  };
};

export const getWidth = (checked: ICheckedTask) => {
  const isCheckedShift = checked.morning || checked.afternoon || checked.evening || checked.other;

  const widthModal = isCheckedShift ? 1200 : 800;
  const spanCol1 = isCheckedShift ? 12 : 24;
  const spanCol2 = isCheckedShift ? 12 : 0;

  return {
    widthModal,
    spanCol1,
    spanCol2,
  };
};

export const getDayOffs = (form?: FormInstance) => {
  const dayOffs = form?.getFieldValue('dayOffs') || [];

  return dayOffs.map((item: { format: Moment }) => dateUtils.currentDatabaseDate(item?.format));
};

export const getDayOffsFromMetadata = (taskDetail: ITaskDetail) => {
  const metadataDayOffs = taskDetail?.metadataDayOffs ? JSON.parse(taskDetail.metadataDayOffs) : [];

  const dayOffs = (metadataDayOffs as string[])?.map((dayOff) => ({ format: moment(dayOff) }));

  return dayOffs;
};

export const getCountWork = ({ values }: { values: any }) => {
  const uniqueIdWorkers = uniq([
    ...(values?.workerIdsMorning ?? []),
    ...(values?.workerIdsAfternoon ?? []),
    ...(values?.workerIdsEvening ?? []),
    ...(values?.workerIdsOther ?? []),
  ]);

  const countWork = uniqueIdWorkers?.map((id: number) => {
    let countWorker = {
      userId: id,
    } as any;

    if (!isNil(values?.[`count_work_morning_${id}`])) {
      countWorker.countWorkMorning = +values?.[`count_work_morning_${id}`];
    }

    if (!isNil(values?.[`count_work_afternoon_${id}`])) {
      countWorker.countWorkAfternoon = +values?.[`count_work_afternoon_${id}`];
    }

    if (!isNil(values?.[`count_work_evening_${id}`])) {
      countWorker.countWorkEvening = +values?.[`count_work_evening_${id}`];
    }

    if (!isNil(values?.[`count_work_other_${id}`])) {
      countWorker.countWorkOther = +values?.[`count_work_other_${id}`];
    }

    return countWorker;
  });
  // bỏ qua các worker chỉ 1 trường userId mà không có countWork
  // ?.filter((item) => {
  //   return Object.keys(item)?.length > 1;
  // });

  return countWork;
};

export const getUnitPriceWorker = ({ values }: { values: any }) => {
  const uniqueIdWorkers = uniq([
    ...(values?.workerIdsMorning ?? []),
    ...(values?.workerIdsAfternoon ?? []),
    ...(values?.workerIdsEvening ?? []),
    ...(values?.workerIdsOther ?? []),
  ]);

  const unitPriceWorker = uniqueIdWorkers?.map((id: number) => {
    const unitPrice = {
      userId: id,
    } as any;
    const isStaffMorning = values?.workerIdsMorning?.includes(id);
    if (isStaffMorning) {
      const nameFormPriceMorningOfWorker = `${priceByCountWorkShift.morning}_${id}`;
      const priceMorning = values?.[nameFormPriceMorningOfWorker] ? +values?.[nameFormPriceMorningOfWorker] : 0;
      unitPrice.priceMorning = priceMorning;
    }

    const isStaffAfternoon = values?.workerIdsAfternoon?.includes(id);
    if (isStaffAfternoon) {
      const nameFormPriceAfternoonOfWorker = `${priceByCountWorkShift.afternoon}_${id}`;
      const priceAfternoon = values?.[nameFormPriceAfternoonOfWorker] ? +values?.[nameFormPriceAfternoonOfWorker] : 0;
      unitPrice.priceAfternoon = priceAfternoon;
    }

    const isStaffEvening = values?.workerIdsEvening?.includes(id);
    if (isStaffEvening) {
      const nameFormPriceEveningOfWorker = `${priceByCountWorkShift.evening}_${id}`;
      const priceEvening = values?.[nameFormPriceEveningOfWorker] ? +values?.[nameFormPriceEveningOfWorker] : 0;
      unitPrice.priceEvening = priceEvening;
    }

    const isStaffOtherShift = values?.workerIdsOther?.includes(id);
    if (isStaffOtherShift) {
      const price = values?.[`price_other_worker_${id}`] ? +values?.[`price_other_worker_${id}`] : 0;
      unitPrice.price = price;
    }

    return unitPrice;
  });

  return { unitPriceWorker };
};

export const getUnitPriceWorkerDefault = ({
  taskDetail,
}: // constructionWorkers,
{
  taskDetail: ITaskDetail;
  // constructionWorkers: Record<number, IWorker>;
}) => {
  const unitPriceWorkerDefault = taskDetail?.workers?.reduce((obj, worker) => {
    const isOldPriceData = [worker?.priceMorning, worker?.priceAfternoon, worker?.priceEvening].every(isNil);
    const salaryWorker = worker.userSalary;

    if (worker?.morningShift === CommonStatus.ACTIVE) {
      const defaultPrice =
        salaryWorker &&
        (getSalaryStaff({
          roleIdStaff: worker?.roleId,
          shift: 'morning',
          salary: salaryWorker,
          salaryType: salaryWorker?.salaryType,
        }) ??
          0);
      obj[`price_morning_${worker.id}`] = isOldPriceData ? defaultPrice : worker?.priceMorning;
    }

    if (worker?.afternoonShift === CommonStatus.ACTIVE) {
      const defaultPrice =
        salaryWorker &&
        (getSalaryStaff({
          roleIdStaff: worker?.roleId,
          shift: 'afternoon',
          salary: salaryWorker,
          salaryType: salaryWorker?.salaryType,
        }) ??
          0);
      obj[`price_afternoon_${worker.id}`] = isOldPriceData ? defaultPrice : worker?.priceAfternoon;
    }

    if (worker?.eveningShift === CommonStatus.ACTIVE) {
      const defaultPrice =
        salaryWorker &&
        (getSalaryStaff({
          roleIdStaff: worker?.roleId,
          shift: 'evening',
          salary: salaryWorker,
          salaryType: salaryWorker?.salaryType,
        }) ??
          0);
      obj[`price_evening_${worker.id}`] = isOldPriceData ? defaultPrice : worker?.priceEvening;
    }

    if (worker?.otherShift === CommonStatus.ACTIVE) {
      const defaultPrice =
        salaryWorker &&
        (getSalaryStaff({
          roleIdStaff: worker?.roleId,
          shift: 'other',
          salary: salaryWorker,
          salaryType: salaryWorker?.salaryType,
        }) ??
          0);
      obj[`price_other_worker_${worker.id}`] = isOldPriceData ? defaultPrice : worker?.price;
    }

    return obj;
  }, {} as any);

  return unitPriceWorkerDefault;
};

export const getInitialCountWorkWorker = ({
  values,
  checked,
  checkedOfAdmin,
  shift,
  isFixPriceConstructionSite,
}: {
  values: any;
  checked: Record<Shift, boolean>;
  checkedOfAdmin: Record<TShift, boolean>;
  shift: Shift;
  isFixPriceConstructionSite: boolean;
}) => {
  const defaultValue = 1;
  const isShiftCustomerSameAsAdmin = checkedOfAdmin[`${shift}Shift`] === checked[shift];
  const maxWorkerInShift = values?.[`maxWorker${capitalize(shift)}`];
  const numberWorkerInShift = values?.[`worker${capitalize(shift)}`];

  if (isFixPriceConstructionSite || isNil(numberWorkerInShift) || +numberWorkerInShift <= 0) return defaultValue;

  if (isShiftCustomerSameAsAdmin && maxWorkerInShift !== numberWorkerInShift) {
    return roundCountWork((maxWorkerInShift || 0) / (numberWorkerInShift || 1));
  }

  return defaultValue; // default value
};

export const checkIsFixPriceConstructionSite = ({
  values,
  constructionSiteDetail,
  isSmallClientTask,
}: {
  values: any;
  constructionSiteDetail?: IConstructionSite;
  isSmallClientTask: boolean;
}) => {
  const typeOfConstruction = values?.constructionSite?.type;
  const isFixPriceSmallTask = typeOfConstruction === ConstructionSiteType.FIXED_PRICE;

  // data of normal task
  const isFixPriceNormalTask = constructionSiteDetail?.type === ConstructionSiteType.FIXED_PRICE;

  const isFixPriceConstructionSite = isSmallClientTask ? isFixPriceSmallTask : isFixPriceNormalTask;

  return isFixPriceConstructionSite;
};

export const checkIsAddCountToMorningShift = (otherShiftType?: OtherShiftType) => {
  return (
    !isNil(otherShiftType) &&
    [
      OtherShiftType.MORNING,
      OtherShiftType.MORNING_AFTERNOON,
      OtherShiftType.MORNING_EVENING,
      OtherShiftType.MORNING_AFTERNOON_EVENING,
    ].includes(otherShiftType)
  );
};

export const checkIsAddCountToAfternoonShift = (otherShiftType?: OtherShiftType) => {
  return (
    !isNil(otherShiftType) &&
    [
      OtherShiftType.AFTERNOON,
      OtherShiftType.MORNING_AFTERNOON,
      OtherShiftType.AFTERNOON_EVENING,
      OtherShiftType.MORNING_AFTERNOON_EVENING,
    ].includes(otherShiftType)
  );
};

export const checkIsAddCountToEveningShift = (otherShiftType?: OtherShiftType) => {
  return (
    !isNil(otherShiftType) &&
    [
      OtherShiftType.EVENING,
      OtherShiftType.MORNING_EVENING,
      OtherShiftType.AFTERNOON_EVENING,
      OtherShiftType.MORNING_AFTERNOON_EVENING,
    ].includes(otherShiftType)
  );
};

export const getOtherShiftType = ({ values = {} }: { values: any }) => {
  const { otherShiftTypeMorning, otherShiftTypeAfternoon, otherShiftTypeEvening } = values;

  if (otherShiftTypeMorning && otherShiftTypeAfternoon && otherShiftTypeEvening) {
    return OtherShiftType.MORNING_AFTERNOON_EVENING;
  } else if (otherShiftTypeMorning && otherShiftTypeAfternoon) {
    return OtherShiftType.MORNING_AFTERNOON;
  } else if (otherShiftTypeMorning && otherShiftTypeEvening) {
    return OtherShiftType.MORNING_EVENING;
  } else if (otherShiftTypeAfternoon && otherShiftTypeEvening) {
    return OtherShiftType.AFTERNOON_EVENING;
  } else if (otherShiftTypeMorning) {
    return OtherShiftType.MORNING;
  } else if (otherShiftTypeAfternoon) {
    return OtherShiftType.AFTERNOON;
  } else if (otherShiftTypeEvening) {
    return OtherShiftType.EVENING;
  } else {
    return OtherShiftType.OTHER;
  }
};

export const getDefaultOtherShiftType = ({ taskDetail }: { taskDetail: ITaskDetail }) => {
  const otherShiftTypeMorning = checkIsAddCountToMorningShift(taskDetail?.otherShiftType);
  const otherShiftTypeAfternoon = checkIsAddCountToAfternoonShift(taskDetail?.otherShiftType);
  const otherShiftTypeEvening = checkIsAddCountToEveningShift(taskDetail?.otherShiftType);

  return {
    otherShiftTypeMorning,
    otherShiftTypeAfternoon,
    otherShiftTypeEvening,
  };
};

export const checkCheckedShiftAdminSameCountShift = ({
  checkedOfAdmin,
  values,
}: {
  checkedOfAdmin: {
    morningShift: boolean;
    afternoonShift: boolean;
    eveningShift: boolean;
    otherShift: boolean;
  };
  values: any;
}) => {
  // không được đồng thời check cả shift admin và count shift
  const otherShiftTypeMorning = values?.otherShiftTypeMorning;
  const otherShiftTypeAfternoon = values?.otherShiftTypeAfternoon;
  const otherShiftTypeEvening = values?.otherShiftTypeEvening;

  const isCheckedSame = [
    checkedOfAdmin.morningShift && otherShiftTypeMorning,
    checkedOfAdmin.afternoonShift && otherShiftTypeAfternoon,
    checkedOfAdmin.eveningShift && otherShiftTypeEvening,
  ].some(Boolean);

  return isCheckedSame;
};

const setUnitPriceCertificateWorkerInShift = ({
  values,
  shift,
  result,
}: {
  values: any;
  shift: Shift;
  result: any;
}) => {
  const nameWorkerIds = `workerIds${capitalize(shift)}`;

  for (const idWorker of values?.[nameWorkerIds]) {
    if (!result[idWorker]) {
      result[idWorker] = {} as any;
    }

    const workerItemInShift = values?.[`certificate_worker_${shift}_${idWorker}`] || [];

    const unitPriceCertificateInShift = workerItemInShift.map((item: IUnitPriceCertificateWorkerItemInForm) => {
      let itemUnitPrice = {
        name: item?.name?.label?.[0],
        priceStaff: item?.priceStaff,
      } as IUnitPriceCertificateWorkerItem;

      if (typeof item?.name?.value === 'number') {
        itemUnitPrice.licenseId = item?.name?.value;
      }

      return itemUnitPrice;
    });

    const shiftNumber =
      shift === 'morning'
        ? ShipOfDay.MORNING
        : shift === 'afternoon'
        ? ShipOfDay.AFTERNOON
        : shift === 'evening'
        ? ShipOfDay.EVENING
        : ShipOfDay.OTHER;

    result[idWorker][shiftNumber] = unitPriceCertificateInShift;
  }
};

export const getUnitPriceCertificate = (values: any) => {
  const result: IUnitPriceCertificateWorker = {};

  if (values?.workerIdsMorning?.length) setUnitPriceCertificateWorkerInShift({ values, shift: 'morning', result });
  if (values?.workerIdsAfternoon?.length) setUnitPriceCertificateWorkerInShift({ values, shift: 'afternoon', result });
  if (values?.workerIdsEvening?.length) setUnitPriceCertificateWorkerInShift({ values, shift: 'evening', result });
  if (values?.workerIdsOther?.length) setUnitPriceCertificateWorkerInShift({ values, shift: 'other', result });

  return result;
};

const getUnitPriceCertificateWorkerDefaultInShift = ({
  workerId,
  unitPriceCertificateParse,
  shift,
  unitPriceCertificateDefault,
}: {
  workerId: number;
  unitPriceCertificateParse: IUnitPriceCertificateWorker['string'];
  shift: Shift;
  unitPriceCertificateDefault: any;
}) => {
  const nameFormList = `certificate_worker_${shift}_${workerId}`;

  unitPriceCertificateDefault[nameFormList] = unitPriceCertificateParse?.[getShiftNumber(shift)!]?.map((item: any) => {
    return {
      priceStaff: item.priceStaff,
      name: item.licenseId
        ? { key: item.licenseId, label: [item?.title || item?.name], value: item.licenseId }
        : {
            label: [item?.title || item?.name],
          },
    } as IUnitPriceCertificateWorkerItemInForm;
  });
};

export const getUnitPriceCertificateWorkerDefault = ({ taskDetail }: { taskDetail: ITaskDetail }) => {
  const unitPriceCertificateWorkerDefault = taskDetail?.workers?.reduce((obj, worker) => {
    const unitPriceCertificateParse: IUnitPriceCertificateWorker['string'] = worker?.unitPriceCertificate
      ? JSON.parse(worker.unitPriceCertificate)
      : {};
    const unitPriceCertificateDefault = {} as any;

    if (worker.morningShift) {
      getUnitPriceCertificateWorkerDefaultInShift({
        shift: 'morning',
        workerId: worker.id,
        unitPriceCertificateParse,
        unitPriceCertificateDefault,
      });
    }

    if (worker.afternoonShift) {
      getUnitPriceCertificateWorkerDefaultInShift({
        shift: 'afternoon',
        workerId: worker.id,
        unitPriceCertificateParse,
        unitPriceCertificateDefault,
      });
    }

    if (worker.eveningShift) {
      getUnitPriceCertificateWorkerDefaultInShift({
        shift: 'evening',
        workerId: worker.id,
        unitPriceCertificateParse,
        unitPriceCertificateDefault,
      });
    }

    if (worker.otherShift) {
      getUnitPriceCertificateWorkerDefaultInShift({
        shift: 'other',
        workerId: worker.id,
        unitPriceCertificateParse,
        unitPriceCertificateDefault,
      });
    }

    return { ...obj, ...unitPriceCertificateDefault };
  }, {} as any);

  return unitPriceCertificateWorkerDefault;
};
