import { emptyData } from 'common/const';
import React from 'react';

interface IProps {
  address?: string;
}

const AddressLink = ({ address }: IProps) => {
  if (!address) {
    return <span>{emptyData}</span>;
  }

  return (
    <a href={`http://maps.google.com/?q=${address}`} target="_blank" rel="noreferrer">
      {address}
    </a>
  );
};

export default AddressLink;
