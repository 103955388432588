import { Col, Row } from 'antd';
import icons from 'assets/icons';
import { textJP } from 'common/const';
import { ITaskDetail } from 'common/interface';
import CommonSpace from 'components/CommonSpace';
import SectionWrapper from 'components/SectionWrapper';
import AddressLink from 'pages/Calendar/components/AddressLink';
import { spanColLabel, spanColValue } from '../../ContentTaskDetails';

interface IProps {
  taskDetail?: ITaskDetail;
}

const CustomerInSmallClientTaskDetailsForAdmin = ({ taskDetail }: IProps) => {
  return (
    <SectionWrapper>
      <CommonSpace align="center" gap={5} className="mb-10">
        <img src={icons.userDouble.black} alt="" />
        <span className="strong">{textJP.clientInformation}</span>
      </CommonSpace>
      <Row gutter={[20, 10]}>
        <Col span={spanColLabel} className="fontsize-14 text-bold">
          {textJP.clientName}
        </Col>
        <Col span={spanColValue} className="fontsize-14 color-text2">
          {taskDetail?.customer?.name}
        </Col>

        <Col span={spanColLabel} className="fontsize-14 text-bold">
          {textJP.companyName}
        </Col>
        <Col span={spanColValue} className="fontsize-14 color-text2">
          {taskDetail?.customer?.companyName || textJP.none}
        </Col>

        <Col span={spanColLabel} className="fontsize-14 text-bold">
          {textJP.phone}
        </Col>
        <Col span={spanColValue} className="fontsize-14 color-text2">
          {taskDetail?.customer?.phone}
        </Col>

        <Col span={spanColLabel} className="fontsize-14 text-bold">
          {textJP.postCode}
        </Col>
        <Col span={spanColValue} className="fontsize-14 color-text2">
          {taskDetail?.customer?.postalCode}
        </Col>

        <Col span={spanColLabel} className="fontsize-14 text-bold">
          {textJP.address}
        </Col>
        <Col span={spanColValue} className="fontsize-14 color-text2">
          <AddressLink address={taskDetail?.customer?.address} />
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default CustomerInSmallClientTaskDetailsForAdmin;
