import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { TaskDetailModal } from 'pages/Tasks/TaskDetailModal';
import { Router, useParams } from 'react-router-dom';
import { QueryClientProvider, useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { getConstructionSiteTaskDetails } from 'api/constructionSite';
import { Col, Form, Row } from 'antd';
import { CommonInput } from 'components/CommonInput';
import Loader from 'components/Loader';
import { sendEmailMulti } from 'api/task';
import {
  checkIsUserInTask,
  checkStaffInactive,
  handleErrorMessage,
  handleMessageSuccess,
  handleOnPopupScroll,
} from 'helper';
import { useRulesForm } from 'hooks/useRulesForm';
import { getNamePdfFile } from 'pages/Tasks/components/ButtonSendEmail/utils';
import { CommonStatus, TYPE_INPUT, UserRole } from 'common';
import useProfile from 'hooks/useProfile';
import sanitize from 'sanitize-filename';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import { CommonButton } from 'components/CommonButton';
import { getImageFormUrl } from 'api/common';
import { uploadFilePdf, uploadMultiple } from 'api/upload';
import { CommonSelect } from 'components/CommonSelect';
import queryKeys from 'common/queryKeys';
import { ALL_ROLES, ROLES_CAN_VIEW_CERTIFICATE_VENDORS } from 'common/const';
import { getStaff } from 'api/staff';
import { CustomReceiverOption, IPayloadSendEmails, IPdfFile, IReceiver } from 'common/interface';
import { renderToStaticMarkup } from 'react-dom/server';
import { history, queryClient } from 'App';
import { ContentTaskDetails } from 'pages/Tasks/TaskDetailModal/ContentTaskDetails';
import HeaderPdf from 'pages/Tasks/components/HeaderPdf';
import PreviewPdf from 'pages/Tasks/components/PreviewPdf';
import { isValidEmail } from 'helper/validator';
import DropRenderEmail from 'pages/Tasks/components/DropRenderEmail';
import { useDebounce } from 'use-debounce';

type TValue = {
  replyToEmail: string;
  to: string;
  subject: string;
  html: string;
  receiverIds: number[];
};

type MessageType = 'close-modal' | 'viewPdf';

const SendEmail = () => {
  const detailsTaskRef = useRef<any>(null);

  const { taskId, companyId } = useParams<{ taskId: string; companyId: string }>();
  const profile = useProfile();

  const [form] = Form.useForm();
  const { rulesForm } = useRulesForm();

  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [customReceiverOptions, setCustomReceiverOptions] = useState<CustomReceiverOption[]>([]);
  const [pdfFiles, setPdfFiles] = useState<IPdfFile[]>([]);
  const isCreateDefaultPdf = useRef(false); // check if create default pdf for customer in task detail
  const isFirstLoadingStaff = useRef(true);
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword] = useDebounce(keyword, 300); // Debounce 300ms

  // get details task
  const { data: taskDetail, isLoading: isLoadingTaskDetail } = useQuery(
    ['construction-site-task-detail', taskId, companyId],
    () => getConstructionSiteTaskDetails(+taskId, +companyId).then((res: any) => res?.data),
    { enabled: !!taskId }
  );

  const {
    data: dataListStaff,
    isLoading: isLoadingStaff,
    isFetching: isFetchingStaff,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.staff.listStaff, taskDetail?.companyId, debouncedKeyword],
    queryFn: ({ pageParam = 1 }) =>
      getStaff({
        companyId: taskDetail?.companyId,
        filters: {
          roleIds: ALL_ROLES,
          status: [CommonStatus.ACTIVE, CommonStatus.INACTIVE],
          pageIndex: pageParam,
          pageSize: 10000, // fake large number to get all data
          isAutoCreateTask: CommonStatus.INACTIVE,
          keyword: debouncedKeyword,
        },
      }),
    getNextPageParam: (lastPage, pages) => {
      const { pageIndex, totalPages } = lastPage;

      if (pageIndex >= totalPages) {
        return false;
      }

      return pageIndex + 1;
    },
    onSuccess() {
      if (isFirstLoadingStaff.current) {
        isFirstLoadingStaff.current = false;
      }
    },
  });

  const listStaff = dataListStaff?.pages?.map((page) => page?.data)?.reduce((arr, data) => [...arr, ...data], []) || [];
  const uniqueListStaff = Array.from(new Map(listStaff.map((s) => [s.id, s])).values()) ?? [];
  const receiverOptions = [
    ...uniqueListStaff,
    ...customReceiverOptions.filter((staff) => {
      const prefixEmail = (staff?.email as string)?.split('@')?.[0];

      return keyword ? prefixEmail.includes(keyword) : true;
    }),
  ]?.map((staff) => ({ ...staff, disabled: checkStaffInactive(staff?.userCompany?.status) }));

  const isShowCreateCustomEmail =
    isValidEmail(keyword) &&
    !isLoadingStaff &&
    uniqueListStaff.length === 0 &&
    !customReceiverOptions.find((receiver) => receiver.email?.includes(keyword));

  const sendEmailMutation = useMutation({
    mutationFn: sendEmailMulti,
  });

  const handlePostMessage = ({ type, payload }: { type: MessageType; payload?: any }) => {
    (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({ type, payload }));
  };

  const handleCancelModal = () => {
    handlePostMessage({ type: 'close-modal' });
  };

  const handleSendEmail = async (values: TValue) => {
    setIsSendingEmail(true);

    try {
      // loop through pdfFiles to create pdf file
      const pdfFilesPromises = pdfFiles.map((pdfFiles) =>
        generatePdfFromHtml({
          bodyHtml: pdfFiles.bodyHtml,
          nameFile: values.subject,
        })
      );
      const listPdfCreate = await Promise.all(pdfFilesPromises);

      // upload all pdf files to s3
      const response = await uploadMultiple(listPdfCreate);

      // url of pdf files
      const urls = response.data.map((suffixUrl) => `${response.domain}/${suffixUrl}`);

      const receivers: IReceiver[] = pdfFiles.map((pdfFile, index) => ({
        email: pdfFile.email as string,
        fileUrl: urls[index],
      }));

      const payloadSendEmails: IPayloadSendEmails = {
        taskId: taskDetail?.id as number,
        replyToEmail: values.replyToEmail,
        subject: values.subject ?? '',
        html: values.html ?? '',
        receivers: receivers,
      };

      await sendEmailMutation.mutateAsync({ data: payloadSendEmails, companyId: +companyId });

      // Sau khi gửi email xong thì đóng modal lại nên cần reset form, state
      handleMessageSuccess('メールを送信しました。');
      handleCancelModal();
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setIsSendingEmail(false);
    }
  };

  const generateEmailBodyFromHtml = async ({
    htmlString,
    roleStaff,
    staffId,
  }: {
    htmlString: string;
    roleStaff: UserRole;
    staffId: number;
  }) => {
    if (!htmlString || !detailsTaskRef.current) return;

    try {
      // Create a div containing HTML content from the input string
      const wrapperDiv = document.createElement('div');
      wrapperDiv.innerHTML = htmlString;

      // Create placeholder for header element
      const headerPlaceholder = document.createElement('div');
      headerPlaceholder.id = 'header-placeholder';

      // Hide unnecessary elements with class "hide-with-pdf"
      wrapperDiv.querySelectorAll('.hide-with-pdf').forEach((el) => el.remove());

      // Remove unnecessary classes
      wrapperDiv.querySelectorAll('.fixed-header-form').forEach((el) => el.classList.remove('fixed-header-form'));

      // fix bug css when printing pdf
      wrapperDiv.querySelectorAll('.ant-avatar-string').forEach((el: any) => {
        el.style.opacity = 1;
        el.style.position = 'static';
      });

      // Add section containing certificates-vendors
      const isCanViewCertificateVendors = ROLES_CAN_VIEW_CERTIFICATE_VENDORS.includes(roleStaff);
      if (isCanViewCertificateVendors) {
        const certificatesVendorsHtml = snapShortCertificateVendors({
          roleStaff: roleStaff,
          staffId,
        });
        const placeHolderCertificatesVendors = wrapperDiv.querySelector('#place-holder-certificates-vendors');
        if (placeHolderCertificatesVendors && certificatesVendorsHtml) {
          placeHolderCertificatesVendors.replaceWith(certificatesVendorsHtml);
        }
      }

      // Process images to avoid CORS errors
      wrapperDiv.querySelectorAll('img')?.forEach((img: any) => {
        if (img?.src?.startsWith('https://')) {
          img.src = img.src.replace(img.src, getImageFormUrl(img.src));
        }
      });

      // Wrap everything into a new div
      const finalWrapper = document.createElement('div');
      finalWrapper.appendChild(headerPlaceholder);
      finalWrapper.appendChild(wrapperDiv);

      return finalWrapper.innerHTML;
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  const generatePdfFromHtml = async ({ bodyHtml, nameFile }: { bodyHtml: string; nameFile: string }) => {
    if (!bodyHtml) return;

    setIsCreatingPdf(true);

    try {
      const bodyElement = document.createElement('div');
      bodyElement.innerHTML = bodyHtml;

      // Add Header
      const elementHeader = document.createElement('div');
      elementHeader.innerHTML = renderToStaticMarkup(
        <HeaderPdf nameFile={nameFile} taskCompanyName={taskDetail?.taskCompanyName} profileName={profile?.name} />
      );

      // Find header placeholder and replace with header
      const headerPlaceholderElement = bodyElement.querySelector('#header-placeholder');

      if (headerPlaceholderElement) {
        headerPlaceholderElement.replaceWith(elementHeader);
      }

      // PDF options
      const opt = {
        margin: 0.5,
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' },
      };

      // Generate PDF blob
      const blob = await html2pdf().set(opt).from(bodyElement).outputPdf('blob', nameFile);

      // Convert blob to file
      const file = new File([blob], nameFile, { type: 'application/pdf' });

      return file;
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setIsCreatingPdf(false);
    }
  };

  const snapShortCertificateVendors = ({ roleStaff, staffId }: { roleStaff: UserRole; staffId: number }) => {
    const isVendor = roleStaff === UserRole.VENDOR;

    const certificatesVendors = detailsTaskRef.current?.querySelector('#certificates-vendors');

    if (isVendor) {
      const certificateVendor = certificatesVendors?.querySelectorAll('[id^="items_vendor_"]') || [];

      // vendor can only see its own certificate
      certificateVendor.forEach((el: any) => {
        if (el.id !== `items_vendor_${staffId}`) {
          el.remove();
        }
      });
    }

    return certificatesVendors?.cloneNode(true);
  };

  const generateHtmlString = (profileUserViewPdf: any, taskId: number) => {
    return renderToStaticMarkup(
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <ContentTaskDetails taskId={taskDetail?.id as number} profileUserViewPdf={profileUserViewPdf} />
        </Router>
      </QueryClientProvider>
    );
  };

  const handleSaveCustomEmail = async () => {
    const customEmail = keyword;

    if (!isValidEmail(customEmail)) return;

    // reset filterStaff keyword
    setKeyword('');

    // With custom email, default role is customer
    const newReceiver: CustomReceiverOption = {
      id: Date.now(),
      email: customEmail,
      name: customEmail,
      avatar: undefined,
      userCompany: { roleId: UserRole.CUSTOMER },
    };
    const currentReceiverIds = form.getFieldValue('receiverIds') || [];
    form.setFieldsValue({ receiverIds: [...currentReceiverIds, newReceiver.id] });

    // generate pdf for custom email
    const htmlString = generateHtmlString(newReceiver, taskDetail?.id as number);
    const bodyHtml = (await generateEmailBodyFromHtml({
      htmlString,
      roleStaff: newReceiver?.userCompany?.roleId as UserRole,
      staffId: newReceiver?.id as number,
    })) as string;

    // save this htmlString to preview
    setPdfFiles((pre) => [
      ...pre,
      {
        userId: newReceiver.id as number,
        email: newReceiver.email as string,
        bodyHtml,
        nameStaff: newReceiver.name as string,
      },
    ]);

    // update customReceiverOptions to show in select
    setCustomReceiverOptions((pre) => [...pre, newReceiver]);
  };

  // watch isLoadingTaskDetail and ref detailsTaskRef to render pdf
  useEffect(() => {
    const isCanNotFillDefaultValues =
      isLoadingTaskDetail || isLoadingStaff || pdfFiles?.length > 0 || isCreateDefaultPdf.current;

    if (isCanNotFillDefaultValues) return;

    // Generate PDF default for customer in task detail
    const setDefaultFile = async () => {
      try {
        const defaultReceiveStaff = uniqueListStaff?.find((staff) => staff.id === taskDetail?.customerId);
        const htmlString = generateHtmlString(defaultReceiveStaff, taskDetail?.id as number);
        const bodyHtml = (await generateEmailBodyFromHtml({
          htmlString,
          roleStaff: defaultReceiveStaff?.userCompany?.roleId as UserRole,
          staffId: defaultReceiveStaff?.id as number,
        })) as string;

        // save this htmlString to preview
        setPdfFiles([
          {
            userId: taskDetail?.customerId,
            email: taskDetail?.customerEmail as string,
            bodyHtml,
            nameStaff: defaultReceiveStaff?.name as string,
          },
        ]);

        // update isCreateDefaultPdf
        isCreateDefaultPdf.current = true;
      } catch (error) {
        handleErrorMessage(error);
        setIsCreatingPdf(false);
      }
    };

    // Set default form values
    const setDefaultForm = () => {
      const namePdfFile = sanitize(getNamePdfFile(taskDetail) || 'task_details.pdf');

      // Set default form values
      form.setFieldsValue({
        replyToEmail: profile?.email,
        to: taskDetail?.customerEmail,
        subject: namePdfFile,
        receiverIds: [taskDetail?.customerId],
      });
    };

    // Generate PDF default for customer in task detail
    setDefaultForm();
    setDefaultFile();
  }, [isLoadingTaskDetail, taskDetail, profile?.email, form, isLoadingStaff, pdfFiles?.length, uniqueListStaff]);

  return (
    <>
      <div className={styles.wrapper}>
        <Loader isLoading={isFirstLoadingStaff.current && isLoadingStaff} />

        <Form form={form} layout="vertical" onFinish={handleSendEmail}>
          <Form.Item label="From" name="replyToEmail" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>

          <Form.Item label="To" name="receiverIds" className="mb-10" rules={[rulesForm().required]}>
            <CommonSelect
              isAllowSelectDisabled
              autoClearSearchValue
              mode={TYPE_INPUT.MULTIPLE}
              options={receiverOptions as any}
              onPopupScroll={(e) => {
                handleOnPopupScroll(() => {
                  if (isLoadingStaff || isFetchingStaff) return;
                  hasNextPage && fetchNextPage();
                });
              }}
              loading={isLoadingStaff || isFetchingStaff}
              variants="multiple"
              allowClear
              filterOption={false}
              listHeight={200}
              searchValue={keyword}
              onSearch={setKeyword}
              onSelect={async (staffId) => {
                const staff = uniqueListStaff?.find((s) => s.id === staffId);
                const email = staff?.email;

                const isStaffInTask = checkIsUserInTask({ taskDetail, user: staff });

                // If staff is not in task, consider staff as customer
                const convertStaff = isStaffInTask
                  ? staff
                  : { ...staff, userCompany: { ...staff?.userCompany, roleId: UserRole.CUSTOMER } };

                const htmlString = generateHtmlString(convertStaff, taskDetail?.id as number);
                const bodyHtml = (await generateEmailBodyFromHtml({
                  htmlString,
                  roleStaff: convertStaff?.userCompany?.roleId as UserRole,
                  staffId: convertStaff?.id as number,
                })) as string;

                setPdfFiles((pre) => [
                  ...pre,
                  {
                    userId: staffId as number,
                    email: email as string,
                    bodyHtml,
                    nameStaff: convertStaff?.name as string,
                  },
                ]);
              }}
              onDeselect={(staffId) => {
                setPdfFiles((pre) => pre.filter((pdfFile) => pdfFile.userId !== staffId));
                setCustomReceiverOptions((pre) => pre.filter((receiver) => receiver.id !== staffId));
              }}
              onClear={() => {
                setPdfFiles([]);
                setCustomReceiverOptions([]);
              }}
              dropdownRender={(menu) => (
                <>
                  {isShowCreateCustomEmail && (
                    <DropRenderEmail email={keyword} handleAddEmail={() => handleSaveCustomEmail()} />
                  )}

                  {menu}
                </>
              )}
              onKeyDown={(e) => {
                e.stopPropagation();

                const isPressEnter = e.key === 'Enter';
                if (!isPressEnter) return;
                handleSaveCustomEmail();
              }}
              onBlur={() => setKeyword('')}
            />
          </Form.Item>

          <Form.Item label="件名" name="subject" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="タイトルを入力してください。" />
          </Form.Item>
          <Form.Item label="メッセージ" name="html" className="mb-10">
            <CommonInput placeholder="メッセージを入力してください" mode={TYPE_INPUT.MULTIPLE} />
          </Form.Item>

          <Loader isLoading={isCreatingPdf} variants="small">
            <Row align="middle" gutter={10}>
              {pdfFiles.map((pdfFile, index) => (
                <Col span={12} className={styles.linkPdf} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <PreviewPdf
                    namePreview={`${pdfFile.nameStaff}_${form?.getFieldValue('subject')}`}
                    onClick={async () => {
                      try {
                        const nameFile = form.getFieldValue('subject');

                        const pdf = await generatePdfFromHtml({
                          bodyHtml: pdfFile.bodyHtml,
                          nameFile,
                        });

                        // const isReactNativeEnv = (window as any).ReactNativeWebView;
                        // upload file pdf to s3 to get link
                        const { domain, data } = await uploadFilePdf(pdf!);
                        const url = `${domain}/${data?.[0]}`;

                        handlePostMessage({ type: 'viewPdf', payload: url });
                      } catch (error) {
                        handleErrorMessage(error);
                      }
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Loader>
          <CommonButton
            title="メール送信"
            htmlType="submit"
            type="primary"
            loading={isSendingEmail}
            disabled={isSendingEmail}
            className="mt-20 w-100"
          />
        </Form>
      </div>

      <TaskDetailModal
        taskId={+taskId}
        onCancel={() => {}}
        visible
        className={styles.myModal}
        ref={detailsTaskRef as any}
      />
    </>
  );
};

export default SendEmail;
