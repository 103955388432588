import _, { omit, omitBy } from 'lodash';
import { Row, Select } from 'antd';
import chevronDown from 'assets/icons/chevron-down-black.svg';
import { useForm } from 'antd/lib/form/Form';
import styles from './styles.module.scss';
import AvatarProfile from 'components/Avatar';
import useProfile from 'hooks/useProfile';
import useRoleId from 'hooks/useRoleId';
import { STATUS, UserRole } from 'common';
import { IVendor } from 'common/interface';
import { HandleSelectedVendorFunc, Shift } from '../AddTaskModal';
import ItemVendorPicker from './ItemVendorPicker';
import { FormInstance } from 'antd/es/form/Form';

const { Option } = Select;

interface IProps {
  value?: any[];
  options: any[];
  onChange?: Function;
  disabled?: boolean;
  onSelect?: (vendor: IVendor) => any;
  onRemove?: (vendor: IVendor) => any;
  hideOtherVendor?: boolean;
  handleSelectedVendor?: HandleSelectedVendorFunc;
  handlePickSelectedVendor?: (vendor: IVendor, type: 'add' | 'remove') => void;
  type?: 'certificateVendor';
  shift?: Shift;
  form?: FormInstance;
  onPopupScroll?: (e: any) => void;
  onSearch?: (value: string) => void;
  filterOption?: any;
  onBlur?: any;
}

export default function VendorPicker(props: IProps) {
  const {
    value = [],
    onChange,
    onSelect,
    onRemove,
    options,
    disabled,
    handleSelectedVendor,
    type,
    handlePickSelectedVendor,
    shift,
    form,
    onPopupScroll,
    onSearch,
    filterOption,
    onBlur,
  } = props;

  const roleId = useRoleId();
  const profile = useProfile();

  const handleSelect = (id: any) => {
    const vendor = options?.find((x) => x.id === id);
    const newValue = _.uniqBy([{ ...vendor, maxWorker: null }, ...(value || [])], 'id');

    handleSelectedVendor?.({ vendor, type: 'add', shift });

    if (type === 'certificateVendor') {
      handlePickSelectedVendor?.(vendor, 'add');
    }

    onChange && onChange(newValue);
    onSelect && onSelect(vendor);
  };

  const handleRemove = (vendor: IVendor) => {
    const newValue = value?.filter((x: any) => x.id !== vendor.id);

    handleSelectedVendor?.({ vendor, type: 'remove', shift });

    if (type === 'certificateVendor') {
      handlePickSelectedVendor?.(vendor, 'remove');
    }

    onChange && onChange(newValue);
    onRemove && onRemove!(vendor);
  };

  const handleChangeMaxWorker = (id: number, maxWorker: number) => {
    const vendor = value?.find((x) => x.id === id);
    if (vendor) {
      vendor.maxWorker = maxWorker;
      onChange && onChange([...value]);
    }
  };

  const handleChangePrice = (id: number, price: number) => {
    const vendor = value?.find((x) => x.id === id);
    if (vendor) {
      vendor.price = price;
      onChange && onChange([...value]);
    }
  };

  const getValueRender = () => {
    // neu la chung chi cua vendor
    if (type === 'certificateVendor') {
      return options.filter((vendor: IVendor & { isPicked?: boolean }) => vendor?.isPicked);
    }

    return value;
  };

  return (
    <div style={{ cursor: disabled ? 'not-allowed' : undefined, opacity: disabled ? 0.5 : 1 }}>
      <div className={disabled ? styles.disabled : undefined}>
        <Select
          className={styles.container}
          suffixIcon={<img width={12} height={12} src={chevronDown} alt="" />}
          showSearch
          onChange={handleSelect}
          value={[]} // array of selected vendor
          disabled={disabled}
          onPopupScroll={onPopupScroll}
          onSearch={onSearch}
          filterOption={filterOption}
          onBlur={onBlur}
        >
          {options
            ?.filter((option: any) => option?.isActive === STATUS.ACTIVE || option.status === STATUS.ACTIVE)
            ?.map((option: any) => (
              <Option key={option?.id} value={option.id}>
                <div key={option?.id} className={styles.option}>
                  <AvatarProfile src={option.avatar} name={option.name} size={33} />
                  <div className={styles.info}>
                    {option?.vendorCompanyName} - {option?.name}
                  </div>
                </div>
              </Option>
            ))}
        </Select>

        {/* list options selected */}
        <Row gutter={8} className={styles.listSelected}>
          {getValueRender()?.map((vendor: IVendor, index) => {
            if (roleId === UserRole.VENDOR && profile.id !== vendor.id) return null;

            const isActive = vendor?.status === STATUS.ACTIVE;

            // chung chi cua vendor da chon
            if (type === 'certificateVendor') {
              const nameFieldShift =
                shift === 'morning'
                  ? 'workerMorning'
                  : shift === 'afternoon'
                  ? 'workerEvening'
                  : shift === 'evening'
                  ? 'workerEvening'
                  : 'workerOther';

              return (
                <ItemVendorPicker
                  key={vendor.id}
                  form={form}
                  handleChangeMaxWorker={handleChangeMaxWorker}
                  handleRemove={handleRemove}
                  vendor={vendor}
                  isActive={isActive}
                  type={type}
                  isGetDetailsVendor
                  nameFieldMaxWorker={['vendors', String(index), nameFieldShift]}
                />
              );
            }

            if (shift === 'morning') {
              return (
                <ItemVendorPicker
                  key={vendor.id}
                  form={form}
                  handleChangeMaxWorker={handleChangeMaxWorker}
                  handleRemove={handleRemove}
                  vendor={vendor}
                  isActive={isActive}
                  type={type}
                  isGetDetailsVendor
                  nameFieldMaxWorker={['vendorsMorning', String(index), 'maxWorker']}
                  span={24}
                />
              );
            }

            if (shift === 'evening') {
              return (
                <ItemVendorPicker
                  key={vendor.id}
                  form={form}
                  handleChangeMaxWorker={handleChangeMaxWorker}
                  handleRemove={handleRemove}
                  vendor={vendor}
                  isActive={isActive}
                  type={type}
                  isGetDetailsVendor
                  nameFieldMaxWorker={['vendorsEvening', String(index), 'maxWorker']}
                  span={24}
                />
              );
            }

            if (shift === 'afternoon') {
              return (
                <ItemVendorPicker
                  key={vendor.id}
                  form={form}
                  handleChangeMaxWorker={handleChangeMaxWorker}
                  handleRemove={handleRemove}
                  vendor={vendor}
                  isActive={isActive}
                  type={type}
                  isGetDetailsVendor
                  nameFieldMaxWorker={['vendorsAfternoon', String(index), 'maxWorker']}
                  span={24}
                />
              );
            }

            if (shift === 'other') {
              return (
                <ItemVendorPicker
                  key={vendor.id}
                  form={form}
                  handleChangeMaxWorker={handleChangeMaxWorker}
                  handleChangePrice={handleChangePrice}
                  handleRemove={handleRemove}
                  vendor={vendor}
                  isActive={isActive}
                  type={type}
                  isGetDetailsVendor
                  nameFieldMaxWorker={['vendorsOther', String(index), 'maxWorker']}
                  nameFieldPrice={['vendorsOther', String(index), 'price']}
                  span={24}
                  showPrice={[UserRole.VENDOR, UserRole.OTHER_WORKER].includes(vendor.roleId)}
                />
              );
            }

            return null;
          })}
        </Row>
      </div>
    </div>
  );
}
