import { Col, Row, Space, Typography } from 'antd';
import SectionWrapper from 'components/SectionWrapper';
import icCompany from 'assets/icons/company.svg';
import icDoubleUserBlack from 'assets/icons/user-double-black.svg';
import { IMetadataCustomerUpdate, ITaskDetail } from 'common/interface';
import { emptyData, spanColLabel, spanColValue } from '../../ContentTaskDetails';
import CustomerNameLink from 'pages/Calendar/components/CustomerNameLink';
import { UserRole } from 'common';
import ProjectNameLink from 'pages/Calendar/components/ProjectNameLink';
import { ROLES_ADMIN, textJP } from 'common/const';
import AddressLink from 'pages/Calendar/components/AddressLink';
import StaffNameLink from 'pages/Calendar/components/StaffNameLink';

interface IProps {
  taskDetail?: ITaskDetail;
  currentRoleInCompany: UserRole;
  isCreateFilePdf: boolean;
  isCanViewMetadata: boolean;
  isAppearMetadata: (key: string) => boolean;
  metadataCustomerUpdate: IMetadataCustomerUpdate;
  isSmallClientTask?: boolean;
}

const CustomerInNormalTaskDetails = ({
  taskDetail,
  currentRoleInCompany,
  isCreateFilePdf,
  isCanViewMetadata,
  isAppearMetadata,
  metadataCustomerUpdate,
  isSmallClientTask,
}: IProps) => {
  return (
    <SectionWrapper>
      <Row gutter={[20, 10]}>
        <Col span={24}>
          <Row gutter={[20, 10]}>
            {isSmallClientTask && !ROLES_ADMIN.includes(currentRoleInCompany) ? null : (
              <>
                <Col span={spanColLabel}>
                  <Space align="center" size={4}>
                    <img src={icCompany} alt="" className="mb-2" />
                    <span className="strong">{taskDetail?.customerCompanyName || emptyData}</span>
                  </Space>
                </Col>
              </>
            )}

            <Col span={spanColValue} className="fontsize-14 color-text2">
              <Space align="center" size={4}>
                <img src={icDoubleUserBlack} alt="" className="mb-2" />
                <CustomerNameLink
                  currentRoleInCompany={currentRoleInCompany}
                  name={taskDetail?.customerName || emptyData}
                  customerId={taskDetail?.customerId}
                  isCreateFilePdf={isCreateFilePdf}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Col span={spanColLabel} className="fontsize-14 text-bold">
          プロジェクト名
        </Col>
        <Col span={spanColValue} className="fontsize-14 color-text2">
          <ProjectNameLink
            currentRoleInCompany={currentRoleInCompany}
            constructionSiteId={taskDetail?.constructionSiteId as number}
            constructionSiteName={taskDetail?.constructionSiteName}
            isAutoCreateTask={taskDetail?.isAutoCreateTask}
          />
        </Col>

        <Col span={spanColLabel} className="fontsize-14 text-bold">
          {textJP.address}
        </Col>
        <Col span={spanColValue} className="fontsize-14 color-text2">
          <AddressLink address={taskDetail?.address} />
        </Col>

        {!isSmallClientTask && (
          <>
            <Col span={spanColLabel} className="fontsize-14 text-bold">
              {textJP.label.projectLeader}
            </Col>
            <Col span={spanColValue} className="fontsize-14 color-text2">
              <StaffNameLink
                name={taskDetail?.leaderName || emptyData}
                staffId={taskDetail?.leaderId}
                currentRoleInCompany={currentRoleInCompany}
              />
            </Col>
          </>
        )}

        <Col span={spanColLabel} className="fontsize-14 text-bold">
          プロジェクトリーダー
        </Col>
        <Col span={spanColValue} className="fontsize-14 color-text2">
          {taskDetail?.leaderTaskPhone
            ? `${taskDetail?.leaderTaskName} - ${taskDetail?.leaderTaskPhone}`
            : taskDetail?.leaderTaskName || emptyData}
        </Col>

        {isSmallClientTask && !ROLES_ADMIN.includes(currentRoleInCompany) ? null : (
          <>
            <Col span={spanColLabel} className="fontsize-14 text-bold">
              プロジェクト概要・メモ
            </Col>
            <Col span={spanColValue} className="fontsize-14 color-text2">
              <div className="textPreWrap"> {taskDetail?.noteConstructionSite || emptyData}</div>

              {isCanViewMetadata && isAppearMetadata('noteConstructionSite') ? (
                <div className="text-green textPreWrap">
                  {metadataCustomerUpdate?.noteConstructionSite || emptyData}
                </div>
              ) : null}

              <Space direction="vertical" size={1}>
                {taskDetail?.filesConstructionSite?.map((file: any, index: number) => (
                  <Typography.Link target="_blank" href={file.url} key={file?.id || index}>
                    {file?.name}
                  </Typography.Link>
                ))}
              </Space>
            </Col>
          </>
        )}
      </Row>
    </SectionWrapper>
  );
};

export default CustomerInNormalTaskDetails;
