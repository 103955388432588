import { UserRole } from 'common';
import { ROLES_CAN_EDIT_CUSTOMER } from 'common/const';
import LinkCondition from 'components/LinkCondition';

interface IProps {
  name: string;
  customerId?: number;
  currentRoleInCompany: UserRole;
  isCreateFilePdf?: boolean;
}

const CustomerNameLink = ({ name, customerId, currentRoleInCompany, isCreateFilePdf }: IProps) => {
  const isCanEditCustomer = ROLES_CAN_EDIT_CUSTOMER.includes(currentRoleInCompany);
  const className = 'strong';

  const getLink = () => {
    // When create file pdf, we don't need to modal edit customer
    if (isCreateFilePdf) return `/customer/${customerId}`;

    // Other case, we need to modal edit customer
    if (isCanEditCustomer) return `/customer/${customerId}?edit=true`;
    return `/customer/${customerId}`;
  };

  return (
    <LinkCondition as={isCanEditCustomer ? 'a' : 'div'} to={getLink()} target="_blank" rel="noreferrer">
      <span className={className}>{name}</span>
    </LinkCondition>
  );
};

export default CustomerNameLink;
