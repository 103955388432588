import { Col, Divider, Form, FormInstance, Row } from 'antd';
import icons from 'assets/icons';
import { textJP } from 'common/const';
import { CommonIconButton } from 'components/CommonIconButton';
import { CommonInput } from 'components/CommonInput';
import CommonInputNumberV2 from 'components/CommonInputNumberV2';
import { CommonSelect } from 'components/CommonSelect';
import { setValueLabelInValue } from 'helper';
import { useRulesForm } from 'hooks/useRulesForm';
import { useRef, useState } from 'react';

interface IProps {
  className?: string;
  name: number;
  nameFormList: string;
  options: any[];
  form?: FormInstance;
  label?: string;

  remove: (name: number) => void;
}

const ItemFormWorkerSelected = (props: IProps) => {
  const { name, nameFormList, options = [], form, remove, label, className, ...restField } = props;
  const [customTitle, setCustomTitle] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const isSelectingRef = useRef(false);

  const { rulesForm } = useRulesForm();

  const handleSetCustomTitle = () => {
    if (!customTitle.trim() || isSelectingRef.current) return;

    const valueUpdate = form?.getFieldValue(nameFormList)?.map((item: any, index: number) => {
      if (index === name) {
        return {
          ...item,
          name: setValueLabelInValue(customTitle),
        };
      }

      return item;
    });

    // set custom title to form
    form?.setFieldsValue({
      [nameFormList]: valueUpdate,
    });

    // reset custom title
    setCustomTitle('');

    // blur input to close dropdown
    inputRef?.current?.blur();
  };

  return (
    <Row className={className} gutter={[10, 10]}>
      <Col span={11}>
        <Form.Item {...restField} name={[name, 'name']} className="mb-0" label={label} rules={[rulesForm().required]}>
          <CommonSelect
            allowClear
            style={{ width: '100%' }}
            options={options}
            optionLabel="name"
            optionValue="id"
            showSearch={false}
            labelInValue
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <div style={{ padding: '0 8px 4px' }}>
                  <CommonInput
                    ref={inputRef}
                    value={customTitle}
                    onChange={(e) => setCustomTitle(e.target.value)}
                    placeholder={textJP.label.manualInput}
                    onBlur={() => {
                      if (customTitle.trim() && !isSelectingRef.current) {
                        handleSetCustomTitle();
                      }
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation(); // prevent select option when press Enter
                      if (e.key !== 'Enter' || !customTitle.trim()) return;
                      handleSetCustomTitle();
                    }}
                  />
                </div>
              </>
            )}
            onSelect={(value: any) => {
              isSelectingRef.current = true;

              const valueUpdate = form?.getFieldValue(nameFormList)?.map((item: any, index: number) => {
                if (index === name) {
                  const priceStaff = options.find((opt) => opt.id === value?.value)?.price ?? 0;

                  return {
                    ...item,
                    priceStaff,
                  };
                }

                return item;
              });

              // Clear custom title first
              setCustomTitle('');

              // Then set form values
              form?.setFieldsValue({
                [nameFormList]: valueUpdate,
              });

              // Reset selecting flag after a delay
              setTimeout(() => {
                isSelectingRef.current = false;
              }, 100);

              // Force blur the input to ensure dropdown closes
              inputRef?.current?.blur();
            }}
          />
        </Form.Item>
      </Col>

      <Col span={11}>
        <Form.Item {...restField} name={[name, 'priceStaff']} label={textJP.label.price} rules={[rulesForm().required]}>
          <CommonInputNumberV2 />
        </Form.Item>
      </Col>

      <Col span={1}>
        <Form.Item label=" ">
          <CommonIconButton icon={icons.trash.gray} title={textJP.delete} onClick={() => remove(name)} shape="circle" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ItemFormWorkerSelected;
