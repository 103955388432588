import { UserRole } from 'common';
import { ROLES_ADMIN } from 'common/const';
import LinkCondition from 'components/LinkCondition';
import React from 'react';

interface IProps {
  name: string;
  staffId?: number;
  currentRoleInCompany: UserRole;
}

const StaffNameLink = ({ name, staffId, currentRoleInCompany }: IProps) => {
  const isAdmin = ROLES_ADMIN.includes(currentRoleInCompany);

  const getLink = () => {
    return `/staff/${staffId}`;
  };

  return (
    <LinkCondition as={isAdmin ? 'a' : 'div'} to={getLink()} target="_blank" rel="noreferrer">
      <span>{name}</span>
    </LinkCondition>
  );
};

export default StaffNameLink;
