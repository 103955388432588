import { getListConstructionSiteMultiCompany } from 'api/constructionSite';
import { UserRole } from 'common';
import { ROLES_ADMIN } from 'common/const';
import { ICompanyActive, IProject } from 'common/interface';
import queryKeys from 'common/queryKeys';
import { CommonSelect, ISelectProps } from 'components/CommonSelect';
import { getRoleName, getRoleNameInListProject, handleOnPopupScroll } from 'helper';
import useListProjectTask from 'hooks/apis/useListProjectTask';
import useProfile from 'hooks/useProfile';

import { isNil, uniqBy } from 'lodash';
import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';

interface IProps extends ISelectProps<any> {
  filters?: Record<string, any>;
  defaultValue?: Record<string, any>;
  keyUniq?: string;
  companySelected?: ICompanyActive;
  companyIds?: number[];
  numberCompanyUserCanCreateTask?: number;
}

export const convertFullNameProject = ({
  project,
  numberCompanyUserCanCreateTask,
  role,
}: {
  project: IProject;
  numberCompanyUserCanCreateTask?: number;
  role: UserRole;
}) => {
  if (isNil(project)) return '';

  const roleInProject = project?.userHandleRoleId;

  /* ------------------------- các role Admins ------------------------- */
  if (ROLES_ADMIN.includes(roleInProject)) {
    // let fullNameProject = `<span class="strong fontsize-16">${project?.name}</span>_${project?.customerName}`;

    let fullNameProject = `<span class="strong">${project?.name}_</span>`;

    // tên cty trong project + role của user trong project
    if (numberCompanyUserCanCreateTask && numberCompanyUserCanCreateTask > 1) {
      fullNameProject += `<span class="text-error">${
        project?.projectCompanyNickname?.trim() || project?.projectCompanyName?.trim() || project?.companyName
      }</span> <span class="text-error">(${getRoleNameInListProject({
        roleId: project?.userHandleRoleId,
        isCustomer: !!project?.userHandleIsCustomer,
      })})</span>`;
    }

    // tên nickname customer hoặc tên cty của customer
    fullNameProject += `<span>_${
      project?.customerNickname?.trim() || project?.customerCompanyName?.trim() || ''
    }</span>`;

    return fullNameProject;
  }

  /* ------------------------- others role : CUSTOMER ------------------------- */
  let fullNameProject = `<span class="strong">${project?.name}`;

  // tên cty trong project + role của user trong project
  if (numberCompanyUserCanCreateTask && numberCompanyUserCanCreateTask > 1) {
    fullNameProject += `_</span><span class="text-error">${
      project?.projectCompanyName?.trim() || project?.companyName
    }</span> <span class="text-error">(${getRoleNameInListProject({
      roleId: project?.userHandleRoleId,
      isCustomer: !!project?.userHandleIsCustomer,
    })})</span>`;
  }

  return fullNameProject;
};

const SelectProject = ({
  filters,
  defaultValue,
  keyUniq = 'id',
  companySelected,
  numberCompanyUserCanCreateTask,
  ...rest
}: IProps) => {
  const isOnlyVendor = companySelected?.roleId === UserRole.VENDOR && !companySelected.isCustomer;
  const profile = useProfile();

  const {
    data: dataRes,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [queryKeys.task.list_constructionSite__create_task, filters],
    queryFn: ({ pageParam = 1 }) => {
      return getListConstructionSiteMultiCompany({
        ...filters,
        pageIndex: pageParam,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      const { pageIndex, totalPages } = lastPage;

      if (pageIndex >= totalPages) {
        return undefined;
      }

      return pageIndex + 1;
    },
    ...rest,
  });

  const data = (dataRes?.pages?.map((page) => page?.data)?.reduce((arr, data) => [...arr, ...data], []) ||
    []) as IProject[];
  const countNameProjects = dataRes?.pages?.[0]?.countNameProjects ?? ({} as { [projectName: string]: number });

  // store data to react-query
  const { setListProjectTask } = useListProjectTask();
  const dataWithDefault = !isNil(defaultValue) && !filters?.keyword ? uniqBy([defaultValue, ...data], keyUniq) : data;
  const dataWithDefaultAndFullName = dataWithDefault.map((project: any) => ({
    ...project,
    fullName: convertFullNameProject({ project, numberCompanyUserCanCreateTask, role: profile?.company?.roleId }),
  }));

  useEffect(() => {
    setListProjectTask(dataWithDefaultAndFullName);
  }, [dataWithDefaultAndFullName, setListProjectTask]);

  return (
    <CommonSelect
      optionLabel="fullName"
      filterOption={false}
      options={dataWithDefaultAndFullName.map((project: any) => ({
        ...project,
        // isDuplicate: ROLES_ADMIN.includes(profile.company.roleId) ? countNameProjects?.[project?.name] > 1 : false, // check if duplicate project name
      }))}
      disabled={isOnlyVendor}
      onPopupScroll={(e) => {
        handleOnPopupScroll(() => {
          if (isLoading || isFetching) return;

          hasNextPage && fetchNextPage();
        });
      }}
      listHeight={256}
      loading={isLoading || isFetching}
      isDangerouslySetInnerHTML
      {...rest}
    />
  );
};

export default SelectProject;
